import React from 'react';
import Login from './Components/Login';
import CampaignList from './Components/CampaignList';
import CampaignAddition from './Components/CampaignAddition';
import CampaignSetting from './Components/CampaignSetting';
import Top from './Components/Top';
import Region from './Components/Region';
import Item from './Components/Item';
import Consumer from './Components/Consumer';
import NotFound from './Components/NotFound';
import ErrorPage from './Components/ErrorPage';
import UserProvider from './Context';
import { GlobalStyle } from './GlobalStyle';
import ReceiptList from './Components/ReceiptList';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProductSetting from './Components/ProductSetting';
import ProductEditing from './Components/ProductEditing';
import PrivateRoute from './Components/PrivateRoute';
import ReceiptInfo from './Components/ReceiptInfo';

const App = () => (

    <Router>
      <UserProvider>
        <Routes>
          <Route path='/' exact element={<Login />} />
          <Route path='/campaigns' element={<PrivateRoute childPath='/campaigns'  childElement={<CampaignList  />} /> } />
          <Route path='/campaigns/addition' element={<PrivateRoute childPath='/campaigns/addition' childElement={ <CampaignAddition />} /> } />
          <Route path='/campaigns/setting' element={<PrivateRoute childPath='/campaigns/setting'  childElement={ <CampaignSetting />} />} />
          <Route path='/campaigns/top' element={<PrivateRoute childPath='/campaigns/top'  childElement={<Top />} /> } />
          <Route path='/campaigns/region' element={<PrivateRoute childPath='/campaigns/region' childElement={ <Region />} /> } />
          <Route path='/campaigns/item' element={<PrivateRoute childPath='/campaigns/item' childElement={<Item /> } />} />
          <Route path='/campaigns/consumer' element={<PrivateRoute childPath='/campaigns/consumer' childElement={<Consumer /> } />} />
          <Route path='/campaigns/productsetting' element={<PrivateRoute childPath='/campaigns/productsetting'  childElement={ <ProductSetting /> } />} />
          <Route path='/campaigns/productediting' element={<PrivateRoute childPath='/campaigns/productediting'  childElement={<ProductEditing /> } />} />
          <Route path='/campaigns/receiptslist' element={<PrivateRoute childPath='/campaigns/receiptslist' childElement={ <ReceiptList /> } />} />
          <Route path='/campaigns/receiptsinfo'  element={<PrivateRoute childPath='/campaigns/receiptsinfo' childElement={ <ReceiptInfo /> } />} />
          <Route path='/errorPage' element={<ErrorPage />} />
          <Route path='/*' element={<NotFound />}/>
        </Routes>
        <GlobalStyle />
      </UserProvider>
    </Router>
  );
  
  export default App;