import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import API from '../API';
import cookie from 'react-cookies';
import { Modal } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import {
  Wrapper, Title, ContentTh, ContentText, ContentNum, ButtonCell, ButtonSave,
  SerchTd, SerchUnitTd,
  AttrEdit, AttrAddButton, AttrAddDiv, AttrAddInput, WrapDiv, WrapTable, WrapTr,
  WrapTdFir, WrapTd, WrapInput, WrapButtonDiv,
  ListContent, ListFlexBox, CenterWrapper, RightWrapper
} from './ProductEditing.style';
import {useNavigateParams} from '../Utility';

const ProductList = () => {
  const token = cookie.load('token');
  //キャンペーンID
  // const { state: { campaignId, showName, showSpan, searchInfo, item } } = useLocation();
  const { state: { searchInfo, item } } = useLocation();

  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get('campaignId');
  const showName = localStorage.getItem('showName');
  const showSpan = localStorage.getItem('showSpan');
  //JANコード
  const [janCode, setJanCode] = useState('');
  //ブランド名称
  const [brandName, setBrandName] = useState('');
  // ブランドカナ
  const [brandNameKana, setBrandNameKana] = useState('');
  // メーカー
  const [maker, setMaker] = useState('');
  // メーカーカナ
  const [makerKana, setMakerKana] = useState('');
  //最低単価
  const [lowerLimit, setLowerLimit] = useState('');
  //最高単価
  const [upperLimit, setUpperLimit] = useState('');
  //正の整数(ゼロを含む)
  const numRegExp = new RegExp('^\\d+$');
  //追加キーワードリスト
  const [addKeyList, setAddKeyList] = useState([]);
  //追加除外キーワードリスト
  const [ridKeyList, setRidKeyList] = useState([]);
  //検索画面から引き継いでいる検索条件
  const [reSearchInfo, setReSearchInfo] = useState([]);
  //キーワードリスト重複チェック兼キーワードデータ送信リスト
  const [wordKeyList, setWordKeyList] = useState([]);
  //除外キーワードリスト重複チェック兼除外データ送信リスト
  const [excludeKeyList, setExcludeKeyList] = useState([]);
  //ルート用
  // const navigate = useNavigate();
  const navigate = useNavigateParams();

  //キーワード処理
  //追加したいキーワードの変更
  const handleWordListTextChange = (e, index) => {
    let newList = addKeyList;
    newList[index]['optionText'] = e.target.value;
    setAddKeyList([...newList]);

  }
  //キーワードを追加ボタン
  const handleWordListAdd = (index) => {
    let dataList = addKeyList;
    let optionVal = dataList[index]['optionText'];
    if (optionVal !== '' && optionVal.trim() !== '') {
      let optionData = dataList[index]['options'];
      let newItem = { 'value': optionVal, 'checked': false };
      var msg = '';
      if (wordKeyList.includes(optionVal)) {
        msg = 'このキーワードは既に存在します';
      }
      if (msg !== '') {
        const modal = Modal.error();
        modal.update({
          title: 'エラー',
          content: msg,
          onOk() {
            modal.destroy();
          }
        })
      } else {
        setWordKeyList([optionVal, ...wordKeyList])
        dataList[index]['options'] = [newItem, ...optionData]
        dataList[index]['optionText'] = '';
        setAddKeyList([...dataList]);
      };
    }
  }
  //削除したいキーワードの選択変更
  const handleWordListCheckChange = (index, opIndex) => {
    let newList = addKeyList;
    let newOptionList = newList[index]['options'];
    newOptionList[opIndex]['checked'] = !newOptionList[opIndex]['checked'];
    newList[index]['options'] = newOptionList;
    setAddKeyList([...newList]);
  }


  //選択したキーワードを削除ボタン
  const handleWordListDel = (index) => {
    let newList = addKeyList;
    let optionList = newList[index]['options'];
    let newOptionList = [];
    let newWordList = []
    optionList.forEach(item => {
      if (!item.checked) {
        newOptionList = [...newOptionList, item];
        newWordList = [...newWordList, item.value];
      }
    })
    newList[index]['options'] = newOptionList;
    setAddKeyList([...newList]);
    setWordKeyList([...newWordList]);
  }
  //キーワード処理ここまで

  //除外キーワード処理
  //追加したい除外キーワードの変更
  const handleExcludeListTextChange = (e, index) => {
    let newList = ridKeyList;
    newList[index]['optionText'] = e.target.value;
    setRidKeyList([...newList]);
  }
  //除外キーワードを追加ボタン
  const handleExcludeListAdd = (index) => {
    let dataList = ridKeyList;
    let optionVal = dataList[index]['optionText'];
    if (optionVal !== '' && optionVal.trim() !== '') {
      let optionData = dataList[index]['options'];
      let newItem = { 'value': optionVal, 'checked': false };
      var msg = '';
      if (excludeKeyList.includes(optionVal)) {
        msg = 'このキーワードは既に存在します';
      }
      if (msg !== '') {
        const modal = Modal.error();
        modal.update({
          title: 'エラー',
          content: msg,
          onOk() {
            modal.destroy();
          }
        })
      } else {
        setExcludeKeyList([optionVal, ...excludeKeyList])
        dataList[index]['options'] = [newItem, ...optionData]
        dataList[index]['optionText'] = '';
        setRidKeyList([...dataList]);
      };
    }
  }
  //削除したい除外キーワードの選択変更
  const handleExcludeListCheckChange = (index, opIndex) => {
    let newList = ridKeyList;
    let newOptionList = newList[index]['options'];
    newOptionList[opIndex]['checked'] = !newOptionList[opIndex]['checked'];
    newList[index]['options'] = newOptionList;
    setRidKeyList([...newList]);
  }
  //選択した除外キーワードを削除ボタン
  const handleExcludeListDel = (index) => {
    let newList = ridKeyList;
    let optionList = newList[index]['options'];
    let newOptionList = [];
    let newWordList = []
    optionList.forEach(item => {
      if (!item.checked) {
        newOptionList = [...newOptionList, item];
        newWordList = [...newWordList, item.value];
      }
    })
    newList[index]['options'] = newOptionList;
    setRidKeyList([...newList]);
    setExcludeKeyList([...newWordList]);
  }
  //除外キーワード処理ここまで

  //データがある場合に数値を打ち込んでおく
  useEffect(() => {
    setReSearchInfo({ searchInfo });
    wordKeyList.length = 0;
    excludeKeyList.length = 0;
    setAddKeyList([{ 'id': '', 'description': '', 'optionText': '', 'options': [] }]);
    setRidKeyList([{ 'id': '', 'description': '', 'optionText': '', 'options': [] }]);
    if (item === undefined) {
      ;
    } else {
      const startResultInfo = async () => {
        if (item.janCode !== undefined) {
          setJanCode(item.janCode);
        }
        if (item.brandName !== undefined) {
          setBrandName(item.brandName);
        }
        if (item.brandNameKana !== undefined) {
          setBrandNameKana(item.brandNameKana)
        }
        if (item.maker !== undefined) {
          setMaker(item.maker)
        }
        if (item.makerKana !== undefined) {
          setMakerKana(item.makerKana)
        }
        if (item.unitPrice?.lowerLimit !== undefined) {
          setLowerLimit(item.unitPrice?.lowerLimit);
        }
        if (item.unitPrice?.upperLimit !== undefined) {
          setUpperLimit(item.unitPrice?.upperLimit);
        }
        //キーワード

        let fraiseItems = { 'options': [] };
        let fraiseNewList = [];
        let fraiseWordList = []
        item.wordList.forEach((x) => {
          let fraiseItem = {};
          fraiseItem['value'] = x;
          fraiseItem['checked'] = false;
          fraiseNewList = [...fraiseNewList, fraiseItem]
          fraiseWordList = [...fraiseWordList, x]
        });
        fraiseItems['options'] = fraiseNewList;
        setAddKeyList([fraiseItems]);
        setWordKeyList([...fraiseWordList]);

        if (item.excludeList !== undefined) {
          //除外キーワード
          let excItems = { 'options': [] };
          let excNewList = [];
          let excWordList = []
          item.excludeList.forEach((x) => {
            let excItem = {};
            excItem['value'] = x;
            excItem['checked'] = false;
            excNewList = [...excNewList, excItem]
            excWordList = [...excWordList, x]
          });
          excItems['options'] = excNewList;
          setRidKeyList([excItems]);
          setExcludeKeyList([...excWordList]);
        }
      }
      startResultInfo();
    }
  }, [])


  //JANコードをクリックした場合は商品編集と表示する
  function Tit({ item }) {
    if (item === undefined) {
      return (
        <>
          <Title>商品追加</Title>
        </>
      );
    } else {
      return (
        <>
          <Title>商品編集</Title>
        </>
      );
    }
  }

  //キャンセルボタン
  const handleProductBack = () => {
    // navigate('/campaigns/productsetting', { state: { campaignId, showName, showSpan, reSearchInfo } });
    navigate("/campaigns/productsetting", `campaignId=${campaignId}`, {reSearchInfo: reSearchInfo});
  }

  // キーワード自動生成ボタン押下
  const autoMake = () => {
    let dataList = addKeyList

    let autoWordList = []
    if ((brandName === '' && brandName.trim() === '') && (brandNameKana === '' && brandNameKana.trim() === '')
      && (maker === '' && maker.trim() === '') && (makerKana === '' && makerKana.trim() === '')) {
      return
    }

    if (brandName !== '' && brandName.trim() !== '') {
      autoWordList.push(brandName)
    }
    if (brandNameKana !== '' && brandNameKana.trim() !== '') {
      autoWordList.push(brandNameKana)
    }
    if ((brandName !== '' && brandName.trim() !== '') && (maker !== '' && maker.trim() !== '')) {
      autoWordList.push(maker + "" + brandName)
    }
    if ((brandNameKana !== '' && brandNameKana.trim() !== '') && (makerKana !== '' && makerKana.trim() !== '')) {
      autoWordList.push(makerKana + "" + brandNameKana)
    }

    let newWordKeyList = [...wordKeyList]

    autoWordList.forEach((item) => {
      if (!(newWordKeyList.includes(item))) {
        let newItem = { 'value': item, 'checked': false }
        let optionData = dataList[0]['options']
        dataList[0]['options'] = [newItem, ...optionData]
        newWordKeyList = [item, ...newWordKeyList]
      } else {
        let newItem = { 'value': item, 'checked': false }
        let optionData = dataList[0]['options']
        optionData = [newItem, ...optionData.filter(data => data.value !== item)]
        dataList[0]['options'] = [...optionData]
        newWordKeyList = [item, ...newWordKeyList.filter(data => data !== item)]
      }
    })
    setWordKeyList(newWordKeyList)
    setAddKeyList([...dataList])
  }


  //保存ボタン
  const handleProductAdd = async () => {
    var msg = '';
    let keyspaceCheck = [];
    let excspaceCheck = [];

    wordKeyList.forEach(x => {
      if (x.trim() === '') {
        keyspaceCheck = [...keyspaceCheck, x];
      }
    })

    excludeKeyList.forEach(x => {
      if (x.trim() === '') {
        excspaceCheck = [...excspaceCheck, x];
      }
    })



    if (janCode === '' || janCode.trim() === '') {
      msg = 'JANコードを入力してください';
    } else if (brandName === '' || brandName.trim() === '') {
      msg = 'ブランドを入力してください';
    } else if (lowerLimit !== '' && !numRegExp.test(lowerLimit) && lowerLimit !== undefined) {
      msg = '最低単価は0以上の整数で入力してください';
    } else if (upperLimit !== '' && !numRegExp.test(upperLimit) && upperLimit !== undefined) {
      msg = '最高単価は0以上の整数で入力してください';
    } else if (parseInt(upperLimit)< parseInt(lowerLimit)) {
      msg = '単価の値範囲が無効です';
    } else if (wordKeyList.length === 0) {
      msg = 'キーワードリストにキーワードを追加してください';
    } else if (!keyspaceCheck.length === 0) {
      msg = 'キーワードリストに無効なデータが存在します';
    } else if (!excspaceCheck.length === 0) {
      msg = '除外リストに無効なデータが存在します';
    }
    // else if (janCode.includes(janCodeAllData)) {
    //   msg = 'このJANコードは既に存在します';
    // }



    if (msg !== '') {
      const modal = Modal.error();
      modal.update({
        title: 'エラー',
        content: msg,
        onOk() {
          modal.destroy();
        }
      })
    } else { //入力された値
      let objectProductInfo = {
        janCode: janCode,
        brandName: brandName,
      };
      let unitPrice = {};
      if (lowerLimit !== '' && lowerLimit !== undefined) {
        unitPrice['lowerLimit'] = parseInt(lowerLimit);
      }
      if (upperLimit !== '' && upperLimit !== undefined) {
        unitPrice['upperLimit'] = parseInt(upperLimit);
      }
      objectProductInfo['unitPrice'] = unitPrice;
      objectProductInfo['wordList'] = wordKeyList;
      objectProductInfo['excludeList'] = excludeKeyList;

      if (brandNameKana !== '' && brandNameKana !== undefined) {
        objectProductInfo['brandNameKana'] = brandNameKana
      }
      if (maker !== '' && maker !== undefined) {
        objectProductInfo['maker'] = maker
      }
      if (makerKana !== '' && makerKana !== undefined) {
        objectProductInfo['makerKana'] = makerKana
      }

      let resultData = null
      //入力された値でDBを検索する
      if (item !== undefined) {
        // 更新
        resultData = await API.updateProductData(objectProductInfo, campaignId, janCode, token)
      } else {
        // 追加
        resultData = await API.addProductData(objectProductInfo, campaignId, janCode, token)
      }
      if (resultData.message !== '') {
        const modal = Modal.error();
        modal.update({
          title: 'エラー',
          content: resultData.message,
          onOk() {
            modal.destroy();
          }
        })
      } else {
        //結果を出した状態でページに戻る
        navigate("/campaigns/productsetting", `campaignId=${campaignId}`, {reSearchInfo: reSearchInfo});
      }
    }
  }

  return (
    <Wrapper>
      <Header showFlag={'page3'} showName={showName} showSpan={showSpan} showId={campaignId} />
      <main>
        <Tit item={item} />
        <CenterWrapper>
          <table>
            <tbody>
              <tr>
                <ContentTh>JANコード:</ContentTh>
                <SerchTd><ContentText disabled={item} type="text" maxLength='13' value={janCode} onChange={(e) => setJanCode(e.target.value)} /></SerchTd>
                <ContentTh>単価:</ContentTh>
                <SerchUnitTd>
                  <ContentNum type="number" maxLength='10' value={lowerLimit} onChange={(e) => setLowerLimit(e.target.value)} />
                  &nbsp;~&nbsp;
                  <ContentNum type="number" maxLength='10' value={upperLimit} onChange={(e) => setUpperLimit(e.target.value)} />
                </SerchUnitTd>
              </tr>
              <tr>
                <ContentTh>ブランド:</ContentTh>
                <SerchTd><ContentText type="text" maxLength='100' value={brandName} onChange={(e) => setBrandName(e.target.value)} /></SerchTd>
                <ContentTh>ブランドカナ:</ContentTh>
                <SerchTd><ContentText type="text" maxLength='100' value={brandNameKana} onChange={(e) => setBrandNameKana(e.target.value)} /></SerchTd>
              </tr>
              <tr>
                <ContentTh>メーカー:</ContentTh>
                <SerchTd><ContentText type="text" maxLength='100' value={maker} onChange={(e) => setMaker(e.target.value)} /></SerchTd>
                <ContentTh>メーカーカナ:</ContentTh>
                <SerchTd><ContentText type="text" maxLength='100' value={makerKana} onChange={(e) => setMakerKana(e.target.value)} /></SerchTd>
              </tr>
            </tbody>
          </table>
          <RightWrapper>
            <ButtonSave onClick={autoMake}>キーワード自動生成</ButtonSave>
          </RightWrapper>
          <ListFlexBox>
            <ListContent>
              <p>キーワードリスト:</p>
              <table>
                <tbody>
                  {addKeyList.map((item, index) =>
                    <tr key={index}>
                      <td>
                        <AttrEdit>
                          <AttrAddDiv>
                            <AttrAddInput type="text" maxLength='50' value={item.optionText} onChange={(e) => handleWordListTextChange(e, index)} />
                            <ButtonSave onClick={() => handleWordListAdd(index)}>追加</ButtonSave>
                          </AttrAddDiv>
                          <WrapDiv>
                            <WrapTable>
                              <tbody>
                                {item.options.map((opItem, opIndex) =>
                                  <WrapTr key={opIndex}>
                                    <WrapTdFir><WrapInput type="checkbox" checked={opItem.checked} onChange={() => handleWordListCheckChange(index, opIndex)} /></WrapTdFir>
                                    <WrapTd contenteditable="true">{opItem.value}</WrapTd>
                                  </WrapTr>
                                )}
                              </tbody>
                            </WrapTable>
                          </WrapDiv>
                          <WrapButtonDiv>
                            <AttrAddButton onClick={() => handleWordListDel(index)}>選択したキーワードを削除</AttrAddButton>
                          </WrapButtonDiv>
                        </AttrEdit>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </ListContent>

            <ListContent>
              <p>除外リスト:</p>
              <table>
                <tbody>
                  {ridKeyList.map((item, index) =>
                    <tr key={index}>
                      <td>
                        <AttrEdit>
                          <AttrAddDiv>
                            <AttrAddInput type="text" maxLength='50' value={item.optionText} onChange={(e) => handleExcludeListTextChange(e, index)} />
                            <ButtonSave onClick={() => handleExcludeListAdd(index)}>追加</ButtonSave>
                          </AttrAddDiv>
                          <WrapDiv>
                            <WrapTable>
                              <tbody>
                                {item.options.map((opItem, opIndex) =>
                                  <WrapTr key={opIndex}>
                                    <WrapTdFir><WrapInput type="checkbox" checked={opItem.checked} onChange={() => handleExcludeListCheckChange(index, opIndex)} /></WrapTdFir>
                                    <WrapTd contenteditable="true">{opItem.value}</WrapTd>
                                  </WrapTr>
                                )}
                              </tbody>
                            </WrapTable>
                          </WrapDiv>
                          <WrapButtonDiv>
                            <AttrAddButton onClick={() => handleExcludeListDel(index)}>選択したキーワードを削除</AttrAddButton>
                          </WrapButtonDiv>
                        </AttrEdit>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <ButtonCell>
                <ButtonSave onClick={handleProductBack} id="backbutton">キャンセル</ButtonSave>
                <ButtonSave onClick={handleProductAdd} id="backbutton"> 保存</ButtonSave>
              </ButtonCell>
            </ListContent>
          </ListFlexBox>
        </CenterWrapper>
      </main >
      <Footer />
    </Wrapper >
  )
}
export default ProductList;