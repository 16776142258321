import React, { useState, useEffect } from "react"
import Footer from './Footer';
import Header from './Header';
import cookie from 'react-cookies';
import moment from 'moment';
import { Modal } from 'antd';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import API from '../API';
import zoominImg from '../images/zoomin.png';
import zoomoutImg from '../images/zoomout.png';
import loading from "../images/loading.gif";

import {
  Info, ImgDiv, ListHref, Title, WrapDiv, WrapTr, WrapTable, WrapTable2,
  AttrEdit, AttrAddSelect, HrefTd, ListValue, MyButton, ButtonCell, ButtonSave,
  ContentNum, ContentName, ContentTH, ContentAttrName, ContentAttrValue, ContentNum2,
  MyButton2, ImgDiv2, DeleteButton, Wrapper, ProductsDiv, WrapTdFir, ContentValue,
  ProductTable, WrapDiv2, ProductTr, ProductTitle, ProductInfoList,
  CheckTd, NameTd, NameTh, JanCodeTh, ReceiptContent, ReceiptButtons, ButtonCell2
} from "./ReceiptInfo.style"

import { useNavigateParams } from '../Utility';

const ReceiptInfo = () => {
  const token = cookie.load('token');
  const navigate = useNavigateParams();
  const { state: { trackingId, receiptSearchInfo } } = useLocation();
  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get('campaignId');
  const showName = localStorage.getItem('showName');
  const showSpan = localStorage.getItem('showSpan');
  // 商品
  const [resultItems, setResultItems] = useState([]);
  // 削除の商品
  const [deleteItems, setDeleteItems] = useState([]);
  // 更新日時
  const [updateTime, setUpdateTime] = useState('');
  // 更新区分
  const [updateType, setUpdateType] = useState('');
  // 購入日時
  const [salesDate, setSalesDate] = useState('');
  const [salesTime, setSalesTime] = useState('');
  // 地域
  const [region, setRegion] = useState('');
  // 合計金額
  const [totalAmount, setTotalAmount] = useState('');
  // 決済方法
  const [paymentMethod, setPaymentMethod] = useState('');
  // ステータス
  const [status, setStatus] = useState('');
  // 却下理由
  const [ngReason, setNgReason] = useState('');
  // 受付日時
  const [entryTime, setEntryTime] = useState('');
  // チェーン店名
  const [chainStore, setChainStore] = useState('');
  // 店舗名
  const [storeName, setStoreName] = useState('');
  // 電話番号
  const [tel, setTel] = useState('');
  // 対象商品数量
  const [targetItemCount, setTargetItemCount] = useState(0);
  // 対象商品金額
  const [targetItemTotal, setTargetItemTotal] = useState(0);
  // レシート画像パス
  const [receiptImagePath, setReceiptImagePath] = useState([]);
  // レシート画像数量
  const [imgTotal, setImgTotal] = useState(1);
  // 現在レシート画像の索引
  const [currentIndex, setCurrentIndex] = useState(1);
  // 対象商品
  const [productList, setProductList] = useState([]);
  // select
  const [statusChange, setStatusChange] = useState([]);
  // 追加商品Data
  const [insertInfo, setInsertInfo] = useState();
  // 追加false | 更新true
  const [updateFlag, setUpdateFlag] = useState(false);
  const [updateIndex, setUpdateIndex] = useState(false);
  const [updateJanCode, setUpdateJanCode] = useState();
  const [imageFlag, setImageFlag] = useState(false);
  // レシート画像の回転角度
  const [imageRotation, setImageRotation] = useState(0);

  //初期画面表示
  useEffect(() => {
    const ReceiptInfoData = async () => {
      // レシートデータ
      const resultData = await API.getReceiptInfo(campaignId, trackingId, token);
      // 対象商品一覧取得
      const productWordsData = await API.getproductWordsData(campaignId, token);
      if (resultData.message !== '' || productWordsData.message !== '') {
        const modal = Modal.error()
        modal.update({
          title: 'エラー',
          content: resultData.message !== "" ? resultData.message : productWordsData.message,
          onOk () {
            modal.destroy();
          }
        })
      } else {
        const receiptDetail = resultData.data.receiptInfo;
        let list = []
        if (resultData.data.items !== undefined) {
          list = resultData.data.items;
        }
        setUpdateTime(moment(receiptDetail.updateTime).format('YYYY/MM/DD HH:mm:ss'))
        setUpdateType(receiptDetail.updateType)
        setSalesDate(moment(receiptDetail.salesTime).format('YYYY-MM-DD'))
        setSalesTime(moment(receiptDetail.salesTime).format('HH:mm'))
        setRegion(receiptDetail.region !== undefined ? receiptDetail.region : "")
        setTotalAmount(receiptDetail.totalAmount !== undefined ? receiptDetail.totalAmount : "")
        setPaymentMethod(receiptDetail.paymentMethod)
        setStatus(receiptDetail.status)
        if (receiptDetail.status === "NG") {
          setNgReason(receiptDetail.ngReason)
          document.getElementById("ngReason").removeAttribute("disabled")
        } else {
          setNgReason("")
          document.getElementById("ngReason").disabled = "disabled"
        }
        setEntryTime(moment(receiptDetail.entryTime).format('YYYY/MM/DD HH:mm:ss'))
        setChainStore(receiptDetail.chainStore !== undefined ? receiptDetail.chainStore : "")
        setStoreName(receiptDetail.storeName !== undefined ? receiptDetail.storeName : "")
        setTel(receiptDetail.tel !== undefined ? receiptDetail.tel : "")
        setTargetItemCount(receiptDetail.targetItemCount)
        setTargetItemTotal(receiptDetail.targetItemTotal)
        setResultItems([...list])
        setProductList([...productWordsData.data]);
      }
    }
    ReceiptInfoData();
    ReceiptInfoImageData();
  }, [])

  const ReceiptInfoImageData = async() => {
    const resultData = await API.getReceiptImage(campaignId, trackingId, token)
    if (resultData.status === 200) {
      if (resultData.data.length > 0) {
        setImageFlag(true)
        setReceiptImagePath([...resultData.data])
        setImgTotal(resultData.data.length)
        handleDragStart()
      } else {
        setImageFlag(false)
        document.getElementById("canvas_img").src = ""
        document.getElementById("canvas_img").alt = "画像が存在しません"
      }
    }
  }

  // 表示更新区分
  const displayUpdateType = (updateNum) => {
    if (updateNum === 1) {
      return "一次判定"
    } else if (updateNum === 2) {
      return "総合判定"
    } else if (updateNum === 3) {
      return "目視判定"
    }
  }

  // テキスト2タイムスタンプ
  const dateStr2TimeTamp = (dateStr) => {
    dateStr = dateStr.substring(0, 19);
    dateStr = dateStr.replace(/-/g, '/');
    var timeTamp = new Date(dateStr).getTime();
    return timeTamp;
  }

  // ステータス選択
  const updateStatus = (e) => {
    setStatus(e.target.value);
    if (e.target.value === "NG") {
      document.getElementById("ngReason").removeAttribute("disabled");
    } else {
      setNgReason("");
      document.getElementById("ngReason").disabled = "disabled";
    }
  }

  // JANコードデータをクリック
  const handlereRceiptJanCodeClick = (x, vindex) => {
    setUpdateJanCode(x.janCode)
    setStatusChange([x.janCode])
    setUpdateFlag(true)
    setUpdateIndex(vindex)
    openDialog()
  }

  // 単価変更、数量変更、金額変更
  const updateValue = (e, index, attribute) => {
    let newResultItems = resultItems;
    if (attribute === "unitPrice") {
      newResultItems[index].unitPrice = e.target.value
      newResultItems[index].price = parseInt(e.target.value) * newResultItems[index].quantity
    } else if (attribute === "quantity") {
      newResultItems[index].quantity = e.target.value
      newResultItems[index].price = parseInt(e.target.value) * newResultItems[index].unitPrice
    } else if (attribute === "price") {
      newResultItems[index].price = e.target.value
    } else if (attribute === "name") {
      newResultItems[index].name = e.target.value
    }
    setResultItems([...newResultItems])
    setTargetItemCount(calculateTargetItemCount(resultItems))
    setTargetItemTotal(calculateTargetItemTotal(resultItems))
  }

  // 対象商品金額を再計算
  const calculateTargetItemTotal = (items) => {
    var total = 0;
    if (items.length > 0) {
      items.forEach((item) => {
        if (item.janCode !== "") {
          total = parseInt(total) + parseInt(item.price);
        }
      })
      return parseInt(total);
    } else {
      return 0;
    }
  }

  // 対象商品数量を再計算
  const calculateTargetItemCount = (items) => {
    var count = 0;
    if (items.length > 0) {
      items.forEach((item) => {
        if (item.janCode !== "") {
          count = parseInt(count) + parseInt(item.quantity);
        }
      })
      return count;
    } else {
      return 0;
    }
  }

  // 削除ボタン押下
  const deleteItem = (e, index) => {
    let newResultItems = resultItems;
    let item = newResultItems[index];
    setDeleteItems([...deleteItems, item.dataType])
    if (item.janCode !== "" | item.janCode.trim().length > 0) {
      setTargetItemCount(targetItemCount - item.quantity);
      setTargetItemTotal(targetItemTotal - item.price);
    }
    setResultItems([...newResultItems.filter((item) => item !== newResultItems[index])])
  }

  // 追加ボタン押下
  const increaseItem = () => {
    setUpdateJanCode(undefined)
    setStatusChange([])
    setUpdateFlag(false)
    openDialog()
  }

  // キャンセルボタン押下
  const handleReceiptBack = () => {
    navigate("/campaigns/receiptslist", `campaignId=${campaignId}`, { theReceiptSearchInfo: receiptSearchInfo });
  }

  // 変更を保存ボタン押下
  const handleReceiptUpdate = async () => {
    var msg = '';
    // 購入日時時刻チェック
    if (salesDate === '' || salesTime === '') {
      msg = "完全な購入日時を入力してください"
    }
    // 合計金額チェック
    if (totalAmount === '' || (totalAmount < 0) || totalAmount.toString().indexOf(".") !== -1) {
      msg = "合計金額は0以上の整数で入力してください"
    }

    // 電話番号チェック
    let rule = /^[0-9.()-]+$/
    if (!rule.test(tel)) {
      msg = "電話番号は半角数字とハイフンで入力してください"
    }
    // 単価チェック
    rule = /^[0-9]*$/
    if (resultItems.some(item => item.unitPrice.toString().trim().length === 0)) {
      msg = "単価を入力してください"
    } else if (resultItems.some(item => item.unitPrice < 0 || item.unitPrice.toString().indexOf(".") !== -1)) {
      msg = "単価は0以上の整数で入力してください"
    } else if (resultItems.some(item => !rule.test(item.unitPrice.toString()))) {
      msg = "単価に数値型データを入力してください"
    }
    // 数量チェック
    if (resultItems.some(item => item.quantity.toString().trim().length === 0)) {
      msg = "数量を入力してください"
    } else if (resultItems.some(item => item.quantity <= 0 || item.quantity.toString().indexOf(".") !== -1)) {
      msg = "数量は正の整数で入力してください"
    }
    // 金額チェック
    if (resultItems.some(item => item.price.toString().trim().length === 0)) {
      msg = "金額を入力してください"
    } else if (resultItems.some(item => item.price < 0 || item.price.toString().indexOf(".") !== -1)) {
      msg = "金額は0以上の整数で入力してください"
    }
    // 商品名チェック
    if (resultItems.some(item => item.name === undefined || item.name.trim().length === 0)) {
      msg = "商品名を入力してください"
    }

    if (msg !== '') {
      const modal = Modal.error();
      modal.update({
        title: 'エラー',
        content: msg,
        onOk () {
          modal.destroy();
        }
      })
    } else {
      // オブジェクトの作成
      let updateInfo = {
        receiptInfo: {
          "trackingId": trackingId,
          "entryTime": dateStr2TimeTamp(entryTime),
          "status": status,
          "ngReason": ngReason === undefined ? "" : ngReason,
          "chainStore": chainStore,
          "storeName": storeName === undefined ? "" : storeName,
          "tel": tel,
          "salesTime": dateStr2TimeTamp(salesDate + " " + salesTime),
          "totalAmount": totalAmount,
          "paymentMethod": paymentMethod,
          "region": region,
          "targetItemCount": calculateTargetItemCount(resultItems),
          "targetItemTotal": calculateTargetItemTotal(resultItems)
        },
        items: resultItems,
        deletedItems: deleteItems
      }

      // レシート詳細更新
      const resultData = await API.updateReceiptInfo(updateInfo, campaignId, trackingId, token);
      if (resultData.message !== '') {
        const modal = Modal.error();
        modal.update({
          title: 'エラー',
          content: resultData.message,
          onOk () {
            modal.destroy();
          }
        })
      } else {
        navigate("/campaigns/receiptslist", `campaignId=${campaignId}`, { theReceiptSearchInfo: receiptSearchInfo });
      }
    }
  }

  // レシート画像を拡大
  const zoomIn = () => {
    const img = document.getElementById("canvas_img");
    img.width = img.width * 1.1
    img.height = img.height * 1.1
  }

  // レシート画像を縮小
  const zoomOut = () => {
    const img = document.getElementById("canvas_img");
    img.width = img.width * 0.9
    img.height = img.height * 0.9
  }

  // レシート画像の切り替え
  const changeImg = (isNext) => {
    let index;
    if (isNext && currentIndex < imgTotal) {
      index = currentIndex;
    } else if (!isNext && currentIndex > 1) {
      index = parseInt(currentIndex) - 2;
    } else {
      return;
    }
    setImageRotation(0); // 画像の向きをリセット
    setCurrentIndex(index + 1);
    document.getElementById("canvas_img").src = "data:image;base64," + receiptImagePath[index]
  }

  // レシート画像ドラッグ
  const handleDragStart = () => {
    var params = {
      left: 0,
      top: 0,
      currentX: 0,
      currentY: 0,
      flag: false
    }
    var getCss = function (o, key) {
      return o.currentStyle ? o.currentStyle[key] : document.defaultView.getComputedStyle(o, false)[key]
    }
    var startDrag = function (bar, target, callback) {
      if (getCss(target, "left") !== "auto") {
        params.left = getCss(target, "left")
      }
      if (getCss(target, "top") !== "auto") {
        params.top = getCss(target, "top")
      }
      bar.onmousedown = function (event) {
        params.flag = true
        if (!event) {
          event = window.event
          bar.onselectstart = function () {
            return false
          }
        }
        var e = event
        params.currentX = e.clientX
        params.currentY = e.clientY
      }
      document.onmouseup = function () {
        params.flag = false
        if (getCss(target, "left") !== "auto") {
          params.left = getCss(target, "left")
        }
        if (getCss(target, "top") !== "auto") {
          params.top = getCss(target, "top")
        }
      }
      document.onmousemove = function (event) {
        var e = event ? event : window.event
        if (params.flag) {
          var nowX = e.clientX, nowY = e.clientY
          var disX = nowX - params.currentX, disY = nowY - params.currentY
          var left = parseInt(params.left) + disX
          var top = parseInt(params.top) + disY
          var width = document.getElementById("canvas_img").width
          var height = document.getElementById("canvas_img").height
          if (Math.abs(left) > 0.92 * width || Math.abs(top) > 0.92 * height) {
            target.style.left = 0 + "px"
            target.style.top = 0 + "px"
          } else {
            target.style.left = left + "px"
            target.style.top = top + "px"
          }

          if (typeof callback == "function") {
            callback((parseInt(params.left) || 0) + disX, (parseInt(params.top) || 0) + disY)
          }

          if (event.preventDefault) {
            event.preventDefault()
          }
          return false
        }
      }
    }
    startDrag(document.getElementById("canvas_img"), document.getElementById("canvas_img"))
  }

  const rotateImg = () => {
    if (imageFlag) {
      setImageRotation(prev => (prev + 90) % 360);
    }
  }

  // Dialog表示
  const openDialog = () => {
    document.getElementById("mydialog").showModal();
  }

  // Dialog 非表示
  const closeDialog = () => {
    document.getElementById("mydialog").close()
  }

  //単一のみの選択
  const handleStatusChange = (e) => {
    if (statusChange.includes(e.target.value)) {
      setStatusChange(statusChange.filter(item => item !== e.target.value))
    } else {
      setStatusChange([e.target.value])
    }
  }

  // 取得商品名
  const getBrandName = (productList, newJanCode) => {
    var brandName = undefined
    if (newJanCode === "") {
      newJanCode = updateJanCode
    }
    productList.forEach((item) => {
      if (item.janCode === newJanCode) {
        brandName = item.brandName
      }
    })
    return brandName
  }

  // 決定ボタン押下
  const handleProductAdd = async () => {
    // 入力チェック
    var msg = ''
    if (statusChange.length === 0) {
      msg = "対象商品を選択してください"
    } else if (statusChange.length > 1) {
      msg = "対象商品は一つだけ選択してください"
    }
    if (msg !== '') {
      const modal = Modal.error()
      modal.update({
        title: 'エラー',
        content: msg,
        onOk () {
          modal.destroy()
        }
      })
    } else {
      // 更新
      if (updateFlag) {
        var updateProductInfo = {
          oldJanCode: updateJanCode,
          newJanCode: statusChange[0],
          name: getBrandName(productList, statusChange[0]),
          target: statusChange[0] !== "" ? 1 : 0,
          updateIndex: updateIndex,
        }
        updateReceiptInfo(updateProductInfo)
      }
      // 追加
      else {
        var insertProductInfo = {
          janCode: statusChange[0],
          name: getBrandName(productList, statusChange[0]),
          unitPrice: 0,
          quantity: 1,
          price: 0,
          target: statusChange[0] === "" ? 0 : 1,
        }
        updateReceiptInfo(insertProductInfo)
      }
    }
    closeDialog()
  }

  const updateReceiptInfo = (data) => {
    let list = []
    list = [...resultItems]
    if (updateFlag) {
      // 変更   
      if (data !== undefined) {
        const index = data.updateIndex
        list[index].name = data.name
        list[index].janCode = data.newJanCode
        list[index].target = data.target
        setTargetItemCount(calculateTargetItemCount(list))
        setTargetItemTotal(calculateTargetItemTotal(list))
      }
    } else {
      // 追加
      setInsertInfo(data)
      if (data !== undefined) {
        if (list.length !== 0) {
          data.dataType = "detailInfo-" + (parseInt(list[list.length - 1].dataType.split("-")[1]) + 1)
        } else {
          data.dataType = "detailInfo-0"
        }
        list.push(data)
        setTargetItemCount(calculateTargetItemCount(list))
        setTargetItemTotal(calculateTargetItemTotal(list))
      }
    }
    setResultItems([...list])
  }

  return (
    <Wrapper>
      <Header showFlag={'page3'} showName={showName} showSpan={showSpan} showId={campaignId} />
      <main>
        <Title>レシート詳細</Title>
        <ReceiptContent>
          <Info>
            <AttrEdit>
              <WrapTable>
                <tbody>
                  <WrapTr>
                    <ContentAttrName>レシートID:</ContentAttrName>
                    <ContentAttrValue>{trackingId}</ContentAttrValue>
                    <ContentAttrName>ステータス:</ContentAttrName>
                    <ContentAttrValue>
                      <AttrAddSelect value={status} onChange={(e) => updateStatus(e)} >
                        <option value="OK">OK</option>
                        <option value="NG">NG</option>
                        <option value="ERROR">ERROR</option>
                      </AttrAddSelect>
                    </ContentAttrValue>
                  </WrapTr>
                  <WrapTr>
                    <ContentAttrName>更新日時:</ContentAttrName>
                    <ContentAttrValue>{updateTime}</ContentAttrValue>
                    <ContentAttrName>却下理由:</ContentAttrName>
                    <ContentAttrValue>
                      <AttrAddSelect id="ngReason" disabled="disabled" value={ngReason} onChange={(e) => setNgReason(e.target.value)} >
                        <option value=""></option>
                        <option value="EXCEEDED_ENTRY_LIMIT">EXCEEDED_ENTRY_LIMIT</option>
                        <option value="INVALID_SALES_TIME">INVALID_SALES_TIME</option>
                        <option value="RECEIPT_ALREADY_ENTERED">RECEIPT_ALREADY_ENTERED</option>
                        <option value="SALES_TIME_NOT_FOUND">SALES_TIME_NOT_FOUND</option>
                        <option value="TARGET_ITEM_NOT_FOUND">TARGET_ITEM_NOT_FOUND</option>
                        <option value="MANUAL_BATCH_CHANGE">CHANGED_MANUALLY</option>
                      </AttrAddSelect>
                    </ContentAttrValue>
                  </WrapTr>
                  <WrapTr>
                    <ContentAttrName>更新区分:</ContentAttrName>
                    <ContentAttrValue>{displayUpdateType(updateType)}</ContentAttrValue>
                    <ContentAttrName>受付日時:</ContentAttrName>
                    <ContentAttrValue>{entryTime}</ContentAttrValue>
                  </WrapTr>
                  <WrapTr>
                    <ContentAttrName>購入日時:</ContentAttrName>
                    <ContentAttrValue rowSpan="2">
                      <ContentValue type="date" value={salesDate} onChange={(e) => setSalesDate(e.target.value)} />
                      <br />
                      <ContentValue type="time" value={salesTime} onChange={(e) => setSalesTime(e.target.value)} />
                    </ContentAttrValue>
                    <ContentAttrName>チェーン店名:</ContentAttrName>
                    <td><ContentValue type="text" maxLength='50' value={chainStore} onChange={(e) => setChainStore(e.target.value)} /></td>
                  </WrapTr>
                  <WrapTr>
                    <ContentAttrValue></ContentAttrValue>
                    <ContentAttrName>店舗名:</ContentAttrName>
                    <ContentAttrValue><ContentValue type="text" maxLength='50' value={storeName} onChange={(e) => setStoreName(e.target.value)} /></ContentAttrValue>
                  </WrapTr>
                  <WrapTr>
                    <ContentAttrName>地域:</ContentAttrName>
                    <ContentAttrValue>
                      <AttrAddSelect value={region} onChange={(e) => setRegion(e.target.value)} >
                        <option value=""></option>
                        <option value="北海道">北海道</option>
                        <option value="青森県">青森県</option>
                        <option value="岩手県">岩手県</option>
                        <option value="宮城県">宮城県</option>
                        <option value="秋田県">秋田県</option>
                        <option value="山形県">山形県</option>
                        <option value="福島県">福島県</option>
                        <option value="茨城県">茨城県</option>
                        <option value="栃木県">栃木県</option>
                        <option value="群馬県">群馬県</option>
                        <option value="埼玉県">埼玉県</option>
                        <option value="千葉県">千葉県</option>
                        <option value="東京都">東京都</option>
                        <option value="神奈川県">神奈川県</option>
                        <option value="新潟県">新潟県</option>
                        <option value="富山県">富山県</option>
                        <option value="石川県">石川県</option>
                        <option value="福井県">福井県</option>
                        <option value="山梨県">山梨県</option>
                        <option value="長野県">長野県</option>
                        <option value="岐阜県">岐阜県</option>
                        <option value="静岡県">静岡県</option>
                        <option value="愛知県">愛知県</option>
                        <option value="三重県">三重県</option>
                        <option value="滋賀県">滋賀県</option>
                        <option value="	京都府">京都府</option>
                        <option value="大阪府">大阪府</option>
                        <option value="兵庫県">兵庫県</option>
                        <option value="奈良県">奈良県</option>
                        <option value="和歌山県">和歌山県</option>
                        <option value="鳥取県">	鳥取県</option>
                        <option value="島根県">島根県</option>
                        <option value="岡山県">岡山県</option>
                        <option value="広島県">広島県</option>
                        <option value="山口県">山口県</option>
                        <option value="徳島県">徳島県</option>
                        <option value="香川県">香川県</option>
                        <option value="愛媛県">愛媛県</option>
                        <option value="高知県">高知県</option>
                        <option value="福岡県">福岡県</option>
                        <option value="佐賀県">佐賀県</option>
                        <option value="長崎県">長崎県</option>
                        <option value="熊本県">熊本県</option>
                        <option value="大分県">大分県</option>
                        <option value="宮崎県">宮崎県</option>
                        <option value="鹿児島県">鹿児島県</option>
                        <option value="沖縄県">沖縄県</option>
                      </AttrAddSelect>
                    </ContentAttrValue>
                    <ContentAttrName>電話番号:</ContentAttrName>
                    <ContentAttrValue><ContentValue type="text" maxLength='15' value={tel} onChange={(e) => setTel(e.target.value)} /></ContentAttrValue>
                  </WrapTr>
                  <WrapTr>
                    <ContentAttrName>合計金額:</ContentAttrName>
                    <ContentAttrValue><ContentValue type="number" maxLength='10' value={totalAmount} onChange={(e) => setTotalAmount(e.target.value.slice(0, 10))} /></ContentAttrValue>
                    <ContentAttrName>対象商品数量:</ContentAttrName>
                    <ContentAttrValue>{targetItemCount}</ContentAttrValue>
                  </WrapTr>
                  <WrapTr>
                    <ContentAttrName>決済方法:</ContentAttrName>
                    <ContentAttrValue>
                      <AttrAddSelect value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)} >
                        <option value="現金">現金</option>
                        <option value="クレジットカード">クレジットカード</option>
                        <option value="その他">その他</option>
                      </AttrAddSelect>
                    </ContentAttrValue>
                    <ContentAttrName>対象商品金額:</ContentAttrName>
                    <ContentAttrValue>{targetItemTotal}</ContentAttrValue>
                  </WrapTr>
                </tbody>
              </WrapTable>
            </AttrEdit>
            <AttrEdit>
              <WrapDiv>
                <WrapTable2>
                  <thead>
                    <WrapTr>
                      <ContentTH>JANコード</ContentTH>
                      <ContentTH>商品名</ContentTH>
                      <ContentTH>単価</ContentTH>
                      <ContentTH>数量</ContentTH>
                      <ContentTH>金額</ContentTH>
                      <th></th>
                    </WrapTr>
                  </thead>
                  <tbody>
                    {
                      resultItems.map((x, index) => (
                        <WrapTr key={x.dataType}>
                          <HrefTd>
                            <ListHref onClick={() => { handlereRceiptJanCodeClick(x, index) }}>
                              {x.janCode ? x.janCode : "x"}
                            </ListHref>
                          </HrefTd>
                          <ContentName>
                            {x.janCode === ""
                              && insertInfo !== undefined
                              && x.dataType === insertInfo.dataType
                              ? <input onChange={(e) => updateValue(e, index, "name")} />
                              : x.name}
                          </ContentName>
                          <ListValue><ContentNum type="number" value={x.unitPrice} onChange={(e) => updateValue(e, index, "unitPrice")}></ContentNum></ListValue>
                          <ListValue><ContentNum2 type="number" value={x.quantity} onChange={(e) => updateValue(e, index, "quantity")}></ContentNum2></ListValue>
                          <ListValue><ContentNum type="number" value={x.price} onChange={(e) => updateValue(e, index, "price")}></ContentNum></ListValue>
                          <ListValue><DeleteButton onClick={(e) => { deleteItem(e, index) }}>削除</DeleteButton></ListValue>
                        </WrapTr>
                      ))
                    }
                  </tbody>
                </WrapTable2>
              </WrapDiv>
              <div>
                <MyButton onClick={increaseItem} id="backbutton">追加</MyButton>
              </div>
            </AttrEdit>
            <ButtonCell>
              <ButtonSave onClick={handleReceiptBack} id="backbutton">キャンセル</ButtonSave>
              <ButtonSave onClick={handleReceiptUpdate} id="backbutton"> 変更を保存</ButtonSave>
            </ButtonCell>
          </Info>
          <ImgDiv>
            <ImgDiv2 imageRotation={imageRotation}>
              <img id="canvas_img"
                alt="レシート画像"
                width={imageFlag ? '70%' : '10%'}
                style={{ position: 'relative', cursor: 'move',verticalAlign:'middle' }}
                src={imageFlag &&receiptImagePath[0] !== undefined ? 'data:image;base64,' + receiptImagePath[0] : loading}
              />
            </ImgDiv2>
            <ReceiptButtons>
              <img src={zoominImg} alt="レシート画像を拡大" onClick={zoomIn} />
              <img src={zoomoutImg} alt="レシート画像を縮小" onClick={zoomOut} />
              <div>
                <MyButton2 isActive={currentIndex > 1} onClick={() => changeImg(false)}>
                  <i className="fa-solid fa-angle-left"></i>
                </MyButton2>
                <label style={{ fontSize: "20px" }}>{currentIndex}&nbsp;/&nbsp;{imageFlag?imgTotal:1}</label>
                <MyButton2 isActive={currentIndex < imgTotal} onClick={() => changeImg(true)}>
                  <i className="fa-solid fa-angle-right"></i>
                </MyButton2>
              </div>
              <i onClick={rotateImg} className="fa-solid fa-rotate"></i>
            </ReceiptButtons>
          </ImgDiv>
          <ProductInfoList id="mydialog">
            <ProductsDiv>
              <ProductTitle>対象商品を選択</ProductTitle>
              <WrapDiv2 id="scroll">
                <ProductTable>
                  <thead>
                    <ProductTr>
                      <CheckTd></CheckTd>
                      <JanCodeTh>JANコード</JanCodeTh>
                      <NameTh>ブランド名称</NameTh>
                    </ProductTr>
                  </thead>
                  <tbody>
                    <ProductTr id="notProduct">
                      <CheckTd>
                        <label htmlFor="npInput">
                          <input
                            name="check"
                            type="checkbox"
                            value=""
                            onChange={handleStatusChange}
                            checked={statusChange.includes("")}
                            id="npInput"
                          />
                        </label>
                      </CheckTd>
                      <WrapTdFir><label htmlFor="npInput">対象商品ではない</label></WrapTdFir>
                      <td><label htmlFor="npInput">　</label></td>
                    </ProductTr>
                    {
                      productList.map((x, index) => (
                        <ProductTr key={index}>
                          <CheckTd>
                            <label htmlFor={x.janCode}>
                              <input
                                name="check"
                                type="checkbox"
                                value={x.janCode}
                                onChange={handleStatusChange}
                                checked={statusChange.includes(x.janCode)}
                                id={x.janCode}
                              />
                            </label>
                          </CheckTd>
                          <WrapTdFir><label htmlFor={x.janCode}>{x.janCode}</label></WrapTdFir>
                          <NameTd><label htmlFor={x.janCode}>{x.brandName}</label></NameTd>
                        </ProductTr>
                      ))
                    }
                  </tbody>
                </ProductTable>
              </WrapDiv2>
              <ButtonCell2>
                <ButtonSave onClick={closeDialog}>キャンセル</ButtonSave>
                <ButtonSave onClick={handleProductAdd}> 決定</ButtonSave>
              </ButtonCell2>
            </ProductsDiv>
          </ProductInfoList>
        </ReceiptContent>
      </main>
      <Footer/>
    </Wrapper>
  )
}

export default ReceiptInfo