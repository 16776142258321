import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 80px;
  height: 100vh;
`;

export const Title = styled.h1`
  text-align: center;
`;

export const Content = styled.div`
  margin-top: 60px;
`;

export const Image = styled.img`
  display: block;
  width: 10em;
  margin: 0 auto;
  margin-bottom: 12px;
`;

export const Span = styled.span`
  font-family: 'DotGothic16', sans-serif;
  margin-right: 0.5em;
`;

export const AreaDiv = styled.div`
  width: max-content;
  margin: 20px auto;
  font-size: 1.1em;
  line-height: 1.8;
`;

export const AreaLabel = styled.label`
  display: inline-block;
  width: 100px;
  text-align: right;
  padding-right: 3px;
`;

export const AreaInput =  styled.input`
  font-size: 1em;
`;

export const ButtonDiv = styled.div`
  background-color: lightgray;
  border-radius: 4px;
  box-shadow: 1px 1px 0.5px 0.5px rgba(3,3,3,0.3);
  &.active{
    box-shadow:inset 0 0 1px 0.5px rgba(3,3,3,0.3);
  }
  padding: 4px;
  width: 5em;
  text-align: center;
  margin: 0 auto;
`;

export const ButtonHref = styled.button`
  color: #333333;
  font-size: 1.1em;
  padding:3px 0px;
  border:none;
  cursor: pointer;
  text-align:center;
  text-decoration:none;
  background-color:lightgray;
  border-color:lightgray;
`;
