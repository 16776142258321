import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: system-ui, sans-serif;
    font-size: 16px;
    color: #333333;
    margin: 0;
  }
  
  footer {
    background-color: #f2f2f2;
    display: grid;
    place-items: center;
    color: darkgray;
    font-size: small;
    padding-top: 6px;
    margin-bottom:0px;
  }
  
  header {
    background-color: #f2f2f2;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    position: relative;
  }

  * {
   margin: 0;
   padding: 0;
  }

  details[open] .detail-arrow {
    transform: rotate(180deg);
  }

  details::after {
   content: "";
   display: block;
   clear: both;
  }

  .con-font {
    font-size:0.9em;
  }

  .reload-button {
    margin-top: 8px;
    margin-bottom: 8px;
    align-self: flex-end;
  }

  .c3-tooltip {
    border-collapse: collapse;
    border-spacing: 0;
    background-color: #fff;
    empty-cells: show;
    -webkit-box-shadow: 7px 7px 12px -9px #777777;
    -moz-box-shadow: 7px 7px 12px -9px #777777;
    box-shadow: 7px 7px 12px -9px #777777;
    opacity: 0.9;
  }
  
  .c3-tooltip tr {
    border: 1px solid #CCC;
  }
  
  .c3-tooltip th {
    background-color: #aaa;
    font-size: 14px;
    padding: 2px 5px;
    text-align: left;
    color: #FFF;
  }
  
  .c3-tooltip td {
    font-size: 13px;
    padding: 3px 6px;
    background-color: #fff;
    border-left: 1px dotted #999;
  }
  
  .c3-tooltip td > span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 6px;
  }
  
  .c3-tooltip .value {
    text-align: right;
  }
`;
