import styled from 'styled-components';

export const Image = styled.img`
  width: 40px;
  position: absolute;
  top: 12px;
  left: 20px;
`;

export const Title = styled.h1`
  margin-left: 52px;
  font-family: 'DotGothic16', sans-serif;
`;

export const Nav = styled.nav`
  display: inline-block;
  line-height: 1.3;
  width: max-content;
`;

export const NavUl = styled.ul`
  display: flex;
`;

export const NavLi = styled.li`
  list-style: none;
  cursor: pointer;
  padding: 4px;
  width: 110px;
  height: fit-content;
  text-align: center;
  box-sizing: border-box;
  &:hover{
    background-color: white;
  }
`;

export const NavMainLi = styled.li`
  list-style: none;
  cursor: pointer;
  padding: 4px;
  width: 110px;
  height: fit-content;
  text-align: center;
  box-sizing: border-box;
  &:hover{
    background-color: white;
  }
  transform: translateY(-0.7em);
`;

export const NavP = styled.p`
  padding-bottom: 6px;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
`;

export const NavSpan = styled.span`
  font-size: 0.75em;
  padding-left: 0.3em;
`;

export const SubMenu = styled.ul`
  height: 0;
  margin-top: 0;
  width: max-content;
  overflow: hidden;
  text-align: left;
  background-color: white;
`;

export const SubLi = styled.li`
background-color:white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  list-style: none;
  padding: 6px;
  border-top: #f2f2f2 0.8px solid;
  border-bottom: #f2f2f2 0.8px solid;
  &:hover{
    background-color: #f2f2f2;
  }
`;

export const NavMenuLi = styled.li`

  list-style: none;
  cursor: pointer;
  padding: 4px;
  width: 110px;
  height: fit-content;
  text-align: center;
  box-sizing: border-box;
  z-index: 101;
  &:hover ${SubMenu} {
   // height: auto;
    overflow: visible;
  }
`;

export const Ahref = styled.a`
  text-decoration: none;
  color: #333333;
  &:hover{
    color: #333333;
  }
`;

export const CampaignTitle = styled.div`
  display: inline-block;
  margin-left: 52px;
  margin-right: 40px;
  line-height: 1.3;
`;

export const CampaignHref = styled.a`
  color: #333333;
  text-decoration: none;
`;

export const CampaignH1 = styled.h1`
  line-height: 1.3;
  font-weight: bold;
  display: inline-block;
  white-space: nowrap;
  transform: translateY(0.1em);
  font-size:2em;
  margin-bottom:0em;
  cursor: pointer;
  &:hover{
    color: #333333;
  }
`;

export const CampaigDate = styled.div`
  display: inline-block;
  margin-left: 30px;
  white-space: nowrap;
`;
