import React, {useContext, useState, createContext } from 'react';

export const Context = createContext();

export function useAuth(){
  return useContext(Context);
}
const UserProvider = ({ children }) => {
  const [state, setState] = useState(undefined);
  function login() {
    localStorage.setItem('isLogInd', true);
  }
    const value = {
      state,
      setState,
      login
    }
  return (
    <Context.Provider value={value}>{children}</Context.Provider>
  );
};

export default UserProvider;
