import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: 70px auto 80px;
`;

export const Title = styled.h2`
  font-size: 1.3em;
  margin: 18px 20px;
`;

export const Content = styled.div`
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Ahref = styled.a`
  text-decoration: none;
  color: #333333;
  cursor: pointer;
`;

export const Card = styled.div`
  box-sizing: border-box;
  width: 390px;
  height: 240px;
  padding: 12px;
  margin: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 4px;
  margin: 3px;
`;

export const CardUl = styled.ul`
  font-size: 1.1em;
  list-style: none;
`;

export const CardLi = styled.li`
  margin: 10px 3px;
`;

export const CardContent = styled.div`
  margin-left: 1em;
`;

export const CardAdd = styled.div`
  box-sizing: border-box;
  width: 390px;
  height: 240px;
  padding: 12px;
  margin: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 4px;
  margin: 3px;
  display: grid;
  place-items: center;
  padding: 30px;
`;

export const CardAddi = styled.i`
  font-size: 6.5em;
  color: lightgray;
`;

