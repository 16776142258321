import Footer from './Footer';
import Header from './Header';
import { Wrapper, Main, ErrorMsg} from './Common.styles';

const ErrorPage = () => {
    return (
        <Wrapper>
           <Header showFlag={'page1'}/>
           <Main>
              <div>
                  <ErrorMsg>
                    申し訳ございません
                    <br/>
                    技術的な問題が発生しました
                    <br/>
                    しばらくたってから
                    <br/>
                    もう一度お試しください
                  </ErrorMsg>
              </div>
           </Main>
           <Footer/>
        </Wrapper>
    )
}

export default ErrorPage;