import bitwarelogo from '../images/bitwarelogo.png';
import {Image} from './Footer.styles';

const Footer = () => {
    return (
       <footer>
          <a href='http://www.bit-ware.co.jp/' target='_blank' rel='noopener noreferrer'>
              <Image src={bitwarelogo} alt='ビットウェアロゴ'/>
          </a>
            &copy; 2022 Bitware All rights reserved.
      </footer>
    )
}
export default Footer;