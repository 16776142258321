import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: 70px auto 80px;
`;

export const Main = styled.main`
  display: grid;
  place-items: center;
`;

export const ErrorMsg = styled.div`
  text-align: center;
  line-height: 2;
`;

export const ErrorButton = styled.div`
  background-color: lightgray;
  border-radius: 4px;
  box-shadow: 1px 1px 0.5px 0.5px rgba(3,3,3,0.3);
  &.active{
    box-shadow: inset 0 0 1px 0.5px rgba(3,3,3,0.3); 
  }
  padding: 4px;
  width: 8em;
  text-align: center;
  margin: 20px auto;
`;

export const ErrorHref = styled.a`
  text-decoration: none;
  color: #333333;
  font-size: 1.1em;
`;

export const IndexDiv = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 342px);
  grid-template-rows: 342px 410px;
  margin: 12px auto;
  width: fit-content;
`;

export const CardDiv = styled.div`
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 4px;
  margin: 3px;
`;

export const TitleDiv = styled.div`
  font-weight: bold;
  margin: 6px;
`;

export const SummaryDiv = styled.div`
  font-size: 1.4em;
  height: 60px;
  text-align: center;
  font-size: 1.2em;
  letter-spacing: 0.05em; 
  display: grid;
  place-items: center;
`;

export const SummarySpan = styled.span`
  font-size: 0.7em;
`;

export const CardSalesDiv = styled.div`
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 4px;
  margin: 3px;
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
`;

export const FilterDiv = styled.div`
  padding: 18px 0 18px 20px;
  border-bottom: #f2f2f2 1.5px solid;
  top: 0;
  z-index: 100;
`;

export const ConFilterDiv = styled.div`
  padding: 15px 0 10px 20px;
  border-bottom: #f2f2f2 1.5px solid;
  top: 0;
  z-index: 100;
`;

export const SubTitleDiv = styled.div`
  display: inline-block;
  margin-right: 30px;
`;

export const SubTitleH2 = styled.h2`
  font-size: 1.3em;;
`;

export const SelectDate = styled.span`
  font-size: 0.9em;
  margin-right:5px;
`;

export const InputDate = styled.input`
  height: 20px;
  margin-right: 3px;
`;

export const InputTime = styled.input`
  height: 20px;
`;

export const SelectRegion = styled.span`
  font-size: 0.9em;
  margin-left: 30px;
  margin-right:5px;
`;

export const FilterSelect = styled.select`
  width: 100px;
  height: 23px;
`;

export const UpdateButton = styled.button`
  display: inline-block;
  background-color: lightgray;
  border-radius: 4px;
  box-shadow: 1px 1px 0.5px 0.5px rgba(3,3,3,0.3);
  cursor: pointer;
  margin-left: 30px;
  color: #333333;
  font-size: 1em;
  padding: 1px;
  width: 60px;
  text-align: center;
  border:none;
  border-color:lightgray;
  &.active {
    box-shadow: inset 0 0 1px 0.5px rgba(3,3,3,0.3);
  }
`;

export const RegionDiv = styled.div`
  box-sizing: border-box;
  margin: 12px auto;
  width: fit-content;
`;

export const DetailsSummary = styled.summary`
  width: 85px;
  display: inline-block;
  margin: 10px 6px auto 12px;
  cursor: pointer;
  float: left;
`;

export const ItemSummary = styled.summary`
  width: 170px;
  display: inline-block;
  margin: 10px 6px auto 12px;
  cursor: pointer;
  float: left;
`;

export const DetailsArrow = styled.span`
  font-size: 0.8em;
  display: inline-block;
  margin: 3px;
  transform: rotate(90deg);
`;

export const RegionDetail = styled.div`
  float: left;
`;

export const ItemDetail = styled.div`
  float: left;
  width: calc(100% - 188px);
`;

export const DetailCard = styled.div`
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 4px;
  margin: 3px;
  box-sizing: border-box;
  width: 300px;
  height: 315px;
  float: left;
`;

export const ItemCard = styled.div`
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 4px;
  margin: 3px;
  box-sizing: border-box;
  width: 300px;
  height: 315px;
  float: left;
`;

export const ConsumerCard = styled.div`
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 4px;
  margin: 10px;
`;

export const RegionSummary = styled.div`
  height: 30px;
  text-align: center;
  font-size: 1.2em;
  letter-spacing: 0.05em; 
  display: grid;
  place-items: center;
`;

export const ConsumerDiv = styled.div`
  padding-left: 6px;
`;

export const SearchDiv = styled.div`
  display: inline-block;
`;

export const FlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const PeriodDiv = styled.div`
  margin: 8px 30px 8px 0;
  display: inline-block;
  white-space: nowrap;
`;

export const WrapAttr = styled.div`
  display: flex;
  flex-flow: row wrap;
  white-space: nowrap;
`;

export const SelectAttr = styled.div`
  font-size: 0.9em;
  margin-right:5px;
  line-height: 40.5px;
`;

export const AreaAttr = styled.div`
  display: inline-block;
  border: #f2f2f2 1.5px solid;
  border-radius: 6px;
  padding: 8px;
`;

export const GenderDiv = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right:5px;
`;

export const GenderArea = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 12px;
`;

export const GenderSelect = styled.select`
  width: 10em;
  height: 20px;
`;

export const EchartsDiv = styled.div`
  display: grid;
  grid-template-columns: 600px 300px;
  width: fit-content;
  height: 360px;
  margin: 12px auto;
`;

