import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: 70px auto 80px;
`;

export const Title = styled.h2`
  font-size: 1.3em;
  margin: 18px 20px;
  font-weight: bold;
`;

export const Content = styled.div`
  margin: 10px 18px;
`;

export const ContentTh = styled.th`
  text-align: right;
  font-weight: normal;
  padding: 4px 12px 4px 6px;
`;

export const ContentText = styled.input`
  height: 25px;
  width:125%;
  font-size: 0.9em;
`;

export const ContentNum = styled.input`
  width: 100px;
  height: 25px;
`;

export const Ahref = styled.a`
  text-decoration: none;
  color: #333333;
`;

export const ButtonCell = styled.div`
  display:flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: flex-end;
  align-items:flex-end;
  padding: 10px;
`;

export const ButtonSave = styled.button`
  display: inline-block;
  border-radius: 4px;
  box-shadow:1px 1px 0.5px 0.5px rgba(3,3,3,0.3);
  &.active{
    box-shadow: inset 0 0 1px 0.5px rgba(3,3,3,0.3);
  }
  :disabled { 
    opacity:0.4;
  }
  cursor: pointer;
  min-width: 3em;
  text-align: center;
  padding: 2px 12px;
  margin-left: 6px;
  color: #333333;
  font-size: 1em;
  border:none;
  text-align:center;
  background-color:lightgray;
  border-color:lightgray;
`;

export const ButtonSearch = styled.button`
  display: inline-block;
  border-radius: 4px;
  box-shadow:1px 1px 0.5px 0.5px rgba(3,3,3,0.3);
  min-width: 3em;
  text-align: center;
  padding: 2px 12px;
  color: #333333;
  font-size: 1em;
  border:none;
  background-color:lightgray;
  border-color:lightgray;
`;

export const Pop = styled.div`
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  position: absolute;
  width:50%;
  height:120px;
  overflow:auto;
  z-index: 10;
  top: 50%;
  right: calc(60%);
  transform: translateY(-30%);
  background-color: #fff;
  color: black;
  padding: 0.5em 1em;
  border-radius: 5px;
`;

export const TooltipCo = styled.div`
  position: relative;
  display: inline - block;
  &:hover{
    background-color: #f2f2f2;
  }
`;

export const ListTable = styled.table`
  width:100%;
  border-collapse: collapse;
  border:1px solid lightgray;
`;

export const ListTr = styled.tr`
  border-collapse: collapse;
  border:1px solid lightgray;
`;

export const ListTopTd = styled.td`
  border-collapse: collapse;
  border:1px solid lightgray;
  background-color:#f2f2f2;
  white-space: nowrap;
  font-weight: bold;
  padding:5px;
  text-align:center;
  vertical-align: top;
  padding: 4px 6px;
`;

export const ListCodeTd = styled.td`
  border-collapse: collapse;
  border:1px solid lightgray;
  text-align:center;
  vertical-align: top;
  padding: 4px 8px;
`;

export const Listhref = styled.a`
  color:blue;
  text-decoration:underline;
  cursor: pointer;
`;

export const ListkeyTd = styled.div`
  border-collapse: collapse;
  border: 0px none;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width:100rem;
  padding: 4px 8px;
`;

export const ListExckeyTd = styled.div`
  border-collapse: collapse;
  border: 0px none;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width:100rem;
  padding: 4px 8px;
`;

export const ListNumberTd = styled.td`
  border-collapse: collapse;
  border:1px solid lightgray;
  text-align:right;
  vertical-align: top;
  padding: 4px 8px;
`;

export const ListNameTd = styled.td`
  border-collapse: collapse;
  border:1px solid lightgray;
  vertical-align: top;
  width:15em;
  padding: 4px 8px;
  text-align: left;
`;

export const ListStringTd = styled.td`
  border-collapse: collapse;
  border:1px solid lightgray;
  vertical-align: top;
  padding: 4px 8px;
  text-align: left;
`;

export const Pagination = styled.div`
  text-align: center; 
  width: 100%;
  margin: 30px auto;
  display:flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items:center;
`;

export const SerchTable = styled.table`
  margin-top:10px;
`;

export const Serch = styled.div`
  display: flex;
  padding-top:5px;
`;

export const SerchTd = styled.td`
  padding-right:100px;
`;

export const SerchUnitTd = styled.td`
  padding:0;
`;

export const ListKeySurroundTd = styled.td`
  vertical-align: top;
  border-collapse: collapse;
  border: 1px solid lightgray;
`;
