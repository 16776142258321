const API_URL = 'https://tcvwvdpn7e.execute-api.us-west-1.amazonaws.com/api/clairvoyance/dashboard/';
//ログイン
const LOGIN_URL = `${API_URL}login`;
//キャンペーン一覧取得
const GET_CAMPAIGN_URL = `${API_URL}campaigns`;
//地域
const GET_MAP_JSON_URL = '/map/japanMap7.geojson';

export {
    API_URL,
    LOGIN_URL,
    GET_CAMPAIGN_URL,
    GET_MAP_JSON_URL
  };