import React, { useState, useEffect} from 'react';
import Footer from './Footer';
import Header from './Header';
import API from '../API';
import cookie from 'react-cookies';
import ReactEcharts from 'echarts-for-react';
import moment from 'moment';
import { Modal } from 'antd';
// import { useLocation } from 'react-router';
import { Wrapper, ConFilterDiv, SubTitleDiv, SubTitleH2, SearchDiv, FlexDiv, PeriodDiv, SelectDate, ConsumerCard,SelectAttr,
    WrapAttr, AreaAttr, GenderSelect, InputDate, InputTime, GenderArea, GenderDiv, UpdateButton, EchartsDiv, TitleDiv} from './Common.styles';
import { useSearchParams } from 'react-router-dom';


const Consumer = () => {
    const token = cookie.load('token');
    // const {state:{showId,showName,showSpan}} = useLocation();
    const [searchParams] = useSearchParams();
    const showId = searchParams.get('campaignId');
    const showName = localStorage.getItem('showName');
    const showSpan = localStorage.getItem('showSpan');
    //表示期間-開始
    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    //表示期間-終了
    const [endDate, setEndDate] = useState('');
    const [endTime, setEndTime] = useState('');
    //購入金額
    const [purchasePriceOption, setPurchasePriceOption] = useState({});
    //決済方法
    const [paymentMethodOption, setPaymentMethodOption] = useState({});
    // customerAttribution
    const [attributeList, setAttributeList] = useState([])
    const [attrParam, setAttrParam] = useState([])

    useEffect(() => {
        const getConsumerInfo = async () => {
          setPurchasePriceOption(getPriceOption([], []));
          setPaymentMethodOption(getMethodOption([]));
          const campaignInfo = await API.getCampaignInfo(showId, token);
          // 開始日時、終了日時
          var startSpan = undefined
          var endSpan = undefined
          const resultData = campaignInfo.data
          if (resultData.validPeriodStartTime !== undefined && resultData.validPeriodStartTime.trim() !== ''
            && resultData.validPeriodEndTime !== undefined && resultData.validPeriodEndTime.trim() !== '') {
            startSpan = new Date(resultData.validPeriodStartTime).getTime()
            endSpan = new Date(resultData.validPeriodEndTime).getTime()
            var tempStartDateTime = resultData.validPeriodStartTime.split(" ")
            var tempEndDateTime = resultData.validPeriodEndTime.split(" ")
            setStartDate(tempStartDateTime[0])
            setStartTime(tempStartDateTime[1].slice(0, 5))
            setEndDate(tempEndDateTime[0])
            setEndTime(tempEndDateTime[1].slice(0, 5))
          }
          //購入金額
          const getPurchasePriceInfo = API.getStatisticsData(showId, token, 'purchasePrice', '', '', '', '', startSpan, endSpan, {});
          //決済方法
          const getPaymentMethodInfo = API.getStatisticsData(showId, token, 'paymentMethodRatio', '', '', '', '', startSpan, endSpan, {});

          Promise.all([getPurchasePriceInfo, getPaymentMethodInfo]).then(function(values) {
            const [purchasePriceInfo, paymentMethodInfo] = values;

            var message = '';
            if (campaignInfo.message !== '') {
              message = campaignInfo.message;
            } else if (purchasePriceInfo.message !== '' ) {
              message = purchasePriceInfo.message;
            } else if (paymentMethodInfo.message !== '') {
              message = paymentMethodInfo.message;
            }
            if (message !== '') {
              const modal = Modal.error();
              modal.update({
              title:'エラー',
              content:message,
              onOk() {
                  modal.destroy();
                }
              })
            } else {
              const campaignData = campaignInfo.data
              var tempAttributeList = []
              var tempAttrParam = []
              if (campaignData !== undefined && campaignData['customerAttribution'] !== undefined) {
                campaignData['customerAttribution'].forEach(item => {
                  if (item.id !== undefined && item.options !== undefined && item.description !== undefined) {
                    let attribute = {}
                    let param = {}
                    attribute['descriptionID'] = item.id
                    attribute['descriptionName'] = item.description
                    attribute['descriptionValue'] = [{ 'key': 'all', 'value': '全て' }]
                    param['name'] = item.id
                    param['value'] = 'all'
                    Object.keys(item.options).forEach(opKey => {
                      let newItem = {}
                      newItem['key'] = opKey
                      newItem['value'] = item.options[opKey]
                      attribute['descriptionValue'] = [...attribute['descriptionValue'], newItem]
                    })
                    tempAttributeList = [...tempAttributeList, attribute]
                    tempAttrParam = [...tempAttrParam, param]
                  }
                })
              }
              setOptionInfo(purchasePriceInfo.data, paymentMethodInfo.data)
              setAttributeList([...tempAttributeList])
              setAttrParam([...tempAttrParam])
            }
          });
        };
        getConsumerInfo();
    },[]); //eslint-disable-line
    
    //更新ボタン
    const handleUpdateClick = async () => {
        var msg = '';
        if ((startDate === '' || startDate.trim() === '') && startTime !== '' && startTime.trim() !== '') {
         msg = '完全な表示期間開始日時を入力してください。';
        } else if (startDate !== '' && startDate.trim() !== '' && (startTime === '' || startTime.trim() === '')) {
         msg = '完全な表示期間開始日時を入力してください。';
        } else if ((endDate === '' || endDate.trim() === '') && endTime !== '' && endTime.trim() !== '') {
         msg = '完全な表示期間終了日時を入力してください。';
        } else if (endDate !== '' && endDate.trim() !== '' && (endTime === '' || endTime.trim() === '')) {
         msg = '完全な表示期間終了日時を入力してください。';
        } else if (startDate !== '' && startDate.trim() !== '' && startTime !== '' && startTime.trim() !== ''
        && endDate !== '' && endDate.trim() !== '' && endTime !== '' && endTime.trim() !== '') {
          if (getStartDateTime(startDate, startTime) > getEndDateTime(endDate, endTime)) {
           msg = '表示期間の時間範囲が無効です。';
          }
        }
        if (msg !== '') {
         const modal = Modal.error();
         modal.update({
            title:'エラー',
            content:msg,
            onOk(){
               modal.destroy();
            }
         })
        } else {
            var periodStart = undefined;
            var periodEnd = undefined;
            var attrParamMap = {};
            //表示期間-開始
            if (startDate !== '' && startDate.trim() !== '' && startTime !== '' && startTime.trim() !== '') {
                periodStart = getStartDateTime(startDate, startTime);
            }
            //表示期間-終了
            if (endDate !== '' && endDate.trim() !== '' && endTime !== '' && endTime.trim() !== '') {
                periodEnd = getEndDateTime(endDate, endTime);
            }

            // customerAttribution
            attrParam.forEach((item) => {
              if (item.value !== 'all') {
                attrParamMap[item.name] = item.value
              }
            })

            setPurchasePriceOption(getPriceOption([], []));
            setPaymentMethodOption(getMethodOption([]));
            //購入金額
            const purchasePriceInfo = await API.getStatisticsData(showId, token, 'purchasePrice', '', '', '', '', periodStart, periodEnd, attrParamMap);
            //決済方法
            const paymentMethodInfo = await API.getStatisticsData(showId, token, 'paymentMethodRatio', '', '', '', '', periodStart, periodEnd, attrParamMap);
            var message = '';
            if (purchasePriceInfo.message !== '' ) {
               message = purchasePriceInfo.message;
            } else if (paymentMethodInfo.message !== '') {
               message = paymentMethodInfo.message;
            }
            if (message !== '') {
               const modal = Modal.error();
               modal.update({
               title:'エラー',
               content:message,
               onOk() {
                  modal.destroy();
                }
               })
            } else {
              setOptionInfo(purchasePriceInfo.data, paymentMethodInfo.data);
            }
        }
    }

    const setOptionInfo = (priceData, methodData) => {
      if (priceData !== undefined && priceData.data !== undefined && priceData.data.length === 1) {
        if (priceData.data[0]['data1'] !== undefined && priceData.data[0]['data1'].length > 0
           && priceData.data[0]['data2'] !== undefined && priceData.data[0]['data2'].length > 0) {
          let option = getPriceOption(priceData.data[0]['data1'], priceData.data[0]['data2']);
          setPurchasePriceOption(option);
        }
       }
       if (methodData !== undefined && methodData.data !== undefined && methodData.data.length === 1) {
         if (methodData.data[0]['data1'] !== undefined && methodData.data[0]['data1'].length > 0
         && methodData.data[0]['data2'] !== undefined && methodData.data[0]['data2'].length > 0) {
           let data = [];
           for (var n=0; n<methodData.data[0]['data1'].length; n++) {
            let item = {};
            item['name'] = methodData.data[0]['data1'][n];
            item['value'] = methodData.data[0]['data2'][n]
            data = [...data, item];
           }
           let option = getMethodOption(data);
           setPaymentMethodOption(option);
         }
       }
    }

    //開始日時（ミリ秒）
    const getStartDateTime = (dateStr, timeStr) => {
        return new Date(moment(dateStr).format('YYYY-MM-DD') + ' ' + moment(timeStr, 'HH:mm').format('HH:mm:00')).getTime();
    };
    //終了日時（ミリ秒）
    const getEndDateTime = (dateStr, timeStr) => {
        return new Date(moment(dateStr).format('YYYY-MM-DD') + ' ' + moment(timeStr, 'HH:mm').format('HH:mm:59')).getTime();
    };

    const getPriceOption = (data1, data2) => {
        let tipStr = '対象商品合計金額（円）';
        let option = {
          tooltip:{
            position: 'top',
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              snap: true,
              label: {
                formatter: function (params) {
                  return Math.floor(Number(params.value)).toLocaleString();
                }
              }
            },
            borderColor:'#fff',
            borderWidth:0,
            padding:[0,0,0,0],
            formatter: (params) => {
             var res = "<table class='c3-tooltip'>" +
                   "<tr><th colSpan='2'>" + Number(params[0].name).toLocaleString() + "</th></tr>" +
                   "<tr><td class='name'><span style='background-color:#1f77b4;'></span>" + tipStr + "</td><td>"+ params[0].value.toLocaleString() +"</td></tr></table>"
             return res
            }
          },
          grid:{
            left:60,
            bottom:40,
            right:30,
            top:20,
            containLabel: false
          },
          xAxis:{
            name:'合計金額（円）',
            nameLocation:'center',
            nameGap:25,
            nameTextStyle:{
              color:'#000',
              fontSize:10,
              padding:[0,0,0,420],
              verticalAlign:'top'
            },
            type:'category',
            boundaryGap: false,
            axisLine: {
              show: true,
              lineStyle:{
                color:'#ccc'
              }
            },
            splitLine:{
              show:false
            },
            axisTick:{
              show:true
            },
            axisLabel:{
              show:true,
              color:'#000',
              fontSize:10,
              formatter: function (value) {
                return Number(value).toLocaleString();
              }
            },
            data:data1
          },
          yAxis:{
            type:'value',
            axisLine: {
              show:true,
              lineStyle:{
                color:'#ccc'
              }
            },
            splitLine:{
              show:false
            },
            axisTick:{
              show:true,
            },
            axisLabel:{
              show:true,
              color:'#000',
              fontSize:10
            },
          },
          series:[{
            type:'scatter',
            symbolSize: 5,
            itemStyle: {
              color:'#1f77b4'  
            },
            data:data2
          }]
        };
        return option
    }

    const getMethodOption = (data) => {
        let option = {
          tooltip:{
            trigger: 'item',
            borderColor:'#fff',
            borderWidth:0,
            padding:[0,0,0,0],
            formatter: (param) => {
                var res = "<table class='c3-tooltip'>" +
                      "<tr><td class='name'><span style='background-color:"+ param.color+";'></span>" + param.name + "</td><td class='value'>"+ param.percent +"%</td></tr></table>"
                return res
               }
          },
          legend: {
            type: 'scroll',
            icon:'rect',
            bottom: 0,
            itemWidth:10,
            itemHeight:10,
            textStyle:{
              color:'#000',
              fontSize:12
            },
            pageTextStyle:{
              color:'#000',
              fontSize:12,
            },
            pageIconSize:10
          },
          color:['#626A70', '#8AC9F2', '#D2E4F0', '#405D70', '#A4B3BD'],
          series:[{
            type:'pie',
            radius: '90%',
            bottom:25,
            emphasis:{
              focus:'self'
            },
            label:{
              show:false
            },
            data:data
          }]
        };
        return option
    }

  const updateAttributeSelect = (index, attrValue) => {
    let temp = [...attrParam]
    temp[index].value = attrValue
    setAttrParam([...temp])
  }

    return (
      <Wrapper>
          <Header showFlag={'page3'} showName={showName} showSpan={showSpan} showId={showId}/>
          <main>
             <ConFilterDiv>
                <SubTitleDiv><SubTitleH2>消費傾向統計</SubTitleH2></SubTitleDiv>
                <SearchDiv>
                    <FlexDiv>
                        <PeriodDiv>
                            <SelectDate>表示期間: </SelectDate>
                            <InputDate type='date' value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
                            <InputTime type='time' value={startTime} onChange={(e) => setStartTime(e.target.value)}/> ~&nbsp;
                            <InputDate type='date' value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                            <InputTime type='time' value={endTime} onChange={(e) => setEndTime(e.target.value)}/>
                        </PeriodDiv>
                        <FlexDiv>
                           <WrapAttr>
                               <SelectAttr>表示属性: </SelectAttr>
                               <AreaAttr>
                                  {
                                    attributeList.map((item, index) => (
                                      <GenderArea key={item.descriptionID}>
                                        <GenderDiv><label className='con-font'>{item.descriptionName}:</label></GenderDiv>
                                        <GenderSelect value={attrParam[index].value} onChange={(e) => updateAttributeSelect(index, e.target.value)}>
                                          {item.descriptionValue.map((item, index) =>
                                            <option key={index} value={item.key}>{item.value}</option>
                                          )}
                                        </GenderSelect>
                                      </GenderArea>
                                    ))
                                  }
                                </AreaAttr>
                           </WrapAttr>
                           <UpdateButton className='reload-button' onClick={handleUpdateClick}>更新</UpdateButton>
                        </FlexDiv>
                    </FlexDiv>
                </SearchDiv>
             </ConFilterDiv>
             <EchartsDiv>
               <ConsumerCard>
                   <TitleDiv>購入金額</TitleDiv>
                   <div><ReactEcharts option={purchasePriceOption}/></div>
               </ConsumerCard>
               <ConsumerCard>
                   <TitleDiv>決済方法</TitleDiv>
                   <div><ReactEcharts option={paymentMethodOption}/></div>
                </ConsumerCard>
             </EchartsDiv>
          </main>
          <Footer/>
      </Wrapper>
    )
}

export default Consumer;