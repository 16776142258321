import React, { useState, useEffect} from 'react';
import Footer from './Footer';
import Header from './Header';
import API from '../API';
import cookie from 'react-cookies';
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
// import "default-passive-events";
import { Modal } from 'antd';
import { Wrapper, IndexDiv, CardDiv, TitleDiv, SummaryDiv, SummarySpan, CardSalesDiv} from './Common.styles';
// import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';


const Top = () => {
    const token = cookie.load('token');
    // const {state:{showId,showName,showSpan}} = useLocation();
    const [searchParams] = useSearchParams();

    const showId = searchParams.get('campaignId');
    const showName = localStorage.getItem('showName');
    const showSpan = localStorage.getItem('showSpan');
    //レシート応募
    const [entryOption, setEntryOption] = useState({});
    //レシート応募合計件数
    const [entryNum, setEntryNum] = useState(0);
    //売上金額
    const [salesAmountOption, setSalesAmountOption] = useState({});
    //売上金額合計金額
    const [salesAmountNum, setSalesAmountNum] = useState(0);
    //商品別売上金額
    const [goodsOption, setGoodsOption] = useState({});
    //消費者属性
    const [attrOption, setAttrOption] = useState({});
    //消費者属性平均購入金額
    const [attrNum, setAttrNum] = useState(0);
    //地域別売上金額
    const [mapOption, setMapOption] = useState({});
    

    useEffect(() => {
     const getEntryInfo = async () => {
      setEntryOption(getOptionInfo([], [], 'entry'));
      setSalesAmountOption(getOptionInfo([], [], 'salesAmount'));
      setGoodsOption(getGoodsOptionInfo([], []));
      setAttrOption(getAttrOptionInfo([]));
      //地域
      const mapJsonInfo = await API.getMapJson();
      if (mapJsonInfo.data !== undefined) {
        echarts.registerMap('japanMap7', mapJsonInfo.data);
        setMapOption(getMapOptionInfo([], 10));
      }
  
      var attrStr = '';
      var attrIdList = [];
      const campaignInfo = await API.getCampaignInfo(showId, token);
      if (campaignInfo.message === '' && campaignInfo.data !== undefined
         && campaignInfo.data['customerAttribution'] !== undefined) {
           campaignInfo.data['customerAttribution'].forEach(item => {
              if (item.id !== undefined) {
               attrIdList = [...attrIdList, item.id];
               if (attrStr === '' && item.id === 'age') {
                 attrStr = 'age';
               }
              }
           })
      }
       //レシート応募数
       const getResultInfo = API.getStatisticsData(showId, token, 'entry', 'day', '', '', '', undefined, undefined, {});
       //売上金額
       const getAmountInfo = API.getStatisticsData(showId, token, 'salesAmount', 'day', '', '', '', undefined, undefined, {});
       //商品別売上金額
       const getGoodsList = API.getStatisticsData(showId, token, 'salesAmount', '', 'list', '', '', undefined, undefined, {});
       //消費者属性
       let getAttrInfo;
       if (attrStr!== '') {
          getAttrInfo = API.getStatisticsData(showId, token, 'attributionRatio', '', '', attrStr, '', undefined, undefined, {});
       } else if (attrIdList.length > 0) {
          getAttrInfo = API.getStatisticsData(showId, token, 'attributionRatio', '', '', attrIdList[0], '', undefined, undefined, {});
       }
       //地域別売上金額
       const getRegionList = API.getStatisticsData(showId, token, 'salesAmount', '', '', '', 'list', undefined, undefined, {});

       Promise.all([getResultInfo, getAmountInfo, getGoodsList, getAttrInfo, getRegionList]).then(function(values) {
        const [resultInfo, amountInfo, goodsList, attrInfo, regionList] = values;

        var message = '';
        if (resultInfo.message !== '') {
          message = resultInfo.message;
        } else if (amountInfo.message !== '') {
          message = amountInfo.message;
        } else if (goodsList.message !== '') {
          message = goodsList.message;
        } else if (attrInfo.message !== '') {
          message = attrInfo.message;
        } else if (regionList.message !== '') {
          message = regionList.message;
        }

        if (message !== '') {
          const modal = Modal.error();
          modal.update({
          title:'エラー',
          content:message,
          onOk() {
              modal.destroy();
          }
          })
        } else {
          const dataInfo = resultInfo.data;
          if (dataInfo !== undefined && dataInfo.data !== undefined && dataInfo.data.length === 1) {
            if (dataInfo.data[0]['summary'] !== undefined) {
              setEntryNum(dataInfo.data[0]['summary'])
            }
            if (dataInfo.data[0]['data1'] !== undefined && dataInfo.data[0]['data1'].length > 0
              && dataInfo.data[0]['data2'] !== undefined && dataInfo.data[0]['data2'].length > 0) {
              let option = getOptionInfo(dataInfo.data[0]['data1'], dataInfo.data[0]['data2'], 'entry');
              setEntryOption(option);
            }
          }
          const amountData = amountInfo.data;
          if (amountData !== undefined && amountData.data !== undefined && amountData.data.length === 1) {
            if (amountData.data[0]['summary'] !== undefined) {
              setSalesAmountNum(amountData.data[0]['summary'])
            }
            if (amountData.data[0]['data1'] !== undefined && amountData.data[0]['data1'].length > 0
              && amountData.data[0]['data2'] !== undefined && amountData.data[0]['data2'].length > 0) {
              let option = getOptionInfo(amountData.data[0]['data1'], amountData.data[0]['data2'], 'salesAmount');
              setSalesAmountOption(option);
            }
          }
          const goodsDataList = goodsList.data;
          if (goodsDataList !== undefined && goodsDataList.data !== undefined && goodsDataList.data.length > 0) {
            const dataList = goodsDataList.data;
            var data1 = [];
            var data2 = [];
            for (var i=0; i < dataList.length; i++) {
              if (dataList[i]['name'] !== undefined && dataList[i]['summary'] !== undefined) {
                data1 = [...data1, dataList[i]['name']];
                data2 = [...data2, dataList[i]['summary']];
              }
            }
            if (data1.length > 0 && data2.length > 0) {
              let option = getGoodsOptionInfo(data1, data2);
              setGoodsOption(option);
            }
          }
          const attrData = attrInfo.data;
          if (attrData !== undefined && attrData.data !== undefined && attrData.data.length === 1) {
            if (attrData.data[0]['summary'] !== undefined) {
              setAttrNum(attrData.data[0]['summary'])
            }
            if (attrData.data[0]['data1'] !== undefined && attrData.data[0]['data1'].length > 0
            && attrData.data[0]['data2'] !== undefined && attrData.data[0]['data2'].length > 0) {
              let data = [];
              for (var n=0; n<attrData.data[0]['data1'].length; n++) {
              let item = {};
              item['name'] = attrData.data[0]['data1'][n];
              item['value'] = attrData.data[0]['data2'][n]
              data = [...data, item];
              }
              let option = getAttrOptionInfo(data);
              setAttrOption(option);
          }
          }
          const mapJsonData = mapJsonInfo.data;
          const regionDataList = regionList.data;
          if (mapJsonData !== undefined && regionDataList !== undefined && regionDataList.data !== undefined 
            && regionDataList.data.length > 0) {
              let data = [];
              let maxValue = 10;
              for (var r=0; r <regionDataList.data.length; r++) {
                let item = {};
                if (regionDataList.data[r]['name'] !== undefined && regionDataList.data[r]['summary'] !== undefined) {
                  item['name'] = regionDataList.data[r]['name'];
                  item['value'] = regionDataList.data[r]['summary'];
                  if (item['name'] !== 'データなし' && regionDataList.data[r]['summary'] > maxValue) {
                    maxValue = Math.ceil(regionDataList.data[r]['summary']);
                  }
                  data = [...data, item];
                }
              }
              setMapOption(getMapOptionInfo(data, maxValue)); 
          }
        }
      });
     };
     getEntryInfo();
    },[]); //eslint-disable-line

    const getOptionInfo = (data1, data2, type) => {
      let tipStr = '';
      if (type === 'entry') {
        tipStr = '応募数（件）';
      } else if (type === 'salesAmount') {
        tipStr = '売上金額（円）';
      }
      let option = {
        tooltip:{
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            snap: true,
            label: {
              show: false
            }
          },
          borderColor:'#fff',
          padding:[0,0,0,0],
          borderWidth:0,
          formatter: (params) => {
           var res = "<table class='c3-tooltip'>" +
                 "<tr><th colSpan='2'>" + params[0].name.replaceAll('-', '/') + "</th></tr>" +
                 "<tr><td class='name'><span style='background-color:#1f77b4;'></span>" + tipStr + "</td><td>"+ params[0].value.toLocaleString() +"</td></tr></table>"
           return res
          }
        },
        grid:{
          top: 30,
          bottom: 100,
          left: 50
        },
        xAxis:{
          type:'category',
          axisLine: {
            show: true,
            lineStyle:{
              color:'#ccc'
            }
          },
          splitLine:{
            show:false
          },
          axisTick:{
            show:true
          },
          axisLabel: {
            show: true,
            interval: getInterval(data1),
            formatter: function (value) {
              var date = new Date(value)
              var texts = [(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1), 
              date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
              ]
              return texts.join('/')
            }
          },
          data:data1
        },
        yAxis:{
          type:'value',
          axisLine: {
            show:true,
            lineStyle:{
              color:'#ccc'
            }
          },
          splitLine:{
            show:false
          },
          axisTick:{
            show:true,
          },
          axisLabel:{
            show:true,
            rotate: -45,
            formatter: function (value) {
              if (type === 'salesAmount') {
                return (value / 1000).toLocaleString();
              }
              return value;
            }
          },
          name: type === 'salesAmount' ? '(千円)' : '',
          nameTextStyle: {
            align: 'left'
          },
        },
        series:[{
          type:'line',
          showSymbol: false,
          lineStyle:{
           color:'#1f77b4',
           width:1,
          },
          data:data2
        }]
      };
      return option
    }

  const getInterval = (data1) => {
    const len = data1.length
    if (len <= 49) {
      return 6
    } else if (len > 49 && len < 98) {
      return 13
    } else if (len > 98 && len < 180) {
      return 20
    } else {
      return "auto"
    }
  }

    const getGoodsOptionInfo = (data1, data2) => {
      let tipStr = '売上金額（円）';
      let option = {
        tooltip:{
          trigger: 'axis',
          borderColor:'#fff',
          padding:[0,0,0,0],
          borderWidth:0,
          formatter: (params) => {
           var res = "<table class='c3-tooltip'>" +
                 "<tr><th colSpan='2'>" + params[0].name + "</th></tr>" +
                 "<tr><td class='name'><span style='background-color:#1f77b4;'></span>" + tipStr + "</td><td>"+ params[0].value.toLocaleString() +"</td></tr></table>"
           return res
          }
        },
        grid:{
          left:200,
          top: 20
        },
        xAxis:{
          type:'value',
          splitNumber: 3,
          axisLine: {
            show:true,
            lineStyle:{
              color:'#ccc'
            }
          },
          splitLine:{
            show:false
          },
          axisTick:{
            show:true,
          },
          axisLabel:{
            show:true
          },
        },
        yAxis:{
          type:'category',
          axisLine: {
            show: true,
            lineStyle:{
              color:'#ccc'
            }
          },
          splitLine:{
            show:false
          },
          axisTick:{
            show:true
          },
          axisLabel:{
            show:true,
            width:170,
            color:'#333',
            overflow:'truncate'
          },
          data:data1
        },
        series:[{
          type:'bar',
          showSymbol: false,
          itemStyle:{
           color:'#1f77b4'
          },
          data:data2
        }]
      };
      return option
    }

    const getAttrOptionInfo = (data) => {
      let option = {
        tooltip:{
          trigger: 'item',
          borderColor:'#fff',
          borderWidth:0,
          padding:[0,0,0,0],
          formatter: (param) => {
              var res = "<table class='c3-tooltip'>" +
                    "<tr><td class='name'><span style='background-color:"+ param.color+";'></span>" + param.name + "</td><td class='value'>"+ param.percent +"%</td></tr></table>"
              return res
             }
        },
        color:['#626A70', '#8AC9F2', '#D2E4F0', '#405D70', '#A4B3BD'],
        legend: {
          type: 'scroll',
          icon:'rect',
          orient: 'vertical',
          height:240,
          right:10,
          top:'middle',
          itemWidth:10,
          itemHeight:10,
          textStyle:{
            color:'#000',
            fontSize:12,
            width:70,
            overflow:'truncate'
          },
          pageTextStyle:{
            color:'#000',
            fontSize:12,
          },
          pageIconSize:10
        },
        series:[{
          type:'pie',
          radius: '72%',
          colorBy:'data',
          left:-80,
          top: -20,
          bottom: 50,
          emphasis:{
            focus:'self'
          },
          label:{
            show:false
          },
          data:data
        }]
      };
      return option
    };

    const getMapOptionInfo = (data, maxVal) => {
     let option = {
      tooltip:{
        trigger: 'item',
        borderColor:'#fff',
        borderWidth:0,
        padding:[0,0,0,0],
        formatter: (param) => {
            var data = 0;
            if (param.value !== undefined && !isNaN(param.value)) {
              data = param.value;
            }
            var res = "<table class='c3-tooltip'>" +
                  "<tr><td class='name'>" + param.name + "</td><td class='value'>" + Number(data).toLocaleString() +" 円</td></tr></table>"
            return res
           }
        },
       visualMap: {
         min: 0,
         max: maxVal,
         calculable: true,
         right: 10,
         bottom: -10,
         orient: 'horizontal',
         itemHeight: 150,
         itemWidth: 15,
         inRange: {
           color: [
             "#d2e4f0",
             "#92bedb",
             "#5398c6",
             "#316b92",
             "#316b92",
             "#1c3d53"
           ]
         },
         formatter: function (value) {
          return value.toLocaleString();
         },
         show: true,
       },
       series: [{
         type: 'map',
         map: 'japanMap7',
         center: [135.3, 36.0],
         zoom: 10,
         top: 25,
         data: data
       }]
     };
     return option;
    }

    return (
      <Wrapper>
         <Header showFlag={'page3'} showName={showName} showSpan={showSpan} showId={showId}/>
         <main>
           <IndexDiv>
              <CardDiv>
                  <TitleDiv>レシート応募数</TitleDiv>
                  <SummaryDiv><p><SummarySpan>合計:</SummarySpan>{entryNum.toLocaleString()}件</p></SummaryDiv>
                  <div><ReactEcharts option={entryOption}/></div>
              </CardDiv>
              <CardDiv>
                  <TitleDiv>売上金額</TitleDiv>
                  <SummaryDiv><p><SummarySpan>合計:</SummarySpan>{salesAmountNum.toLocaleString()}円</p></SummaryDiv>
                  <div><ReactEcharts option={salesAmountOption}/></div>
              </CardDiv>
              <CardDiv>
                  <TitleDiv>消費者属性</TitleDiv>
                  <SummaryDiv><p><SummarySpan>平均購入金額:</SummarySpan>{attrNum.toLocaleString()}円</p></SummaryDiv>
                  <div><ReactEcharts option={attrOption}/></div>
              </CardDiv>
              <CardDiv>
                  <TitleDiv>地域別売上金額</TitleDiv>
                  <div><ReactEcharts option={mapOption} style={{height: '350px'}}/></div>
              </CardDiv>
              <CardSalesDiv>
                  <TitleDiv>商品別売上金額</TitleDiv>
                  <div><ReactEcharts option={goodsOption} style={{height: '400px'}}/></div>
              </CardSalesDiv>
           </IndexDiv>
        </main> 
        <Footer/>
      </Wrapper>
    )
}

export default Top;