import React from "react";
import styled from "styled-components";
import loading from "./images/loading.gif";

const Loader = () => {
  return (
    <LoaderContainer>
      <LoaderImg src={loading} />
      <LoadingSpan> 読み込み中</LoadingSpan>
    </LoaderContainer>
  );
};


const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: #CCCCCC;
  z-index: 100;
`;
const LoaderImg = styled.img`
  position: absolute;
  width:50px;
  height:50px;
`;

export const LoadingSpan = styled.span`
position: absolute;
  margin-top:70px;
`;

export default Loader;