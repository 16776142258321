import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import API from '../API';
import cookie from 'react-cookies';
import moment from 'moment';
import { Modal } from 'antd';
import { Wrapper, Title, Content, Ahref, Card,
     CardUl, CardLi, CardContent, CardAdd, CardAddi } from './CampaignList.styles';
import {useNavigateParams} from '../Utility';


const CampaignList = () => {
    const token = cookie.load('token');
    const [campaignInfo, setCampaignInfo] = useState([]);
    // const navigate = useNavigate();
    const navigate = useNavigateParams();

    useEffect(() => {
        const getResultInfo =  async () => {
            const resultInfo = await API.getCampaignDataList(token);
            if (resultInfo.message !== '') {
                const modal = Modal.error();
                modal.update({
                title:'エラー',
                content:resultInfo.message,
                onOk() {
                    modal.destroy();
                 }
                })
            } else {
                const dataInfo = resultInfo.data;
                console.log(resultInfo);

                console.log(dataInfo);
                setCampaignInfo([...dataInfo]);
            }
          }
          getResultInfo();
    },[]) //eslint-disable-line

    const handleMoveClick = (item) => {
        let dateSpan = moment(item.entryStartTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD HH:mm') + 
            '～'+ moment(item.entryEndTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD HH:mm');
            // localStorage.setItem('showId',item.campaignId );
            localStorage.setItem('showName',item.campaignName );
            localStorage.setItem('showSpan',dateSpan );

        // navigate('/campaigns/top', {state:{showId:item.campaignId,showName:item.campaignName,showSpan:dateSpan}});
        navigate("/campaigns/top", `campaignId=${item.campaignId}`);

    }

    return (
        <Wrapper>
            <Header showFlag={'page2'}/>
            <main>
            <div>
                <Title>キャンペーン一覧</Title>
            </div>
            <div>
                <Content>
                    {campaignInfo.map((item, index) =>
                    <Ahref onClick={() => handleMoveClick(item)} key={index}>
                        <Card>
                            <h3>{item.campaignName}</h3>
                            <CardUl>
                                <CardLi>
                                    <div>キャンペーンID:</div>
                                    <CardContent>{item.campaignId}</CardContent>
                                </CardLi>
                                <CardLi>
                                    <div>応募期間:</div>
                                    <CardContent>{moment(item.entryStartTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD HH:mm')}～{moment(item.entryEndTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD HH:mm')}</CardContent>
                                </CardLi>
                                <CardLi>
                                    <div>レシート有効期間:</div>
                                    <CardContent>{moment(item.validPeriodStartTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD HH:mm')}～{moment(item.validPeriodEndTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD HH:mm')}</CardContent>                                  
                                </CardLi>
                            </CardUl>
                        </Card> 
                    </Ahref> 
                    )}
                    <Ahref href='/campaigns/addition'>
                        <CardAdd>
                            <div>キャンペーン追加</div>
                            <CardAddi className='fa-solid fa-circle-plus'/>
                        </CardAdd>
                    </Ahref>                  
                </Content>
            </div>
            </main>
            <Footer/>
        </Wrapper>
    ) 
}

export default CampaignList;