import { useNavigate, generatePath } from 'react-router-dom';


export const useNavigateParams = () => {
    const navigate = useNavigate();
  
    return (url, params, stateValue) => {
      const path = generatePath(":url?:queryString", {
        url,
        queryString: params
      });
      navigate(path,{ state: stateValue });
    };
  };