import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: 70px auto 80px;
`;

export const Title = styled.h2`
  font-size: 1.3em;
  margin: 18px 20px;
`;

export const Content = styled.div`
  margin: 10px auto;
  width: fit-content;
`;

export const ContentTh = styled.th`
  text-align: right;
  font-weight: normal;
  padding: 4px 12px 4px 6px;
`;

export const ContentTop = styled.th`
  text-align: right;
  font-weight: normal;
  padding: 4px 12px 4px 6px;
  vertical-align: top;
`;

export const ContentText = styled.input`
  height: 20px;
  font-size: 0.9em;
`;

export const ContentNum = styled.input`
  width: 100px;
  height: 20px;
`;

export const ContentTime = styled.input`
  margin-left:5px;
`;

export const AttrEdit = styled.div`
  width: 350px;
  padding: 6px 12px;
  margin-top: 6px;
  border: #f2f2f2 1.5px solid;
  border-radius: 6px;
`;

export const AttrAdd = styled.div`
  margin-top: 6px;
`;

export const AttrAddSelect = styled.select`
  width: 10em;
  height: 25px;
  font-size: 0.9em;
  margin-left: 3px;
  margin-right: 6px;
`;

export const AttrAddButton = styled.button`
  display: inline-block;
  border-radius: 4px;
  box-shadow: 1px 1px 0.5px 0.5px rgba(3,3,3,0.3);
  &.active{
    box-shadow:inset 0 0 1px 0.5px rgba(3,3,3,0.3);
  }
  min-width: 4em;
  text-align: center;
  padding: 2px 12px;
  margin-left: 0;
  color: #333333;
  font-size: 1em;
  border:none;
  text-align:center;
  text-decoration:none;
  background-color:lightgray;
  border-color:lightgray;
  cursor: pointer;
`;

export const ButtonCell = styled.td`
  text-align: right;
  padding-top: 20px;
`;

export const ButtonHref = styled.a`
  text-decoration: none;
  color: #333333;
  font-size: 1em;
  display: inline-block;
  background-color: lightgray;
  border-radius: 4px;
  box-shadow:1px 1px 0.5px 0.5px rgba(3,3,3,0.3);
  &.active{
    box-shadow: inset 0 0 1px 0.5px rgba(3,3,3,0.3);
  }
  cursor: pointer;
  min-width: 6em;
  text-align: center;
  padding: 2.5px 12px;
  margin-left: 6px;
  border:none;
  text-align:center;
  border-color:lightgray;
`;

export const ButtonDiv = styled.div`
  display: inline-block;
  background-color: lightgray;
  border-radius: 4px;
  box-shadow:1px 1px 0.5px 0.5px rgba(3,3,3,0.3);
  &.active{
    box-shadow: inset 0 0 1px 0.5px rgba(3,3,3,0.3);
  }
  cursor: pointer;
  min-width: 4em;
  text-align: center;
  margin-left: 6px;
  padding: 2px 12px;
`;

export const ButtonSave = styled.button`
display: inline-block;
border-radius: 4px;
box-shadow:1px 1px 0.5px 0.5px rgba(3,3,3,0.3);
&.active{
  box-shadow: inset 0 0 1px 0.5px rgba(3,3,3,0.3);
}
cursor: pointer;
min-width: 6em;
text-align: center;
padding: 2px 12px;
margin-left: 6px;
color: #333333;
font-size: 1em;
border:none;
text-align:center;
background-color:lightgray;
border-color:lightgray;
`;

export const ButtonCancel = styled.button`
  display: inline-block;
  border-radius: 4px;
  box-shadow:1px 1px 0.5px 0.5px rgba(3,3,3,0.3);
  &.active{
    box-shadow: inset 0 0 1px 0.5px rgba(3,3,3,0.3);
  }
  cursor: pointer;
  min-width: 6em;
  text-align: center;
  padding: 2px 12px;
  margin-left: 6px;
  color: #333333;
  font-size: 1em;
  border:none;
  text-align:center;
  background-color:lightgray;
  border-color:lightgray;
`;

export const AttrLabel = styled.label`
  display: inline-block;
  width: 4em;
`;

export const AttrContent = styled.input`
  width: 200px;
  height: 20px;
  margin-bottom: 6px;
  padding-left: 2px;
  font-size: 0.9em;
`;

export const AttrAddDiv = styled.div`
   display: flex;
`;

export const AttrAddInput = styled.input`
  height: 20px;
  font-size: 0.9em;
  padding-left: 2px;
  flex-grow: 1;
`;

export const WrapDiv = styled.div`
  margin-top: 6px;
  align-items: flex-start;
  align-content: stretch;
  height: 120px;
  overflow-y: scroll;
  border: #f2f2f2 1.5px solid;
  background-color: #f2f2f2;
`;

export const WrapTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  background-color: white;
`;

export const WrapTr = styled.tr`
  border: lightgray 1px solid;
`;

export const WrapTdFir = styled.td`
  width: 22px;
  text-align: center;
`;

export const WrapTd = styled.td`
  padding: 3px 4px;
  cursor: default;
`;

export const WrapArrow = styled.td`
  padding: 3px 4px;
  cursor: default;
  width: 1em;
  color: lightgrey;
  text-align: center;
  font-size: smaller;
  cursor: pointer;
`;

export const WrapInput = styled.input`
  transform: scale(1.2);
`;

export const WrapButtonDiv = styled.div`
  margin-top: 6px;
  text-align: right;
  display: flex;
  justify-content: space-between;
`;



