import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: 70px auto 80px;
`;

export const Title = styled.h2`
   font-size: 1.3em;
  margin: 18px 20px;
`;

export const ContentTh = styled.th`
  text-align: right;
  font-weight: normal;
  padding: 4px 12px 4px 20px;
`;

export const ContentText = styled.input`
  height: 20px;
  width:125%;
  font-size: 0.9em;
`;

export const ContentNum = styled.input`
  width: 100px;
  height: 20px;
`;


export const ButtonCell = styled.div`
  display:flex;
  -webkit-justify-content: flex-end;;
    justify-content: flex-end;;
    -webkit-align-items: flex-end;;
    align-items:flex-end;;
  padding: 20px;
`;

export const ButtonSave = styled.button`
display: inline-block;
border-radius: 4px;
box-shadow:1px 1px 0.5px 0.5px rgba(3,3,3,0.3);
&.active{
  box-shadow: inset 0 0 1px 0.5px rgba(3,3,3,0.3);
}
:disabled { 
  opacity:0.4;
  }
cursor: pointer;
min-width: 3em;
text-align: center;
padding: 2px 12px;
margin-left: 6px;
color: #333333;
font-size: 1em;
border:none;
text-align:center;
background-color:lightgray;
border-color:lightgray;
`;

export const SerchTd = styled.td`
 padding-right:100px;
 
`;


export const SerchUnitTd = styled.td`
padding:0;
 
`;


export const AttrEdit = styled.div`
  width: 395px;
  padding: 6px 12px;
  margin-top: 6px;
  border: #f2f2f2 1.5px solid;
  border-radius: 6px;
`;

export const AttrAddButton = styled.button`
  display: inline-block;
  border-radius: 4px;
  box-shadow: 1px 1px 0.5px 0.5px rgba(3,3,3,0.3);
  &.active{
    box-shadow:inset 0 0 1px 0.5px rgba(3,3,3,0.3);
  }
  min-width: 4em;
  text-align: center;
  padding: 2px 12px;
  margin-left: 0;
  color: #333333;
  font-size: 1em;
  border:none;
  text-align:center;
  text-decoration:none;
  background-color:lightgray;
  border-color:lightgray;
  cursor: pointer;
`;


export const AttrAddDiv = styled.div`
   display: flex;
`;

export const AttrAddInput = styled.input`
  height: 20px;
  font-size: 0.9em;
  padding-left: 2px;
  flex-grow: 1;
`;

export const WrapDiv = styled.div`
  margin-top: 6px;
  align-items: flex-start;
  align-content: stretch;
  height: 300px;
  overflow-y: scroll;
  border: #f2f2f2 1.5px solid;
  background-color: #f2f2f2;
`;

export const WrapTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  background-color: white;
`;

export const WrapTr = styled.tr`
  border: lightgray 1px solid;
`;

export const WrapTdFir = styled.td`
  width: 22px;
  text-align: center;
`;

export const WrapTd = styled.td`
  padding: 3px 2px;
  cursor: default;
`;


export const WrapInput = styled.input`
  transform: scale(1.2);
`;

export const WrapButtonDiv = styled.div`
  margin-top: 6px;
  text-align: right;
  justify-content: space-between;
`;
export const ListFlexBox = styled.div`
  display: flex;
   
`;
export const ListContent = styled.div`
  flex-direction: row;
  margin:10px 20px;
`;

export const CenterWrapper = styled.div`
  width: fit-content;
  margin: 0 auto;
`;

export const RightWrapper = styled.div`
  text-align: right;
  margin-top: 12px;
  padding-right: 20px;
`;
