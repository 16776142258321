import React, { useState, useEffect} from 'react';
import Footer from './Footer';
import Header from './Header';
import API from '../API';
import cookie from 'react-cookies';
import ReactEcharts from 'echarts-for-react';
// import "default-passive-events";
import moment from 'moment';
import { Modal } from 'antd';
import { Wrapper, FilterDiv, SubTitleDiv, SelectDate, InputDate, InputTime, SelectRegion, UpdateButton,
  RegionDiv, DetailsSummary, SubTitleH2, FilterSelect, DetailsArrow, RegionDetail, DetailCard, TitleDiv,
  RegionSummary, SummarySpan, ConsumerDiv} from './Common.styles';
// import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const Region = () => {
    const token = cookie.load('token');
    // const {state:{showId,showName,showSpan}} = useLocation();
    const [searchParams] = useSearchParams();
    const showId = searchParams.get('campaignId');
    const showName = localStorage.getItem('showName');
    const showSpan = localStorage.getItem('showSpan');
    //地域リスト
    const [regionList, setRegionList] = useState([]);
    //表示商品
    const [productList, setProductList] = useState([{'code':'all', 'name':'全て'}]);
    const [selectProduct, setSelectProduct] = useState('all');
    //表示期間-開始
    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    //表示期間-終了
    const [endDate, setEndDate] = useState('');
    const [endTime, setEndTime] = useState('');
    //消費者属性
    const [attrParam, setAttrParam] = useState('');
    //都道府県
    const prefectureOpList = ['北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県',
    '埼玉県', '千葉県', '東京都', '神奈川県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県',
    '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県',
    '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県', 'データなし'];
    
    useEffect(() => {
      const getRegionList = async () => {
       var attrStr = '';
       var attrIdList = [];
       const campaignInfo = await API.getCampaignInfo(showId, token);
       if (campaignInfo.message === '' && campaignInfo.data !== undefined 
          && campaignInfo.data['customerAttribution'] !== undefined) {
            campaignInfo.data['customerAttribution'].forEach(item => {
               if (item.id !== undefined) {
                attrIdList = [...attrIdList, item.id];
                if (attrStr === '' && item.id === 'age') {
                  attrStr = 'age';
                }
               }
            })
       }
       // 開始日時、終了日時
       var startSpan = undefined
       var endSpan = undefined
       const resultData = campaignInfo.data
       if (resultData.validPeriodStartTime !== undefined && resultData.validPeriodStartTime.trim() !== ''
        && resultData.validPeriodEndTime !== undefined && resultData.validPeriodEndTime.trim() !== '') {
         startSpan = new Date(resultData.validPeriodStartTime).getTime()
         endSpan = new Date(resultData.validPeriodEndTime).getTime()
         var tempStartDateTime = resultData.validPeriodStartTime.split(" ")
         var tempEndDateTime = resultData.validPeriodEndTime.split(" ")
         setStartDate(tempStartDateTime[0])
         setStartTime(tempStartDateTime[1].slice(0, 5))
         setEndDate(tempEndDateTime[0])
         setEndTime(tempEndDateTime[1].slice(0, 5))
       }
       //表示商品
       const getProductInfo = API.getproductWordsData(showId, token);
       //売上金額
       const getSalesInfo = API.getStatisticsData(showId, token, 'salesAmount', 'day', '', '', 'list', startSpan, endSpan, {});
       //販売単価別売上数量
       const getUnitInfo = API.getStatisticsData(showId, token, 'unitAmount', '', '', '', 'list', startSpan, endSpan, {});
       //消費者属性
       var getAttrInfo = {message:'',data:[]};
       if (attrStr !== '') {
         setAttrParam(attrStr);
         getAttrInfo = API.getStatisticsData(showId, token, 'attributionRatio', '', '', attrStr, 'list', startSpan, endSpan, {});
       } else if (attrIdList.length > 0) {
         setAttrParam(attrIdList[0]);
         getAttrInfo = API.getStatisticsData(showId, token, 'attributionRatio', '', '', attrIdList[0], 'list', startSpan, endSpan, {});
       }

       Promise.all([getProductInfo, getSalesInfo, getUnitInfo, getAttrInfo]).then(function(values) {
        const [productInfo, salesInfo, unitInfo, attrInfo] = values;

        var message = '';
        if (productInfo.message !== '') {
          message = productInfo.message;
        } else if (salesInfo.message !== '') {
          message = salesInfo.message;
        } else if (unitInfo.message !== '') {
          message = unitInfo.message;
        } else if (attrInfo.message !== '') {
          message = attrInfo.message;
        }

        if (message !== '') {
          const modal = Modal.error();
          modal.update({
          title:'エラー',
          content:message,
          onOk() {
              modal.destroy();
          }
          })
        } else {
          const productData = productInfo.data;
          var proList = [{'code':'all', 'name':'全て'}];
          if (productData !== undefined && productData.length > 0) {
            for (var f=0; f <productData.length; f++) {
              if (productData[f]['janCode'] !== undefined && productData[f]['janCode'] !== ''
              && productData[f]['brandName'] !== undefined && productData[f]['brandName'] !== '') {
                let dto = {};
                dto['code'] = productData[f]['janCode']
                dto['name'] = productData[f]['brandName'];
                proList = [...proList, dto];
              }
            }
          }
          setProductList(proList);
          const salesData = salesInfo.data;
          const unitData = unitInfo.data;
          const attrData = attrInfo.data;
          var data1 = [];
          var data2 = [];
          var data3 = [];
          if (salesData !== undefined && salesData.data !== undefined && salesData.data.length > 0) {
            data1 = salesData.data;
          }
          if (unitData !== undefined && unitData.data !== undefined && unitData.data.length > 0) {
            data2 = unitData.data;
          }
          if (attrData !== undefined && attrData.data !== undefined && attrData.data.length > 0) {
            data3 = attrData.data;
          }
          setRegionDataInfo(data1, data2, data3);
        }
      });
     };
     getRegionList();
    },[]); //eslint-disable-line

    const handleProductChange = (e) => {
      setSelectProduct(e.target.value);
    };
    //更新ボタン
    const handleUpdateClick = async () => {
     var msg = '';
     if ((startDate === '' || startDate.trim() === '') && startTime !== '' && startTime.trim() !== '') {
      msg = '完全な表示期間開始日時を入力してください。';
     } else if (startDate !== '' && startDate.trim() !== '' && (startTime === '' || startTime.trim() === '')) {
      msg = '完全な表示期間開始日時を入力してください。';
     } else if ((endDate === '' || endDate.trim() === '') && endTime !== '' && endTime.trim() !== '') {
      msg = '完全な表示期間終了日時を入力してください。';
     } else if (endDate !== '' && endDate.trim() !== '' && (endTime === '' || endTime.trim() === '')) {
      msg = '完全な表示期間終了日時を入力してください。';
     } else if (startDate !== '' && startDate.trim() !== '' && startTime !== '' && startTime.trim() !== ''
     && endDate !== '' && endDate.trim() !== '' && endTime !== '' && endTime.trim() !== '') {
       if (getStartDateTime(startDate, startTime) > getEndDateTime(endDate, endTime)) {
        msg = '表示期間の時間範囲が無効です。';
       }
     }
     if (msg !== '') {
      const modal = Modal.error();
      modal.update({
         title:'エラー',
         content:msg,
         onOk(){
            modal.destroy();
         }
      })
     } else {
      var startSpan = undefined;
      var endSpan = undefined;
      var productParam = '';
      if (startDate !== '' && startDate.trim() !== '' && startTime !== '' && startTime.trim() !== '') {
        startSpan = getStartDateTime(startDate, startTime);
      }
      if (endDate !== '' && endDate.trim() !== '' && endTime !== '' && endTime.trim() !== '') {
        endSpan = getEndDateTime(endDate, endTime);
      }
      if (selectProduct !== 'all') {
        productParam = selectProduct;
      }
      //売上金額
      const salesInfo = await API.getStatisticsData(showId, token, 'salesAmount', 'day', productParam, '', 'list', startSpan, endSpan, {});
      //販売単価別売上数量
      const unitInfo = await API.getStatisticsData(showId, token, 'unitAmount', '', productParam, '', 'list', startSpan, endSpan, {});
      //消費者属性
      var attrInfo =  {message:'',data:[]};
      if (attrParam !== '') {
        attrInfo = await API.getStatisticsData(showId, token, 'attributionRatio', '', productParam, attrParam, 'list', startSpan, endSpan, {});
      }
      var message = '';
      if (salesInfo.message !== '') {
        message = salesInfo.message;
       } else if (unitInfo.message !== '') {
        message = unitInfo.message;
       } else if (attrInfo.message !== '') {
        message = attrInfo.message;
       }
       if (message !== '') {
        const modal = Modal.error();
        modal.update({
        title:'エラー',
        content:message,
        onOk() {
            modal.destroy();
         }
        })
       } else {
        const salesData = salesInfo.data;
        const unitData = unitInfo.data;
        const attrData = attrInfo.data;
        var data1 = [];
        var data2 = [];
        var data3 = [];
        if (salesData !== undefined && salesData.data !== undefined && salesData.data.length > 0) {
          data1 = salesData.data;
        }
        if (unitData !== undefined && unitData.data !== undefined && unitData.data.length > 0) {
          data2 = unitData.data;
        }
        if (attrData !== undefined && attrData.data !== undefined && attrData.data.length > 0) {
          data3 = attrData.data;
        }
        setRegionDataInfo(data1, data2, data3);
       }
     }
    }

    const setRegionDataInfo = (data1, data2, data3) => {
      var finalData = [];
      if (data1.length > 0 || data2.length > 0 || data3.length > 0) {
        for (var i=0; i < prefectureOpList.length; i++) {
          var newItem = {'region':'', 'salesNum':0, 'unitNum':0, 'attrNum':0};
          newItem['salesOption'] = getSalesOption([],[]);
          newItem['unitOption'] = getUnitOption([],[]);
          newItem['attrOption'] = getAttrOption([]);
          var regionStr =  prefectureOpList[i];
          //売上金額
          for (var m=0; m < data1.length; m++) {
            if (data1[m]['name'] !== undefined && data1[m]['name'] !== '' && data1[m]['name'] === regionStr) {
              newItem['region'] = regionStr;
              if (data1[m]['summary'] !== undefined) {
                newItem['salesNum'] = data1[m]['summary'];
              }
              if (data1[m]['data1'] !== undefined && data1[m]['data1'].length > 0 &&
              data1[m]['data2'] !== undefined && data1[m]['data2'].length > 0) {
                newItem['salesOption'] = getSalesOption(data1[m]['data1'], data1[m]['data2']);
              }
              break;
            }
          }
          //販売単価別売上数量
          for (var n=0; n < data2.length; n++) {
            if (data2[n]['name'] !== undefined && data2[n]['name'] !== '' && data2[n]['name'] === regionStr) {
              newItem['region'] = regionStr;
              if (data2[n]['summary'] !== undefined) {
                newItem['unitNum'] = data2[n]['summary'];
              }
              if (data2[n]['data1'] !== undefined && data2[n]['data1'].length > 0 &&
              data2[n]['data2'] !== undefined && data2[n]['data2'].length > 0) {
                newItem['unitOption'] = getUnitOption(data2[n]['data1'], data2[n]['data2']);
              }
              break;
            }
          }
          //消費者属性
          for (var k=0; k < data3.length; k++) {
            if (data3[k]['name'] !== undefined && data3[k]['name'] !== '' && data3[k]['name'] === regionStr) {
              newItem['region'] = regionStr;
              if (data3[k]['summary'] !== undefined) {
                newItem['attrNum'] = data3[k]['summary'];
              }
              if (data3[k]['data1'] !== undefined && data3[k]['data1'].length > 0 &&
              data3[k]['data2'] !== undefined && data3[k]['data2'].length > 0) {
                let data = [];
                for (var h=0; h<data3[k]['data1'].length; h++) {
                 let item = {};
                 item['name'] = data3[k]['data1'][h];
                 item['value'] = data3[k]['data2'][h]
                 data = [...data, item];
                }
                newItem['attrOption'] = getAttrOption(data);
              }
              break;
            }
          }
          if (newItem['region'] !== undefined && newItem['region'] !== '') {
            finalData = [...finalData, newItem]
          }
        }
      }
      setRegionList(finalData);  
    };
    //開始日時（ミリ秒）
    const getStartDateTime = (dateStr, timeStr) => {
        return new Date(moment(dateStr).format('YYYY-MM-DD') + ' ' + moment(timeStr, 'HH:mm').format('HH:mm:00')).getTime();
    };
    //終了日時（ミリ秒）
    const getEndDateTime = (dateStr, timeStr) => {
        return new Date(moment(dateStr).format('YYYY-MM-DD') + ' ' + moment(timeStr, 'HH:mm').format('HH:mm:59')).getTime();
    };

    const getSalesOption = (data1, data2) => {
      let tipStr = '売上金額（円）';
      let option = {
        tooltip:{
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            snap: true,
            label: {
              show: false
            }
          },
          borderColor:'#fff',
          padding:[0,0,0,0],
          borderWidth:0,
          formatter: (params) => {
           var res = "<table class='c3-tooltip'>" +
                 "<tr><th colSpan='2'>" + params[0].name.replaceAll('-', '/') + "</th></tr>" +
                 "<tr><td class='name'><span style='background-color:#1f77b4;'></span>" + tipStr + "</td><td>"+ params[0].value.toLocaleString() +"</td></tr></table>"
           return res
          }
        },
        grid:{
          top: 40,
          bottom: 90,
          left: 50
        },
        xAxis:{
          type:'category',
          axisLine: {
            show: true,
            lineStyle:{
              color:'#ccc'
            }
          },
          splitLine:{
            show:false
          },
          axisTick:{
            show:true
          },
          axisLabel:{
            show:true,
            formatter: function (value) {
              var date = new Date(value)
              var texts = [(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1), 
              date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
              ]
              return texts.join('/')
            }
          },
          data:data1
        },
        yAxis:{
          type:'value',
          axisLine: {
            show:true,
            lineStyle:{
              color:'#ccc'
            }
          },
          splitLine:{
            show:false
          },
          axisTick:{
            show:true,
          },
          axisLabel:{
            show:true,
            rotate: -45
          },
        },
        series:[{
          type:'line',
          showSymbol: false,
          lineStyle:{
           color:'#1f77b4',
           width:1,
          },
          data:data2
        }]
      };
      return option
    };

    const getUnitOption = (data1, data2) => {
      let tipStr = '売上数量（個）';
      let option = {
        tooltip:{
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            snap: true,
            label: {
              show: false
            }
          },
          borderColor:'#fff',
          padding:[0,0,0,0],
          borderWidth:0,
          formatter: (params) => {
            var res = "<table class='c3-tooltip'>" +
                  "<tr><th colSpan='2'>" + Number(params[0].name).toLocaleString() + "</th></tr>" +
                  "<tr><td class='name'><span style='background-color:#1f77b4;'></span>" + tipStr + "</td><td>"+ params[0].value.toLocaleString() +"</td></tr></table>"
            return res
          }
        },
        grid:{
          top: 40,
          bottom: 90,
          left: 40
        },
        xAxis:{
          type:'category',
          name:'販売価格（円）',
          nameLocation:'center',
          nameGap:25,
          nameTextStyle:{
            color:'#000',
            fontSize:10,
            padding:[0,0,0,180],
            verticalAlign:'top'
          },
          axisLine: {
            show: true,
            lineStyle:{
              color:'#ccc'
            }
          },
          splitLine:{
            show:false
          },
          axisTick:{
            show:true
          },
          axisLabel:{
            show:true,
            color:'#ccc',
            formatter: function (value) {
              return Number(value).toLocaleString();
            }
          },
          data:data1
        },
        yAxis:{
          type:'value',
          boundaryGap: [0, '30%'],
          axisLine: {
            show:true,
            lineStyle:{
              color:'#ccc'
            }
          },
          splitLine:{
            show:false
          },
          axisTick:{
            show:true,
          },
          axisLabel:{
            show:true
          },
        },
        series:[{
          type:'line',
          smooth: 0.6,
          showSymbol: false,
          lineStyle:{
           color:'#1f77b4',
           width:1,
          },
          areaStyle: {
            color:'#ADD8E6'
          },
          data:data2
        }]
      };
      return option
    };

    const getAttrOption = (data) => {
      let option = {
        tooltip:{
          trigger: 'item',
          borderColor:'#fff',
          borderWidth:0,
          padding:[0,0,0,0],
          formatter: (param) => {
              var res = "<table class='c3-tooltip'>" +
                    "<tr><td class='name'><span style='background-color:"+ param.color+";'></span>" + param.name + "</td><td class='value'>"+ param.percent +"%</td></tr></table>"
              return res
             }
        },
        legend: {
          type: 'scroll',
          icon:'rect',
          orient: 'vertical',
          height:210,
          right:10,
          top:20,
          itemWidth:10,
          itemHeight:10,
          textStyle:{
            color:'#000',
            fontSize:12,
            width:70,
            overflow:'truncate'
          },
          pageTextStyle:{
            color:'#000',
            fontSize:12,
          },
          pageIconSize:10,
          tooltip: {
            show: true
          }
        },
        color:['#626A70', '#8AC9F2', '#D2E4F0', '#405D70', '#A4B3BD'],
        series:[{
          type:'pie',
          radius: '60%',
          colorBy:'data',
          left:-100,
          top: -10,
          bottom: 20,
          emphasis:{
            focus:'self'
          },
          label:{
            show:false
          },
          data:data
        }]
      };
      return option
    };

    return (
       <Wrapper>
         <Header showFlag={'page3'} showName={showName} showSpan={showSpan} showId={showId}/>
         <main>
           <FilterDiv>
             <SubTitleDiv><SubTitleH2>地域ごと統計</SubTitleH2></SubTitleDiv>
             <SelectDate>表示期間:</SelectDate>
             <InputDate type='date' value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
             <InputTime type='time' value={startTime} onChange={(e) => setStartTime(e.target.value)}/> ~&nbsp;
             <InputDate type='date' value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
             <InputTime type='time' value={endTime} onChange={(e) => setEndTime(e.target.value)}/>
             <SelectRegion>表示商品:</SelectRegion>
             <FilterSelect value={selectProduct} onChange={handleProductChange}>
              {productList.map((item, index) =>
                <option key ={index} value={item.code}>{item.name}</option>
               )}
             </FilterSelect>
             <UpdateButton onClick={handleUpdateClick}>更新</UpdateButton>
           </FilterDiv>
           <RegionDiv>
            {
              regionList.map((item, index) => 
              <details key={index} open>
                <DetailsSummary><DetailsArrow className='detail-arrow'>▲</DetailsArrow>{item.region}</DetailsSummary>
                <RegionDetail>
                    <DetailCard>
                      <TitleDiv>売上金額</TitleDiv>
                      <RegionSummary><p><SummarySpan>合計: </SummarySpan>{item.salesNum.toLocaleString()}円</p></RegionSummary>
                      <div><ReactEcharts option={item.salesOption}/></div>
                    </DetailCard>
                    <DetailCard>
                      <TitleDiv>販売単価別売上数量</TitleDiv>
                      <RegionSummary><p><SummarySpan>合計: </SummarySpan>{item.unitNum.toLocaleString()}個</p></RegionSummary>
                      <div><ReactEcharts option={item.unitOption}/></div>
                    </DetailCard>
                    <DetailCard>
                      <TitleDiv>消費者属性</TitleDiv>
                      <RegionSummary><p><SummarySpan>平均購入金額: </SummarySpan>{item.attrNum.toLocaleString()}円</p></RegionSummary>
                      <ConsumerDiv><ReactEcharts option={item.attrOption}/></ConsumerDiv>
                    </DetailCard>
                </RegionDetail>
              </details>
                )
              }
           </RegionDiv>
         </main>
         <Footer/>
       </Wrapper>
    )
}
export default Region;