import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: 70px auto 80px;
`;

export const Info = styled.div`
  margin: 0 18px;
`;

export const ImgDiv = styled.div`
  max-width: 730px;
  width: 30%;
  min-width: 400px;
  padding: 6px 12px;
  margin: 0px 18px 12px 18px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  text-align: center;
  flex-grow: 1;
`;

export const ImgDiv2 = styled.div`
  height: 635px;
  text-align: center;
  line-height: 635px;
  overflow:hidden;
  margin:6px 0;
  img {
    transform: ${props => `rotate(${props.imageRotation}deg)`};
  }
`;

export const AttrEdit = styled.div`
  min-width: 730px;
  padding: 6px 12px 10px 12px;
  margin-bottom: 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

export const Title = styled.h2`
  width:150px;
  margin: 18px 20px;
  font-size: 1.3em;
`;

export const AttrAddSelect = styled.select`
  width: 10em;
  height: 25px;
  font-size: 0.9em;
`;

export const WrapTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  background-color: white;
`;

export const WrapTable2 = styled.table`
  border-collapse: collapse;
  width: 100%;
  background-color: white;
  thead {
    position: sticky;
    top: 0;
    left: 0;
    background-color: white;
  }
`;

export const WrapDiv = styled.div`
  margin-top: 6px;
  margin-bottom: 6px;
  height: 300px;
  overflow-y: scroll;
  border: #f2f2f2 1.5px solid;
`;

export const WrapTr = styled.tr`
`;

export const ContentTH = styled.th`
  text-align:center;
  padding:5px 15px;
`;

export const ContentAttrName = styled.th`
  text-align: right;
  padding: 4px 6px 0px 0px;
`;

export const ContentAttrValue = styled.td`
  text-align: left;
  padding-top: 4px;
`;

export const ListValue = styled.td`
  vertical-align:middle;
`;

export const ListHref = styled.a`
  color:OrangeRed;
  text-decoration:underline;
  padding:0px 15px;
  margin-left:10px;
  cursor: pointer;
  display: block;
`;

export const HrefTd = styled.td`
  vertical-align:middle;
  text-align: center;
`;

export const ContentName = styled.td`
  text-align:left;
  width:190px;
  vertical-align:middle;
  padding:6px;
  padding-right: 12px;
`;

export const MyButton = styled.button`
  border-radius: 4px;
  box-shadow:1px 1px 0.5px 0.5px rgba(3,3,3,0.3);
  min-width: 8em;
  text-align: center;
  padding: 2px 12px;
  margin: 6px 0;
  color: #333333;
  font-size: 1em;
  border:none;
  background-color:lightgray;
  cursor: pointer;
`;

export const MyButton2 = styled.button`
  text-align: center;
  border:none;
  background-color:white;
  font-size: 20px;
  cursor: ${props => props.isActive ? "pointer" : "default"};
  color: ${props => props.isActive ? "#333333" : "lightgray"};
  padding: 3px 8px;
  
`;

export const ZoomButton = styled.button`
  width:30px;
  height:30px;
  border-radius: 50%;
  text-align: center;
`;

export const ButtonCell = styled.div`
  display:flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: flex-end;
  align-items:flex-end;
  padding-bottom: 18px;
`;

export const ButtonCell2 = styled(ButtonCell)`
  margin-right: 18px;
`;

export const DeleteButton = styled.button`
  display: inline-block;
  border-radius: 4px;
  box-shadow:1px 1px 0.5px 0.5px rgba(3,3,3,0.3);
  cursor: pointer;
  text-align: center;
  padding: 2px 12px;
  margin:3px 8px;
  color: #333333;
  font-size: 1em;
  border:none;
  background-color:lightgray;
  border-color:lightgray;
`;

export const ButtonSave = styled.button`
  display: inline-block;
  border-radius: 4px;
  box-shadow:1px 1px 0.5px 0.5px rgba(3,3,3,0.3);
  cursor: pointer;
  min-width: 3em;
  text-align: center;
  padding: 2px 12px;
  margin-left:12px;
  color: #333333;
  font-size: 1em;
  border:none;
  background-color:lightgray;
  border-color:lightgray;
`;

export const ContentNum = styled.input`
  width: 95px;
  text-align:right;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; 
`;

export const ContentNum2 = styled.input`
  margin: 0 5px;
  width: 65px;
  text-align:right;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; 
`;

export const ContentValue = styled.input`
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; 
`;

export const ProductsDiv = styled.div`
  width: 725px;
  height:420px;
  background-color: #f2f2f2;
  border: #f2f2f2 1.5px solid;
  border-radius: 4px;
`

export const ListFlexBox = styled.div`
  display: flex;
`
export const ListContent = styled.div`
  flex-direction: row;
`

export const WrapTdFir = styled.td`
  padding:5px;
  text-align: left;
`

export const CheckTd = styled.td`
  padding:5px;
  padding-left: 8px;
  text-align: left;
  width:30px;
`

export const NameTd = styled.td`
  padding:5px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const NameTh = styled.th`
  width:280px;
  text-align: center;
`

export const AnotherTh = styled.th`
  width:80px;
  text-align: center;
`

export const JanCodeTh = styled.th`
  width:125px;
  text-align: center;
  padding: 5px;
`

export const WrapTd = styled.td`
  text-align: center;
`

export const ProductTable = styled.table`
  text-align:left;
  width: 100%;
  table-layout: fixed;
  background-color: white;
  border-collapse: collapse;
  thead {
    position: sticky;
    top: -1px;
    left: 0;
    background-color: white;
  }
`

export const WrapDiv2 = styled.div`
  height: 290px;
  overflow-y: scroll;
  border: #f2f2f2 1.5px solid;
  background-color: white;
  margin: 0 18px 12px 18px;
`

export const ProductTr = styled.tr`
  border: lightgray 1px solid;
  label {
    display: block;
  }
`

export const ProductTitle = styled.h2`
  font-size: 1.3em;
  font-weight: bold;
  margin: 18px 20px;
`

export const ProductInfoList = styled.dialog`
  border: none;
  margin: 150px;
  z-index: 999;
  border-radius: 4px;
  ::backdrop {
    background: rgba(0,0,0,.6);
  }
`;

export const ReceiptContent = styled.div`
  line-height: 1.5;
  display: flex;
  justify-content: center;
  flex-wrap: wrap-reverse;
`;

export const ReceiptButtons = styled.div`
  display: grid;
  grid-template-columns: 35px 35px 160px 70px;
  align-items: center;
  justify-content: center;
  img {
    cursor: pointer;
    width: 24px;
    margin: 0 auto;
  }
  > i {
    cursor: pointer;
  } 
  div {
    display: inline-block;
  }
`;
