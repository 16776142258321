import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import API from '../API';
import cookie from 'react-cookies';
import { Modal } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import moment from 'moment';
import {
  Wrapper, Title, Content, ContentTh, ContentText, ContentNum, SerchTable, Serch, SerchTd, SerchUnitTd, ListTr, ListCodeTd, CheckTh,
  ContentTime, ButtonClear, Chceckdiv,
  /* ButtonCell, ,ListNameTd, ButtonSearchListTable Pop, TooltipCo, ListkeyTd, ListKeySurroundTd, ListTable,Pagination*/
  ListNumberTd, Listhref,
  ButtonSearch, ButtonSave,
  ListTable, ButtonCell, Pagination
} from './ReceiptList.style';
import ReactPaginate from 'react-paginate';
import { useNavigateParams } from '../Utility';

//450行目あたりでBootstrapインストール
let globalCurrentPage = 0;
let isSearched = false;


//ページネーション処理
function PaginatedItems({ handlereRceiptCheckClick, itemsPerPage, items, handleStatusAllCheck, handleStatusChange, statusChange, pageChecked, page, receiptSearchInfo, campaignId, handlePageChange }) {


  const [currentItems, setCurrentItems] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {

    if (isSearched) {
      isSearched = false;
      globalCurrentPage = 0;
      setItemOffset(0);
    } 
    setCurrentItems(items);

  }, [itemOffset, items, itemsPerPage]);

  const handlePageClick = (event) => {
    // const newOffset = (event.selected * itemsPerPage) % items.length;
    globalCurrentPage = event.selected;
    handlePageChange();
    setItemOffset(globalCurrentPage);
  };
  //検索結果をData型に変更
  const getDateTime = (x) => {
    return moment(x).format('YYYY/MM/DD HH:mm');
  }
  // レシート一覧の更新区分を以下のように、数字ではなく日本語で表示
  const setNewUpdateType = (value) => {
    if (value === 1) {
      return "一次判定";
    } else if (value === 2) {
      return "総合判定";
    } else if (value === 3) {
      return "目視判定";
    }
  };

  

  return (
    <>
      <ListTable>
        <thead>
          <ListTr key={globalCurrentPage}>
            <CheckTh><input type="checkbox" onChange={handleStatusAllCheck} id="allCheck" value={globalCurrentPage} checked={pageChecked.includes(globalCurrentPage)} /></CheckTh>
            <th>レシートID</th>
            <th>ステータス</th>
            <th>却下理由</th>
            <th>更新日時</th>
            <th>更新区分</th>
            <th>受付日時</th>
            <th>購入日時</th>
            <th>地域</th>
            <th>チェーン店名</th>
            <th>対象商品数量</th>
            <th>対象商品合計金額</th>
            <th>合計金額</th>
          </ListTr>
        </thead>
        <tbody>
          {
            currentItems &&
            currentItems.map((x, index) => (
              <ListTr key={index}>
                <td>
                  <input
                    name="check"
                    type="checkbox"
                    value={x.trackingId}
                    onChange={handleStatusChange}
                    checked={statusChange.includes(x.trackingId)}
                  />
                </td>
                <td><Listhref onClick={() => { handlereRceiptCheckClick(x) }} >{x.trackingId}</Listhref></td>
                <td>{x.status}</td>
                <ListCodeTd>{x.ngReason}</ListCodeTd>
                <td>{getDateTime(x.updateTime)}</td>
                <td>{setNewUpdateType(x.updateType)}</td>
                <td>{getDateTime(x.entryTime)}</td>
                <td>{getDateTime(x.salesTime)}</td>
                <td>{x.region}</td>
                <td>{x.chainStore}</td>
                <ListNumberTd>{x.targetItemCount}</ListNumberTd>
                <ListNumberTd>{x.targetItemTotal?.toLocaleString()}</ListNumberTd>
                <ListNumberTd>{x.totalAmount?.toLocaleString()}</ListNumberTd>
              </ListTr>
            ))
          }
        </tbody>
      </ListTable >

      <Pagination>
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}

          pageCount={page}
          previousLabel="<"
          renderOnZeroPageCount={null}
          marginPagesDisplayed={1} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
          pageRangeDisplayed={1} // アクティブなページを基準にして、そこからいくつページ数を表示するか

          pageClassName="page-item"
          pageLinkClassName="page-link"
          activeClassName="active"

          previousClassName="page-item"
          previousLinkClassName="page-link"

          nextClassName="page-item"
          nextLinkClassName="page-link"

          // //中間ページの省略表記関連
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          forcePage={globalCurrentPage}
        />
      </Pagination>
    </>
  );
}

const ReceiptList = () => {
  const token = cookie.load('token');
  //キャンペーンID
  // const { state: { campaignId, showName, showSpan } } = useLocation();
  // const { state: { theReceiptSearchInfo } } = useLocation();
  const {state} = useLocation();
  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get('campaignId');
  const showName = localStorage.getItem('showName');
  const showSpan = localStorage.getItem('showSpan');
  //レシートID
  const [receiptId, setReceiptId] = useState('');
  //ステータス
  const [status, setStatus] = useState('');
  //チェーン店名
  const [chainStore, setChainStore] = useState('');
  //却下理由
  const [ngReason, setNgReason] = useState('');
  //都道府県
  const [region, setRegion] = useState('');
  //合計金額最小
  const [totalAmountMin, setTotalAmountMin] = useState('');
  //合計金額最大
  const [totalAmountMax, setTotalAmountMax] = useState('');
  //対象商品数量最小
  const [tartgetItemCountMin, setTartgetItemCountMin] = useState('');
  //対象商品数量最大
  const [tartgetItemCountMax, setTartgetItemCountMax] = useState('');
  //対象商品合計金額最小
  const [targetItemTotalMin, setTargetItemTotalMin] = useState('');
  //対象商品合計金額最大
  const [targetItemTotalMax, setTargetItemTotalMax] = useState('');
  //更新日時期間
  const [updateTimeStartDate, setUpdateTimeStartDate] = useState('');
  const [updateTimeStartTime, setUpdateTimeStartTime] = useState('');
  const [updateTimeEndDate, setUpdateTimeEndDate] = useState('');
  const [updateTimeEndTime, setUpdateTimeEndTime] = useState('');
  //受付日時期間
  const [entryStartDate, setEntryStartDate] = useState('');
  const [entryStartTime, setEntryStartTime] = useState('');
  const [entryEndDate, setEntryEndDate] = useState('');
  const [entryEndTime, setEntryEndTime] = useState('');
  //購入日時期間
  const [salesTimeStartDate, setSalesTimeStartDate] = useState('');
  const [salesTimeStartTime, setSalesTimeStartTime] = useState('');
  const [salesTimeEndDate, setSalesTimeEndDate] = useState('');
  const [salesTimeEndTime, setSalesTimeEndTime] = useState('');
  //整数(+-を含む)
  const intRegExp = new RegExp("^[+-]?[0-9]+$");
  //正の整数(ゼロを含む)
  const numRegExp = new RegExp('^\\d+$');
  //検索条件格納
  const [receiptSearchInfo, setreceiptSearchInfo] = useState('');
  //検索結果
  const [receiptResult, setReceiptResult] = useState([]);
  //ルート
  // const navigate = useNavigate();
  const navigate = useNavigateParams();

  //ページ総数番号取得
  const [page, setPage] = useState('');
  const itemsPageNum = 20;


  //初期画面表示
  useEffect(() => {
    let searchInfo = {
      page: 1,
      pageSize: itemsPageNum,
      sort: "-salesTime"
    };
    if (state != null && state.theReceiptSearchInfo) {
      const theReceiptSearchInfo = state.theReceiptSearchInfo
      if (theReceiptSearchInfo !== undefined && theReceiptSearchInfo !== '' && theReceiptSearchInfo !== null) {
        if (theReceiptSearchInfo.page) {
          theReceiptSearchInfo.page = globalCurrentPage + 1
        }
        searchInfo = theReceiptSearchInfo
        if (theReceiptSearchInfo.receiptId) {
          setReceiptId(theReceiptSearchInfo.receiptId)
        }
        if (theReceiptSearchInfo.ngReason) {
          setNgReason(theReceiptSearchInfo.ngReason)
        }
        if (theReceiptSearchInfo.updateType) {
          setPart([theReceiptSearchInfo.updateType+''])
        }
        if (theReceiptSearchInfo.region) {
          setRegion(theReceiptSearchInfo.region)
        }
        if (theReceiptSearchInfo.chainStore) {
          setChainStore(theReceiptSearchInfo.chainStore)
        }
        if (theReceiptSearchInfo.totalAmountMin === 0 || theReceiptSearchInfo.totalAmountMin) {
          setTotalAmountMin(theReceiptSearchInfo.totalAmountMin)
        }
        if (theReceiptSearchInfo.totalAmountMax) {
          setTotalAmountMax(theReceiptSearchInfo.totalAmountMax)
        }
        if (theReceiptSearchInfo.status) {
          setSte([theReceiptSearchInfo.status])
        }

        if (theReceiptSearchInfo.updateTimeStart) {
          setUpdateTimeStartDate(moment(theReceiptSearchInfo.updateTimeStart).format('YYYY-MM-DD'))
          setUpdateTimeStartTime(moment(theReceiptSearchInfo.updateTimeStart).format('HH:mm'))
        }
        if (theReceiptSearchInfo.updateTimeEnd) {
          setUpdateTimeEndDate(moment(theReceiptSearchInfo.updateTimeEnd).format('YYYY-MM-DD'))
          setUpdateTimeEndTime(moment(theReceiptSearchInfo.updateTimeEnd).format('HH:mm'))
        }

        if (theReceiptSearchInfo.entryTimeStart) {
          setEntryStartDate(moment(theReceiptSearchInfo.entryTimeStart).format('YYYY-MM-DD'))
          setEntryStartTime(moment(theReceiptSearchInfo.entryTimeStart).format('HH:mm'))
        }
        if (theReceiptSearchInfo.entryTimeEnd) {
          setEntryEndDate(moment(theReceiptSearchInfo.entryTimeEnd).format('YYYY-MM-DD'))
          setEntryEndTime(moment(theReceiptSearchInfo.entryTimeEnd).format('HH:mm'))
        }

        if (theReceiptSearchInfo.salesTimeStart) {
          setSalesTimeStartDate(moment(theReceiptSearchInfo.salesTimeStart).format('YYYY-MM-DD'))
          setSalesTimeStartTime(moment(theReceiptSearchInfo.salesTimeStart).format('HH:mm'))
        }
        if (theReceiptSearchInfo.salesTimeEnd) {
          setSalesTimeEndDate(moment(theReceiptSearchInfo.salesTimeEnd).format('YYYY-MM-DD'))
          setSalesTimeEndTime(moment(theReceiptSearchInfo.salesTimeEnd).format('HH:mm'))
        }

        if (theReceiptSearchInfo.targetItemCountMin === 0 || theReceiptSearchInfo.targetItemCountMin) {
          setTartgetItemCountMin(theReceiptSearchInfo.targetItemCountMin)
        }
        if (theReceiptSearchInfo.targetItemCountMax) {
          setTartgetItemCountMax(theReceiptSearchInfo.targetItemCountMax)
        }

        if (theReceiptSearchInfo.targetItemTotalMin === 0 || theReceiptSearchInfo.targetItemTotalMin) {
          setTargetItemTotalMin(theReceiptSearchInfo.targetItemTotalMin)
        }
        if (theReceiptSearchInfo.targetItemTotalMax) {
          setTargetItemTotalMax(theReceiptSearchInfo.targetItemTotalMax)
        }
      }
    }
    const startResultInfo = async () => {
      const resultData = await API.getReceiptDataList(searchInfo, campaignId, token);
      if (resultData.message !== '') {
        const modal = Modal.error();
        modal.update({
          title: 'エラー',
          content: resultData.message,
          onOk() {
            modal.destroy();
          }
        })
      } else {
        //結果を出した状態でページに戻る
        const dataInfo = resultData.data.items;
        setReceiptResult([...dataInfo])

        const page = resultData.data.pagination.totalPages
        setreceiptSearchInfo(searchInfo);
        setPage(page);
      }
    }
    startResultInfo();
  }, [])

  //検索
  const handleProductSearch = async () => {
    //選択状態を空にする
    statusChange.length = 0;
    // allCheck.checked = false;
    pageChecked.length = 0;
    isSearched = true;
    var msg = '';
    if (totalAmountMin !== '' && totalAmountMin !== undefined && !numRegExp.test(totalAmountMin)) {
      msg = '合計金額は0以上の整数で入力してください';
    } else if (totalAmountMax !== '' && totalAmountMax !== undefined && !numRegExp.test(totalAmountMax)) {
      msg = '合計金額は0以上の整数で入力してください';
    } else if (totalAmountMax < totalAmountMin) {
      msg = '合計金額の値範囲が無効です';

    } else if (tartgetItemCountMin !== '' && !numRegExp.test(tartgetItemCountMin) && tartgetItemCountMin !== undefined) {
      msg = '対象商品数量は0以上の整数で入力してください';
    } else if (tartgetItemCountMax !== '' && !numRegExp.test(tartgetItemCountMax) && tartgetItemCountMax !== undefined) {
      msg = '対象商品数量は0以上の整数で入力してください';
    } else if (tartgetItemCountMax < tartgetItemCountMin) {
      msg = '対象商品数量の値範囲が無効です';

    } else if (targetItemTotalMin !== '' && !numRegExp.test(targetItemTotalMin) && targetItemTotalMin !== undefined) {
      msg = '対象商品合計金額は0以上の整数で入力してください';
    } else if (targetItemTotalMax !== '' && !numRegExp.test(targetItemTotalMax) && targetItemTotalMax !== undefined) {
      msg = '対象商品合計金額は0以上の整数で入力してください';
    } else if (targetItemTotalMax < targetItemTotalMin) {
      msg = '対象商品合計金額の値範囲が無効です';
    }

    else if (entryStartDate === "" && entryStartTime !== "") {
      msg = '受付日時を正しく入力してください';
    } else if (entryStartDate !== "" && entryStartTime === "") {
      msg = '受付日時を正しく入力してください';
    } else if (entryEndDate === "" && entryEndTime !== "") {
      msg = '受付日時を正しく入力してください';
    } else if (entryEndDate !== "" && entryEndTime === "") {
      msg = '受付日時を正しく入力してください';
    } else if (entryStartDate > entryEndDate && entryStartDate !== "" && entryEndDate !== "") {
      msg = '受付日時の時間範囲が無効です';
    } else if (entryStartTime > entryEndTime && entryStartDate === entryEndDate) {
      msg = '受付日時の時間範囲が無効です';
    }
    else if (updateTimeStartDate === "" && updateTimeStartTime !== "") {
      msg = '更新日時を正しく入力してください';
    } else if (updateTimeStartDate !== "" && updateTimeStartTime === "") {
      msg = '更新日時を正しく入力してください';
    } else if (updateTimeEndDate === "" && updateTimeEndTime !== "") {
      msg = '更新日時を正しく入力してください';
    } else if (updateTimeEndDate !== "" && updateTimeEndTime === "") {
      msg = '更新日時を正しく入力してください';
    } else if (updateTimeStartDate > updateTimeEndDate && updateTimeStartDate !== "" && updateTimeEndDate !== "") {
      msg = '更新日時の時間範囲が無効です';
    } else if (updateTimeStartTime > updateTimeEndTime && updateTimeStartDate === updateTimeEndDate) {
      msg = '更新日時の時間範囲が無効です';
    }

    else if (salesTimeStartDate === "" && salesTimeStartTime !== "") {
      msg = '購入開始日時を正しく入力してください';
    } else if (salesTimeStartDate !== "" && salesTimeStartTime === "") {
      msg = '購入開始日時を正しく入力してください';
    } else if (salesTimeEndDate === "" && salesTimeEndTime !== "") {
      msg = '購入開始日時を正しく入力してください';
    } else if (salesTimeEndDate !== "" && salesTimeEndTime === "") {
      msg = '購入開始日時を正しく入力してください';
    }
    else if (salesTimeStartDate > salesTimeEndDate && salesTimeStartDate !== "" && salesTimeEndDate !== "") {
      msg = '購入日時の時間範囲が無効です';
    } else if (salesTimeStartTime > salesTimeEndTime && salesTimeStartDate === salesTimeEndDate) {
      msg = '購入日時の時間範囲が無効です';
    }

    if (msg !== '') {
      const modal = Modal.error();
      modal.update({
        title: 'エラー',
        content: msg,
        onOk() {
          modal.destroy();
        }
      })
    } else {
      //入力された値
      let searchInfo = {
        page: 1,
        pageSize: itemsPageNum,
        sort: "-salesTime"
      };
      if (entryStartDate !== "" && entryStartTime !== "") {
        searchInfo['entryTimeStart'] = dateStr2TimeTamp(getStartDate(entryStartDate, entryStartTime))
      }
      if (entryEndDate !== "" && entryEndTime !== "") {
        searchInfo['entryTimeEnd'] = dateStr2TimeTamp(getEndDate(entryEndDate, entryEndTime))
      }

      if (updateTimeStartDate !== "" && updateTimeStartTime !== "") {
        searchInfo['updateTimeStart'] = dateStr2TimeTamp(getStartDate(updateTimeStartDate, updateTimeStartTime))
      }
      if (updateTimeEndDate !== "" && updateTimeEndTime !== "") {
        searchInfo['updateTimeEnd'] = dateStr2TimeTamp(getEndDate(updateTimeEndDate, updateTimeEndTime))
      }
      if (salesTimeStartDate !== "" && salesTimeStartTime !== "") {
        searchInfo['salesTimeStart'] = dateStr2TimeTamp(getStartDate(salesTimeStartDate, salesTimeStartTime))
      }
      if (salesTimeEndDate !== "" && salesTimeEndTime !== "") {
        searchInfo['salesTimeEnd'] = dateStr2TimeTamp(getEndDate(salesTimeEndDate, salesTimeEndTime))
      }
      if (receiptId !== '') {
        searchInfo['receiptId'] = receiptId;
      }
      if (status !== '') {
        searchInfo['status'] = status;
      }
      if (ngReason !== '') {
        searchInfo['ngReason'] = ngReason;
      }
      if (region !== '') {
        searchInfo['region'] = region;
      }

      if (part.length !== 0) {
        searchInfo['updateType'] = parseInt(part);
      }
      if (ste.length !== 0) {
        searchInfo['status'] = String(ste);
      }
      if (chainStore !== '') {
        searchInfo['chainStore'] = chainStore;
      }
      if (totalAmountMin !== '' && totalAmountMin !== undefined) {
        searchInfo['totalAmountMin'] = parseInt(totalAmountMin);
      }
      if (totalAmountMax !== '' && totalAmountMax !== undefined) {
        searchInfo['totalAmountMax'] = parseInt(totalAmountMax);
      }
      if (tartgetItemCountMin !== '' && tartgetItemCountMin !== undefined) {
        searchInfo['targetItemCountMin'] = parseInt(tartgetItemCountMin);
      }
      if (tartgetItemCountMax !== '' && tartgetItemCountMax !== undefined) {
        searchInfo['targetItemCountMax'] = parseInt(tartgetItemCountMax);
      }
      if (targetItemTotalMin !== '' && targetItemTotalMin !== undefined) {
        searchInfo['targetItemTotalMin'] = parseInt(targetItemTotalMin);
      }
      if (targetItemTotalMax !== '' && targetItemTotalMax !== undefined) {
        searchInfo['targetItemTotalMax'] = parseInt(targetItemTotalMax);
      }

      //入力された値でDBを検索する
      const resultData = await API.getReceiptDataList(searchInfo, campaignId, token);
      if (resultData.message !== '') {
        const modal = Modal.error();
        modal.update({
          title: 'エラー',
          content: resultData.message,
          onOk() {
            modal.destroy();
          }
        })
      } else {
        //結果を出した状態でページに戻る
        const dataInfo = resultData.data.items;
        const page = resultData.data.pagination.totalPages
        setReceiptResult([...dataInfo]);
        setreceiptSearchInfo(searchInfo);
        setPage(page);

        // navigate('/campaigns/receiptslist', { state: { campaignId, showName, showSpan, receiptSearchInfo, receiptResult, page } });
        navigate("/campaigns/receiptslist", `campaignId=${campaignId}`, { receiptSearchInfo: receiptSearchInfo, receiptResult: receiptResult, page: page });

      }

    }
  };

  //条件クリア
  const handleProductClear = () => {
    setReceiptId('');
    setStatus('');
    setSte([])
    setPart([])
    setChainStore('');
    setTotalAmountMin('');
    setTotalAmountMax('');
    setTartgetItemCountMin('');
    setTartgetItemCountMax('');
    setTargetItemTotalMin('');
    setTargetItemTotalMax('');
    setUpdateTimeStartDate('');
    setUpdateTimeStartTime('');
    setUpdateTimeEndDate('');
    setUpdateTimeEndTime('');
    setEntryStartDate('');
    setEntryStartTime('');
    setEntryEndDate('');
    setEntryEndTime('');
    setSalesTimeStartDate('');
    setSalesTimeStartTime('');
    setSalesTimeEndDate('');
    setSalesTimeEndTime('');
    setNgReason('');
    setRegion('');
  };

  //開始日時（YYYY-MM-DD HH:mm:ss）
  const getStartDate = (dateStr, timeStr) => {
    return moment(dateStr).format('YYYY-MM-DD') + ' ' + moment(timeStr, 'HH:mm').format('HH:mm:00');
  }
  //終了日時（YYYY-MM-DD HH:mm:ss）
  const getEndDate = (dateStr, timeStr) => {
    return moment(dateStr).format('YYYY-MM-DD') + ' ' + moment(timeStr, 'HH:mm').format('HH:mm:59');
  }


  //レシートIDコードリンク
  const handlereRceiptCheckClick = (x) => {
    const trackingId = x.trackingId;
    // navigate('/campaigns/receiptsinfo', { state: { campaignId, showName, showSpan, trackingId } });
    navigate("/campaigns/receiptsinfo", `campaignId=${campaignId}`, { trackingId: trackingId, receiptSearchInfo: receiptSearchInfo });

  };
  //単一のみの選択
  const [statusChange, setStatusChange] = useState([]);
  const handleStatusChange = e => {
    if (statusChange.includes(e.target.value)) {
      setStatusChange(statusChange.filter(item => item !== e.target.value));
    } else {
      setStatusChange([...statusChange, e.target.value]);
    }
  };

  //全選択判定
  const okbutton = document.getElementById("okbutton");
  const ngbutton = document.getElementById("ngbutton");
  const [pageChecked, setPageChecked] = useState([]);


  const handleStatusAllCheck = () => {
    let tempStatusChange = [...statusChange];
    let unCheckedArr = [];
    receiptResult.forEach((item) => {
      if (pageChecked.includes(globalCurrentPage)) {
        unCheckedArr = [...unCheckedArr, item.trackingId];
      } else {
        tempStatusChange = [...tempStatusChange, item.trackingId];
      }
    }
    )
    if (pageChecked.includes(globalCurrentPage)) {
      setPageChecked(pageChecked.filter(item => item !== globalCurrentPage));
    } else {
      setPageChecked([...pageChecked, globalCurrentPage]);
    }
    if (tempStatusChange.length === 0) {
      okbutton.disabled = true;
      ngbutton.disabled = true;
      setStatusChange([]);
    } else {
      if (unCheckedArr.length > 0) {
        setStatusChange(tempStatusChange.filter(item => !unCheckedArr.includes(item)));
      } else {
        setStatusChange([...tempStatusChange]);
      }
    }

  }

  //ステータスOKの変更
  const handleStatusOKChange = async () => {
    const statusItems = {
      "receiptIds": statusChange,
      "status": "OK"
    };
    const statusData = await API.changeStatus(statusItems, campaignId, token);
    if (statusData.message !== '') {
      const modal = Modal.error();
      modal.update({
        title: 'エラー',
        content: statusData.message,
        onOk() {
          modal.destroy();
        }
      })
      //もう一度検索してる
    } else {
      //ステータスの配列を空にする
      statusChange.length = 0;
      // allCheck.checked = false;
      pageChecked.length = 0;

      //入力された値でDBを検索する
      let searchInfo = receiptSearchInfo;
      searchInfo.page = globalCurrentPage + 1
      const resultData = await API.getReceiptDataList(searchInfo, campaignId, token);
      if (resultData.message !== '') {
        const modal = Modal.error();
        modal.update({
          title: 'エラー',
          content: resultData.message,
          onOk() {
            modal.destroy();
          }
        })
      } else {
        //結果を出した状態でページに戻る
        const dataInfo = resultData.data.items;
        setReceiptResult([...dataInfo]);
        setreceiptSearchInfo(searchInfo);
        const page = resultData.data.pagination.totalPages
        setPage(page);
        // navigate('/campaigns/receiptslist', { state: { campaignId, showName, showSpan, receiptSearchInfo, receiptResult, page } });
        navigate("/campaigns/receiptslist", `campaignId=${campaignId}`, { receiptSearchInfo: receiptSearchInfo, receiptResult: receiptResult, page: page });

      }
    }
  }

  //ステータスNGの変更
  const handleStatusNGChange = async () => {

    const statusItems = {
      "receiptIds": statusChange,
      "status": "NG"
    };
    const statusData = await API.changeStatus(statusItems, campaignId, token);
    if (statusData.message !== '') {
      const modal = Modal.error();
      modal.update({
        title: 'エラー',
        content: statusData.message,
        onOk() {
          modal.destroy();
        }
      })
      //もう一度検索してる
    } else {
      //ステータスの配列を空にする
      statusChange.length = 0;
      pageChecked.length = 0;
      // allCheck.checked = false;
      //入力された値でDBを検索する
      let searchInfo = receiptSearchInfo;
      searchInfo.page = globalCurrentPage + 1
      const resultData = await API.getReceiptDataList(searchInfo, campaignId, token);
      if (resultData.message !== '') {
        const modal = Modal.error();
        modal.update({
          title: 'エラー',
          content: resultData.message,
          onOk() {
            modal.destroy();
          }
        })
      } else {
        //結果を出した状態でページに戻る
        const dataInfo = resultData.data.items;
        setReceiptResult([...dataInfo]);
        setreceiptSearchInfo(searchInfo);
        const page = resultData.data.pagination.totalPages
        setPage(page);
        // navigate('/campaigns/receiptslist', { state: { campaignId, showName, showSpan, receiptSearchInfo, receiptResult, page } });
        navigate("/campaigns/receiptslist", `campaignId=${campaignId}`, { receiptSearchInfo: receiptSearchInfo, receiptResult: receiptResult, page: page });

      }
    }
  }

  const handlePageChange = async () => {
    let searchInfo = {...receiptSearchInfo};
    searchInfo["page"] = globalCurrentPage + 1;
    searchInfo["pageSize"] = itemsPageNum;
    searchInfo["sort"] = "-salesTime"

    //入力された値でDBを検索する
    const resultData = await API.getReceiptDataList(searchInfo, campaignId, token);
    if (resultData.message !== '') {
      const modal = Modal.error();
      modal.update({
        title: 'エラー',
        content: resultData.message,
        onOk() {
          modal.destroy();
        }
      })
    } else {
      //結果を出した状態でページに戻る
      const dataInfo = resultData.data.items;
      setReceiptResult([...dataInfo]);

    }
  }

  //更新区分のチェック
  const [part, setPart] = useState('');
  const handlePartChange = e => {
    if (part.includes(e.target.value)) {
      setPart(part.filter(item => item !== e.target.value));
    } else {
      setPart([e.target.value]);
    }
  };

  //ステータスのチェック
  const [ste, setSte] = useState('');
  const handleSteChange = e => {
    if (ste.includes(e.target.value)) {
      setSte(ste.filter(item => item !== e.target.value));
    } else {
      setSte([e.target.value]);
    }
  };


  // テキスト2タイムスタンプ
  const dateStr2TimeTamp = (dateStr) => {
    dateStr = dateStr.substring(0, 19);
    dateStr = dateStr.replace(/-/g, '/');
    var timeTamp = new Date(dateStr).getTime();
    return timeTamp;
  }

  return (
    <Wrapper>
      <Header showFlag={'page3'} showName={showName} showSpan={showSpan} showId={campaignId} />
      <main>
        <Serch>
          <Title>レシート検索</Title>
          <SerchTable>
            <tbody>
              <tr>
                <ContentTh>レシートID</ContentTh>
                <SerchTd><ContentText type="text" maxLength='50' value={receiptId} onChange={(e) => setReceiptId(e.target.value)} /></SerchTd>
                <ContentTh>ステータス:</ContentTh>
                <SerchTd>
                  <Chceckdiv>
                    <input
                      name="status"
                      type="checkbox"
                      value="OK"
                      onChange={handleSteChange}
                      checked={ste.includes("OK")}
                      id="statusOk"
                    />
                    <label htmlFor="statusOk">OK</label>
                  </Chceckdiv>
                  <Chceckdiv>
                    <input
                      name="status"
                      type="checkbox"
                      value="NG"
                      onChange={handleSteChange}
                      checked={ste.includes("NG")}
                      id="statusNg"
                    />
                    <label htmlFor="statusNg">NG</label>
                  </Chceckdiv>
                  <Chceckdiv>
                    <input
                      name="status"
                      type="checkbox"
                      value="ERROR"
                      onChange={handleSteChange}
                      checked={ste.includes("ERROR")}
                      id="statusError"
                    />
                    <label htmlFor="statusError">ERROR</label>
                  </Chceckdiv>
                </SerchTd>
              </tr>
              <tr>
                <ContentTh>却下理由:</ContentTh>
                <td>
                  <select value={ngReason} onChange={(e) => setNgReason(e.target.value)} >
                    <option value=""></option>
                    <option value="EXCEEDED_ENTRY_LIMIT">EXCEEDED_ENTRY_LIMIT</option>
                    <option value="INVALID_SALES_TIME">INVALID_SALES_TIME</option>
                    <option value="RECEIPT_ALREADY_ENTERED">RECEIPT_ALREADY_ENTERED</option>
                    <option value="SALES_TIME_NOT_FOUND">SALES_TIME_NOT_FOUND</option>
                    <option value="TARGET_ITEM_NOT_FOUND">TARGET_ITEM_NOT_FOUND</option>
                    <option value="MANUAL_BATCH_CHANGE">MANUAL_BATCH_CHANGE</option>
                  </select>
                </td>
                <ContentTh>更新日時:</ContentTh>
                <td>
                  <input type="date" value={updateTimeStartDate} onChange={(e) => setUpdateTimeStartDate(e.target.value)} />
                  <ContentTime type="time" value={updateTimeStartTime} onChange={(e) => setUpdateTimeStartTime(e.target.value)} />
                  ~
                  <input type="date" value={updateTimeEndDate} onChange={(e) => setUpdateTimeEndDate(e.target.value)} />
                  <ContentTime type="time" value={updateTimeEndTime} onChange={(e) => setUpdateTimeEndTime(e.target.value)} />
                </td>

              </tr>
              <tr>
                <ContentTh>更新区分:</ContentTh>
                <td>
                  <Chceckdiv>
                    <input
                      name="part"
                      type="checkbox"
                      value="1"
                      onChange={handlePartChange}
                      checked={part.includes("1")}
                      id="part1"
                    />
                    <label htmlFor="part1">一次判定</label>
                  </Chceckdiv>
                  <Chceckdiv>
                    <input
                      name="part"
                      type="checkbox"
                      value="2"
                      onChange={handlePartChange}
                      checked={part.includes("2")}
                      id="part2"
                    />
                    <label htmlFor="part2">総合判定</label>
                  </Chceckdiv>
                  <Chceckdiv>
                    <input
                      name="part"
                      type="checkbox"
                      value="3"
                      onChange={handlePartChange}
                      checked={part.includes("3")}
                      id="part3"
                    />
                    <label htmlFor="part3">目視判定</label>
                  </Chceckdiv>
                </td>
                <ContentTh>受付日時:</ContentTh>
                <td>
                  <input type="date" value={entryStartDate} onChange={(e) => setEntryStartDate(e.target.value)} />
                  <ContentTime type="time" value={entryStartTime} onChange={(e) => setEntryStartTime(e.target.value)} />
                  ~
                  <input type="date" value={entryEndDate} onChange={(e) => setEntryEndDate(e.target.value)} />
                  <ContentTime type="time" value={entryEndTime} onChange={(e) => setEntryEndTime(e.target.value)} />
                </td>

              </tr>
              <tr>
                <ContentTh>地域:</ContentTh>
                <td>
                  <select value={region} onChange={(e) => setRegion(e.target.value)} >
                    <option value=""></option>
                    <option value="北海道">北海道</option>
                    <option value="青森県">青森県</option>
                    <option value="岩手県">岩手県</option>
                    <option value="宮城県">宮城県</option>
                    <option value="秋田県">秋田県</option>
                    <option value="山形県">山形県</option>
                    <option value="福島県">福島県</option>
                    <option value="茨城県">茨城県</option>
                    <option value="栃木県">栃木県</option>
                    <option value="群馬県">群馬県</option>
                    <option value="埼玉県">埼玉県</option>
                    <option value="千葉県">千葉県</option>
                    <option value="東京都">東京都</option>
                    <option value="神奈川県">神奈川県</option>
                    <option value="新潟県">新潟県</option>
                    <option value="富山県">富山県</option>
                    <option value="石川県">石川県</option>
                    <option value="福井県">福井県</option>
                    <option value="山梨県">山梨県</option>
                    <option value="長野県">長野県</option>
                    <option value="岐阜県">岐阜県</option>
                    <option value="静岡県">静岡県</option>
                    <option value="愛知県">愛知県</option>
                    <option value="三重県">三重県</option>
                    <option value="滋賀県">滋賀県</option>
                    <option value="	京都府">京都府</option>
                    <option value="大阪府">大阪府</option>
                    <option value="兵庫県">兵庫県</option>
                    <option value="奈良県">奈良県</option>
                    <option value="和歌山県">和歌山県</option>
                    <option value="鳥取県">	鳥取県</option>
                    <option value="島根県">島根県</option>
                    <option value="岡山県">岡山県</option>
                    <option value="広島県">広島県</option>
                    <option value="山口県">山口県</option>
                    <option value="徳島県">徳島県</option>
                    <option value="香川県">香川県</option>
                    <option value="愛媛県">愛媛県</option>
                    <option value="高知県">高知県</option>
                    <option value="福岡県">福岡県</option>
                    <option value="佐賀県">佐賀県</option>
                    <option value="長崎県">長崎県</option>
                    <option value="熊本県">熊本県</option>
                    <option value="大分県">大分県</option>
                    <option value="宮崎県">宮崎県</option>
                    <option value="鹿児島県">鹿児島県</option>
                    <option value="沖縄県">沖縄県</option>
                  </select>
                </td>
                <ContentTh>購入日時:</ContentTh>
                <td>
                  <input type="date" value={salesTimeStartDate} onChange={(e) => setSalesTimeStartDate(e.target.value)} />
                  <ContentTime type="time" value={salesTimeStartTime} onChange={(e) => setSalesTimeStartTime(e.target.value)} />
                  ~
                  <input type="date" value={salesTimeEndDate} onChange={(e) => setSalesTimeEndDate(e.target.value)} />
                  <ContentTime type="time" value={salesTimeEndTime} onChange={(e) => setSalesTimeEndTime(e.target.value)} />
                </td>
              </tr>
              <tr>
                <ContentTh>チェーン店名:</ContentTh>
                <SerchTd><ContentText type="text" maxLength='50' value={chainStore} onChange={(e) => setChainStore(e.target.value)} /></SerchTd>
                <ContentTh>対象商品数量</ContentTh>
                <SerchUnitTd>
                  <ContentNum type="number" maxLength='10' value={tartgetItemCountMin} onChange={(e) => setTartgetItemCountMin(e.target.value)} />
                  ~
                  <ContentNum type="number" maxLength='10' value={tartgetItemCountMax} onChange={(e) => setTartgetItemCountMax(e.target.value)} />
                </SerchUnitTd>
              </tr>
              <tr>
                <ContentTh>合計金額</ContentTh>
                <SerchUnitTd>
                  <ContentNum type="number" maxLength='10' value={totalAmountMin} onChange={(e) => setTotalAmountMin(e.target.value)} />
                  ~
                  <ContentNum type="number" maxLength='10' value={totalAmountMax} onChange={(e) => setTotalAmountMax(e.target.value)} />
                </SerchUnitTd>
                <ContentTh>対象商品合計金額</ContentTh>
                <SerchUnitTd>
                  <ContentNum type="number" maxLength='10' value={targetItemTotalMin} onChange={(e) => setTargetItemTotalMin(e.target.value)} />
                  ~
                  <ContentNum type="number" maxLength='10' value={targetItemTotalMax} onChange={(e) => setTargetItemTotalMax(e.target.value)} />
                </SerchUnitTd>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <ButtonClear onClick={handleProductClear}>条件クリア</ButtonClear>
                  <ButtonSearch onClick={handleProductSearch}>検索</ButtonSearch>
                </td>
              </tr>
            </tbody>
          </SerchTable>
        </Serch>
        <ButtonCell>
          <ButtonSave onClick={handleStatusOKChange} disabled={!statusChange.length} id="okbutton">一括OK</ButtonSave>
          <ButtonSave onClick={handleStatusNGChange} disabled={!statusChange.length} id="ngbutton">一括NG</ButtonSave>
        </ButtonCell>
        <Content>
          <PaginatedItems itemsPerPage={itemsPageNum} items={receiptResult} handleStatusAllCheck={handleStatusAllCheck} 
          handleStatusChange={handleStatusChange} handlereRceiptCheckClick={handlereRceiptCheckClick} 
          statusChange={statusChange} pageChecked={pageChecked} page={Number(page)} receiptSearchInfo = {receiptSearchInfo} campaignId = {campaignId} handlePageChange = {handlePageChange}/>
        </Content>
      </main >
      <Footer />
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous"></link>
    </Wrapper >

  )
}
export default ReceiptList;