import axios from 'axios';
import {
  LOGIN_URL,
  GET_CAMPAIGN_URL,
  GET_MAP_JSON_URL
} from './Config';
import { get, post, put, deleteM } from './Request';


const API = {
  //ログイン
  getLoginInfo: async (companyId, userId, password) => {
    const bodyData = {
      companyId: companyId,
      userId: userId,
      password: password
    };
    var resultData = {
      message: '',
      token: '',
      data: []
    };
    await axios({
      method: 'POST',
      url: LOGIN_URL,
      data: JSON.stringify(bodyData),
      headers: {
        'Content-Type': 'application/json;chartset=utf-8'
      },
      responseType: 'json'
    }).then(res => {
      if (res.status === 200) {
        resultData.data = res.data
        resultData.token = res.headers.token;
      } else {
        resultData.message = res.message;
      }
    })
      .catch(error => {
        if (error.response !== undefined && (error.response.status === 400 || error.response.status === 401)) {
          resultData.message = error.response.data.message;
      } else {
        resultData.message = error.message;
      }
    })
    return resultData;
  },
  //キャンペーン一覧取得
  getCampaignDataList: async (token) => {
    var resultData = {
      message: '',
      data: []
    };
    await get(GET_CAMPAIGN_URL, {}, { responseType: 'json'})
    .then(res => {      
        if (res.status === 200) {
          resultData.data = res.data;
        } else {
        resultData.message = res.message;
      }
    }).catch(error => {
        if (error.response !== undefined && (error.response.status === 400 || error.response.status === 401)) {
          resultData.message = error.response.data.message;
        } else if(error.response === undefined || (error.response !== undefined && error.response.status !== 403)) {
          resultData.message = error.message;
        }
      })

    return resultData
  },
  //キャンペーン情報追加・更新
  addCampaignInfo: async (saveInfo, campaignId, token) => {
    var resultData = {
      message: '',
      data: []
    };

    await put(GET_CAMPAIGN_URL + '/' + campaignId, JSON.stringify(saveInfo), { responseType: 'json'})
    .then(res => {
      if (res.status === 200) {
        resultData.data = res.data;
      } else {
        resultData.message = res.message;
      }
    })
      .catch(error => {
        if (error.response !== undefined && (error.response.status === 400 || error.response.status === 401)) {
          resultData.message = error.response.data.message;
        } else if(error.response === undefined || (error.response !== undefined && error.response.status !== 403)) {
          resultData.message = error.message;
        }
      })
    return resultData;
  },
  //キャンペーン情報取得
  getCampaignInfo: async (campaignId, token) => {
    var resultData = {
      message: '',
      data: []
    }

    await get(GET_CAMPAIGN_URL + '/' + campaignId, {}, { responseType: 'json'})
    .then(res => {
      
      if (res.status === 200) {
        resultData.data = res.data;
      } else {
        resultData.message = res.message;
      }
    })
      .catch(error => {
        alert(error.response.status);
        if (error.response !== undefined && (error.response.status === 400 || error.response.status === 401)) {
          resultData.message = error.response.data.message;
        } else if(error.response === undefined || (error.response !== undefined && error.response.status !== 403)) {
          resultData.message = error.message;
        }
      })
    return resultData;
  },
  //キャンペーン削除
  delCampaignInfo: async (campaignId, token) => {
    var resultData = {
      message: '',
      data: []
    };

    await deleteM(GET_CAMPAIGN_URL + '/' + campaignId, {}, { responseType: 'json'})
    .then(res => {
      if (res.status === 200) {
        resultData.data = res.data;
      } else {
        resultData.message = res.message;
      }
    })
      .catch(error => {
        if (error.response !== undefined && (error.response.status === 400 || error.response.status === 401)) {
          resultData.message = error.response.data.message;
        } else if(error.response === undefined || (error.response !== undefined && error.response.status !== 403)) {
          resultData.message = error.message;
        }
      })
    return resultData
  },

  //商品一覧検索
  getProductDataList: async (searchInfo, campaignId, token) => {
    var resultData = {
      message: '',
      data: []
    }

    await get(GET_CAMPAIGN_URL + '/' + campaignId + "/targetItems", searchInfo, { responseType: 'json'})
    .then(res => {
      if (res.status === 200) {
        resultData.data = res.data
      } else {
        resultData.message = res.message
      }
    })
      .catch(error => {
        if (error.response !== undefined && (error.response.status === 400 || error.response.status === 401)) {
          resultData.message = error.response.data.message
        } else if(error.response === undefined || (error.response !== undefined && error.response.status !== 403)) {
          resultData.message = error.message
        }
      })
    return resultData
  },
  //商品情報削除
  delProductData: async (deletedItems, campaignId, token) => {
    var resultData = {
      message: '',
      data: []
    };

    await post(GET_CAMPAIGN_URL + '/' + campaignId + "/targetItems", JSON.stringify(deletedItems), { responseType: 'json'})
    .then(res => {
      if (res.status === 200) {
        resultData.data = res.data;
      } else {
        resultData.message = res.message;
      }
    })
      .catch(error => {
        if (error.response !== undefined && (error.response.status === 400 || error.response.status === 401)) {
          resultData.message = error.response.data.message;
        } else if(error.response === undefined || (error.response !== undefined && error.response.status !== 403)) {
          resultData.message = error.message;
        }
      })
    return resultData
  },
  //商品情報更新
  updateProductData: async (updateInfo, campaignId, janCode, token) => {
    var resultData = {
      message: '',
      data: []
    }

    await put(GET_CAMPAIGN_URL + '/' + campaignId + "/targetItems/" + janCode, JSON.stringify(updateInfo), { responseType: 'json'})
    .then(res => {
      if (res.status === 200) {
        resultData.data = res.data
      } else {
        resultData.message = res.message
      }
    })
      .catch(error => {
        if (error.response !== undefined && (error.response.status === 400 || error.response.status === 401)) {
          resultData.message = error.response.data.message
        } else if(error.response === undefined || (error.response !== undefined && error.response.status !== 403)) {
          resultData.message = error.message
        }
      })
    return resultData
  },
//商品情報追加
  addProductData: async (addInfo, campaignId, janCode, token) => {
    var resultData = {
      message: '',
      data: []
    }
    await post(GET_CAMPAIGN_URL + '/' + campaignId + "/targetItems/" + janCode, JSON.stringify(addInfo), { responseType: 'json' })
      .then(res => {
        if (res.status === 200) {
          resultData.data = res.data
        } else {
          resultData.message = res.message
        }
      })
      .catch(error => {
        if (error.response !== undefined && (error.response.status === 400 || error.response.status === 401 || error.response.status === 409 )) {
          resultData.message = error.response.data.message
        } else if (error.response === undefined || (error.response !== undefined && error.response.status !== 403)) {
          resultData.message = error.message
        }
      })
    return resultData
  },
  //対象商品一覧取得
  getproductWordsData: async (campaignId, token) => {
    var resultData = {
      message: '',
      data: []
    };
    var pathUrl = GET_CAMPAIGN_URL + '/' + campaignId + '/targetItems';

    await get(pathUrl, {}, { responseType: 'json'})
    .then(res => {
      if (res.status === 200) {
        resultData.data = res.data;
      } else {
        resultData.message = res.message;
      }
    })
      .catch(error => {
        if (error.response !== undefined && (error.response.status === 400 || error.response.status === 401)) {
          resultData.message = error.response.data.message;
        } else if(error.response === undefined || (error.response !== undefined && error.response.status !== 403)) {
          resultData.message = error.message;
        }
      })
    return resultData;
  },
  //統計データ取得
  getStatisticsData: async (campaignId, token, type, span, item, attributionId, region, periodStart, periodEnd, attrMap) => {
    var resultData = {
      message: '',
      data: []
    };
    var pathUrl = GET_CAMPAIGN_URL + '/' + campaignId + '/statistics?type=' + type;
    if (span !== undefined && span !== '') {
      pathUrl = pathUrl + '&span=' + span;
    }
    if (item !== undefined && item !== '') {
      pathUrl = pathUrl + '&item=' + item;
    }
    if (attributionId !== undefined && attributionId !== '') {
      pathUrl = pathUrl + '&attributionId=' + attributionId;
    }
    if (region !== undefined && region !== '') {
      pathUrl = pathUrl + '&region=' + region;
    }
    if (periodStart !== undefined) {
      pathUrl = pathUrl + '&periodStart=' + periodStart;
    }
    if (periodEnd !== undefined) {
      pathUrl = pathUrl + '&periodEnd=' + periodEnd;
    }
    if (attrMap !== undefined && Object.keys(attrMap).length > 0) {
      for (var key in attrMap) {
        if (attrMap[key] !== undefined && attrMap[key] !== '') {
          pathUrl = pathUrl + '&' + key + '=' + attrMap[key];
        }
      }
    }

    await get(pathUrl, {}, { responseType: 'json'})
    .then(res => {
      if (res.status === 200) {
        resultData.data = res.data;
      } else {
        resultData.message = res.message;
      }
    })
      .catch(error => {
        if (error.response !== undefined && (error.response.status === 400 || error.response.status === 401)) {
          resultData.message = error.response.data.message;
        } else if(error.response === undefined || (error.response !== undefined && error.response.status !== 403)) {
          resultData.message = error.message;
        }
      })
    return resultData;
  },
  //地域
  getMapJson: async () => {
    var resultData = {
      message: '',
      data: []
    };
    await axios({
      method: 'GET',
      url: GET_MAP_JSON_URL,
      headers: {
        'Content-Type': 'application/json;chartset=utf-8'
      },
      responseType: 'json'
    }).then(res => {
      if (res.status === 200) {
        var data = res.data;
        for (var i = 0; i < data.features.length; i++) {
          var newName = data.features[i].properties.name_ja;
          data.features[i].properties.name = newName;
        }
        resultData.data = data;
      } else {
        resultData.message = res.message;
      }
    })
      .catch(error => {
        resultData.message = error.message;
      })
    return resultData;
  },
  //レシート商品一覧検索
  getReceiptDataList: async (searchInfo, campaignId, token) => {
    var resultData = {
      message: '',
      data: []
    }

    await get(GET_CAMPAIGN_URL + '/' + campaignId + '/receipts', searchInfo, { responseType: 'json'})
    .then(res => {
      if (res.status === 200) {
        resultData.data = res.data
      } else {
        resultData.message = res.message
      }
    })
      .catch(error => {
        if (error.response !== undefined && (error.response.status === 400 || error.response.status === 401)) {
          resultData.message = error.response.data.message
        } else if(error.response === undefined || (error.response !== undefined && error.response.status !== 403)) {
          resultData.message = error.message
        }
      })
    return resultData
  },
  //レシート詳細取得
  getReceiptInfo: async (campaignId, receiptId, token) => {
    var resultData = {
      message: '',
      data: [],
    }
    // await axios({
    //   method: 'GET',
    //   url: GET_CAMPAIGN_URL + '/' + campaignId + '/receipts/' + receiptId,
    //   headers: {
    //     'Content-Type': 'application/json;chartset=utf-8',
    //     'Authorization': token
    //   },
    //   responseType: 'json'
    // })
    await get(GET_CAMPAIGN_URL + '/' + campaignId + '/receipts/' + receiptId, { responseType: 'json'})
    .then(res => {
      if (res.status === 200) {
        resultData.data = res.data
      } else {
        resultData.message = res.message
      }
    })
      .catch(error => {
        if (error.response !== undefined && (error.response.status === 400 || error.response.status === 401)) {
          resultData.message = error.response.data.message
        } else {
          resultData.message = error.message
        }
      })
    return resultData
  },
  // レシート詳細Image
  getReceiptImage: async (campaignId, receiptId, token) => {
    var resultData = {
      message: '',
      data: [],
      status: 0
    }
    await axios.get(GET_CAMPAIGN_URL + '/' + campaignId + '/receipts/' + receiptId + '/images',
      {
        headers: {
          'Content-Type': 'application/json;chartset=utf-8',
          'Authorization': token
        },
        responseType: 'json'
      })
      .then(res => {
        if (res.status === 200) {
          resultData.data = res.data
          resultData.status = 200
        } else {
          resultData.message = res.message
        }
      }).catch(error => {
        if (error.response !== undefined && (error.response.status === 400 || error.response.status === 401)) {
          resultData.message = error.response.data.message
        } else {
          resultData.message = error.message
        }
      })
    return resultData
  },
  //レシート詳細更新
  updateReceiptInfo: async (updateInfo, campaignId, receiptId, token) => {
    var resultData = {
      message: '',
      data: [],
    };
    // await axios({
    //   method: 'PUT',
    //   url: GET_CAMPAIGN_URL + '/' + campaignId + '/receipts/' + receiptId,
    //   data: JSON.stringify(updateInfo),
    //   headers: {
    //     'Content-Type': 'application/json;chartset=utf-8',
    //     'Authorization': token
    //   },
    //   responseType: 'json'
    // })
    await put(GET_CAMPAIGN_URL + '/' + campaignId + '/receipts/' + receiptId, JSON.stringify(updateInfo), { responseType: 'json'})
    .then(res => {
      if (res.status === 200) {
        resultData.data = res.data;
      } else {
        resultData.message = res.message;
      }
    })
      .catch(error => {
        if (error.response !== undefined && (error.response.status === 400 || error.response.status === 401)) {
          resultData.message = error.response.data.message;
        } else {
          resultData.message = error.message;
        }
      })
    return resultData;
  },
  //ステータス一括変更
  changeStatus: async (statusItems, campaignId, token) => {
    var resultData = {
      message: '',
      data: []
    }
    console.log(statusItems);
    await post(GET_CAMPAIGN_URL + '/' + campaignId + '/receipts', JSON.stringify(statusItems), { responseType: 'json'})
    .then(res => {
      if (res.status === 200) {
        resultData.data = res.data
      } else {
        resultData.message = res.message
      }
    })
      .catch(error => {
        if (error.response !== undefined && (error.response.status === 400 || error.response.status === 401)) {
          resultData.message = error.response.data.message
        } else if(error.response === undefined || (error.response !== undefined && error.response.status !== 403)) {
          resultData.message = error.message
        }
      })
    return resultData
  },
}


export default API;