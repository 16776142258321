import React, { useState } from 'react';
import Footer from './Footer';
import API from '../API';
import clairvoyance_icon from '../images/clairvoyance_icon.png';
import cookie from 'react-cookies';
import { Modal } from 'antd';
// import { useNavigate } from 'react-router-dom';
import { Wrapper, Content, Image, Title, Span, AreaDiv, ButtonDiv, AreaLabel, AreaInput, ButtonHref } from './Login.styles';
import { useAuth } from "../Context"
import {useNavigateParams} from '../Utility';
import moment from 'moment';



const Login = () => {
   //企業ID
   const [companyId, setCompanyId] = useState('');
   //ユーザーID
   const [userId, setUserId] = useState('');
   //パスワード
   const [password, setPassword] = useState('');
   const navigate = useNavigateParams();
   const { login } = useAuth();

   //ログインボタン
   const handleLoginClick = async () => {
      var msg = '';
      if (companyId === '' || (companyId).trim() === '') {
         msg = '企業IDを入力してください。';
      } else if (userId === '' || (userId).trim() === '') {
         msg = 'ユーザーIDを入力してください。';
      } else if (password === '' || (password).trim() === '') {
         msg = 'パスワードを入力してください。';
      } 
      
      if (msg === '') {
         const loginInfo = await API.getLoginInfo(companyId, userId, password);
         if (loginInfo.message !== '') {
            const modal = Modal.error();
            modal.update({
               title:'エラー',
               content:loginInfo.message,
               onOk(){
                  modal.destroy();
               }
            })
         } else {
            cookie.save('token', loginInfo.token);
            await login();
           const loginAfterPage = localStorage.getItem('loginAfterPage');
           const paramValue = localStorage.getItem('loginAfterParam');
           console.log(loginAfterPage);

           if (loginAfterPage !== null && loginAfterPage !== undefined) {
            localStorage.removeItem('loginAfterPage');
            localStorage.removeItem('loginAfterParam');
            console.log(paramValue);
            if(paramValue === 'null' ){
               navigate(loginAfterPage,  '');
            } else {
               const resultInfo = await API.getCampaignInfo(paramValue, loginInfo.token);
               if (resultInfo.message !== '') {
                     const modal = Modal.error();
                     modal.update({
                     title:'エラー',
                     content:resultInfo.message,
                     onOk() {
                        modal.destroy();
                     }
                     })
               } else {
                     const dataInfo = resultInfo.data;
                     let dateSpan = moment(dataInfo.entryStartTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY年MM月DD日') + 
                     '～'+ moment(dataInfo.entryEndTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY年MM月DD日');
                  // localStorage.setItem('showId',item.campaignId );
                  localStorage.setItem('showName',dataInfo.campaignName );
                  localStorage.setItem('showSpan',dateSpan );
                     navigate(loginAfterPage,  `campaignId=${paramValue}`);
               }
            }
           } else {
            navigate('/campaigns', '');
           }
         }
      } else {
         const modal = Modal.error();
         modal.update({
            title:'エラー',
            content:msg,
            onOk(){
               modal.destroy();
            }
         })
      }
   }

   return (
      <Wrapper>
         <Content>
            <Image src={clairvoyance_icon} alt='千里眼アイコン'/>
            <Title><Span>千里眼</Span>ログイン</Title>
            <AreaDiv>
                  <div>
                     <AreaLabel htmlFor='company-id'>企業ID:</AreaLabel>
                     <AreaInput type='text' value={companyId} onChange={(e) => setCompanyId(e.target.value)}/>
                  </div>
                  <div>
                     <AreaLabel htmlFor='user-id'>ユーザーID:</AreaLabel>
                     <AreaInput type='text' value={userId} onChange={(e) => setUserId(e.target.value)}/>
                  </div>
                  <div>
                     <AreaLabel htmlFor='password'>パスワード:</AreaLabel>
                     <AreaInput type='password' value={password} onChange={(e) => setPassword(e.target.value)}/>
                  </div>
            </AreaDiv>
            <ButtonDiv>
               <ButtonHref onClick={handleLoginClick}>ログイン</ButtonHref>
            </ButtonDiv>
         </Content>
         <Footer />
      </Wrapper>
   );
}

export default Login;
