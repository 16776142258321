import React ,{ useEffect, useState} from 'react';
import Footer from './Footer';
import Header from './Header';
import cookie from 'react-cookies';
import API from '../API';
import moment from 'moment';
import { Modal } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useNavigateParams } from '../Utility';
// import { useLocation } from 'react-router';
import { Wrapper, Title, Content, ContentTh, ContentText, ContentNum, ContentTop,
    ContentTime, AttrEdit, AttrAdd, AttrAddSelect, AttrAddButton, ButtonCell,ButtonCancel,
     AttrLabel, AttrContent, AttrAddDiv, AttrAddInput, WrapDiv, WrapTable, WrapTr, 
    WrapTdFir, WrapTd, WrapArrow, WrapInput, WrapButtonDiv, ButtonSave} from './CampaignAddition.styles';

const CampaignSetting = () => {
    //キャンペーンID
    // const {state:{showId,showName,showSpan}} = useLocation();
    const [searchParams] = useSearchParams();
    const showId = searchParams.get('campaignId');
    const showName = localStorage.getItem('showName');
    const showSpan = localStorage.getItem('showSpan');
    //キャンペーン名称
    const [campaignName, setCampaignName] = useState('');
    //応募可能期間
    const [entryStartDate, setEntryStartDate] = useState('');
    const [entryStartTime, setEntryStartTime] = useState('');
    const [entryEndDate, setEntryEndDate] = useState('');
    const [entryEndTime, setEntryEndTime] = useState('');
    //レシート有効期間
    const [validPeriodStartDate, setValidPeriodStartDate] = useState('');
    const [validPeriodStartTime, setValidPeriodStartTime] = useState('');
    const [validPeriodEndDate, setValidPeriodEndDate] = useState('');
    const [validPeriodEndTime, setValidPeriodEndTime] = useState('');
    //応募可能回数
    const [entryMaxNumber, setEntryMaxNumber] = useState('');
    //属性
    const [attrList, setAttrList] = useState([]);
    //追加したいの属性値
    const [attrAddId, setAttrAddId] = useState('custom');
    //属性タイプ
    const attrOptionInfo = {'custom':'カスタム','gender':'性別','age':'年齢','prefecture':'都道府県','job':'職業','income':'年収'};
    //半角英数字記号(_-)
    const enRegExp = new RegExp('^[A-Za-z0-9_-]+$');
    //正の整数(ゼロを含む)
    const numRegExp = new RegExp('^\\d+$');
    //属性タイプ-性別
    const genderOpList = ['男性', '女性', 'その他'];
    //属性タイプ-年齢
    const ageOpList = ['10代未満', '10代', '20代', '30代', '40代', '50代', '60代', '70代', '80歳以上'];
    //属性タイプ-都道府県
    const prefectureOpList = ['北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県',
    '埼玉県', '千葉県', '東京都', '神奈川県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県',
    '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県',
    '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'];
    //属性タイプ-職業
    const jobOpList = ['会社員', '公務員', '自営業/個人事業', '会社役員', '自由業', '専業主婦・主夫', '学生', 'パート/アルバイト', '無職', 'その他'];
    //属性タイプ-年収
    const incomeOpList = ['200万円未満', '200万円以上400万円未満', '400万円以上600万円未満', '600万円以上800万円未満',
    '800万円以上1000万円未満', '1000万円以上1200万円未満', '1200万円以上1500万円未満', '1500万円以上2000万円未満',
    '2000万円以上', 'わからない', '答えたくない'];
    const optionListMap = {'gender': genderOpList, 'age':ageOpList, 'prefecture':prefectureOpList, 'job':jobOpList, 'income':incomeOpList};
    const token = cookie.load('token');
    const navigate = useNavigateParams();
    

    useEffect(() => {
        const getResultInfo =  async () => {
            const resultInfo = await API.getCampaignInfo(showId, token);
            if (resultInfo.message !== '') {
                const modal = Modal.error();
                modal.update({
                title:'エラー',
                content:resultInfo.message,
                onOk() {
                    modal.destroy();
                 }
                })
            } else {
                const dataInfo = resultInfo.data;
                //キャンペーン名称
                setCampaignName(dataInfo['campaignName']);
                //応募可能期間
                setEntryStartDate(moment(dataInfo['entryStartTime'], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'));
                setEntryStartTime(moment(dataInfo['entryStartTime'], 'YYYY-MM-DD HH:mm:ss').format('HH:mm'));
                setEntryEndDate(moment(dataInfo['entryEndTime'], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'));
                setEntryEndTime(moment(dataInfo['entryEndTime'], 'YYYY-MM-DD HH:mm:ss').format('HH:mm')); 
                //レシート有効期間
                setValidPeriodStartDate(moment(dataInfo['validPeriodStartTime'], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'));
                setValidPeriodStartTime(moment(dataInfo['validPeriodStartTime'], 'YYYY-MM-DD HH:mm:ss').format('HH:mm'));
                setValidPeriodEndDate(moment(dataInfo['validPeriodEndTime'], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'));
                setValidPeriodEndTime(moment(dataInfo['validPeriodEndTime'], 'YYYY-MM-DD HH:mm:ss').format('HH:mm')); 
                //応募可能回数
                if (dataInfo['entryMaxNumber'] !== undefined) {
                    setEntryMaxNumber(dataInfo['entryMaxNumber']);
                }
                //属性
                if (dataInfo['customerAttribution'] !== undefined) {
                    let cusAttrList = [];
                    dataInfo['customerAttribution'].forEach( item => {
                      let cusItem = {'id':'', 'description':'', 'optionText':'', 'options': []};
                      if (item.id !== undefined) {
                        cusItem['id'] = item.id;
                      }
                      if (item.description !== undefined) {
                        cusItem['description'] = item.description;
                      }
                      if (item.options !== undefined) {
                         let newOpList = [];
                         Object.keys(item.options).forEach(opKey => {
                           let opItem = {};
                           opItem['value'] = item.options[opKey];
                           opItem['checked'] = false;
                           newOpList = [...newOpList, opItem]
                         });
                         cusItem['options'] = newOpList;
                      }
                      cusAttrList = [...cusAttrList, cusItem];
                    }); 
                    if (cusAttrList.length > 0) {
                        setAttrList([...cusAttrList]);
                    }
                } 
            }
          }
          getResultInfo();
    },[]); //eslint-disable-line

    //追加したい属性値
    const handleAttrAdd = (e)=> {
        setAttrAddId(e.target.value);
    }
    //属性を追加ボタン
    const handleAttrClick = () => {
        let attrItem = {'id':'', 'description':'', 'optionText':'', 'options': []};
        if (attrAddId !== 'custom') {
            attrItem['id'] = attrAddId;
            attrItem['description'] = attrOptionInfo[attrAddId];
            attrItem['options'] = getOptionItemList(optionListMap[attrAddId]);
        }
        setAttrList([...attrList, attrItem]);
    }
    //追加したい選択肢値の変更
    const handleOptionTextChange = (e, index) => {
        let newList = attrList;
        newList[index]['optionText'] = e.target.value;
        setAttrList([...newList]);
    }
    //選択肢を追加ボタン
    const handleOptionAdd = (index) => {
        let dataList = attrList;
        let optionVal = dataList[index]['optionText'];
        if (optionVal !== '' && optionVal.trim() !== '') {
          let optionData = dataList[index]['options'];
          let newItem = {'value':optionVal,'checked':false};
          dataList[index]['options'] = [...optionData, newItem];
          dataList[index]['optionText'] = '';
          setAttrList([...dataList]);
        }
    }
    //この属性を削除ボタン
    const handleAttrDel = (index) => {
        let newList = attrList;
        newList.splice(index, 1);
        setAttrList([...newList]);
    }
    //削除したい選択肢の選択変更
    const handleOptionCheckChange = (index, opIndex) => {
        let newList = attrList;
        let newOptionList = newList[index]['options'];
        newOptionList[opIndex]['checked'] = !newOptionList[opIndex]['checked'];
        newList[index]['options'] = newOptionList;
        setAttrList([...newList]);
    }
    //選択した選択肢を削除ボタン
    const handleOptionDel = (index) => {
        let newList = attrList;
        let optionList = newList[index]['options'];
        let newOptionList = [];
        optionList.forEach(item => {
          if (!item.checked) {
                newOptionList = [...newOptionList, item];
           }
        })
        newList[index]['options'] = newOptionList;
        setAttrList([...newList]);
    }
    //属性IDの変更
    const handleAttrIdChange = (e, index) => {
        let newList = attrList;
        newList[index]['id'] = e.target.value;
        setAttrList([...newList]);
    }
    //属性名の変更
    const handleAttrNameChange = (e, index) => {
        let newList = attrList; 
        newList[index]['description'] = e.target.value;
        setAttrList([...newList]);
    }
    //属性選択肢上矢印
    const handleOptionUp = (index, opIndex) => {
        if (opIndex !== 0) { 
          let newList = attrList;
          let optionList = newList[index]['options'];
          let optionUpData = optionList[opIndex - 1];
          let optionDownData = optionList[opIndex];
          optionList[opIndex] = optionUpData;
          optionList[opIndex - 1] = optionDownData;
          newList[index]['options'] = optionList;
          setAttrList([...newList]);
        }  
    }
    //属性選択肢下矢印
    const handleOptionDown = (index, opIndex) => {
        let newList = attrList;
        let optionList = newList[index]['options'];
        if (opIndex !== (optionList.length-1)) {
            let optionUpData = optionList[opIndex];
            let optionDownData = optionList[opIndex + 1];
            optionList[opIndex] = optionDownData;
            optionList[opIndex + 1] = optionUpData;
            newList[index]['options'] = optionList;
            setAttrList([...newList]);
        }
    }
    // キャンセルボタン
    const handleCancel = () => {
        navigate("/campaigns/top", `campaignId=${showId}`)
    }
    //保存ボタン
    const handleCampaignSubmit = async () => {
        var msg = '';
        if (campaignName === '' || campaignName.trim() === '') {
            msg = 'キャンペーン名称を入力してください。';
        } else if (entryStartDate === '' || entryStartDate.trim() === '' ||
            entryStartTime === '' || entryStartTime.trim() === '' ||
            entryEndDate === '' || entryEndDate.trim() === '' ||
            entryEndTime === '' || entryEndTime.trim() === '') {
            msg = '応募可能期間を入力してください。';
        } else if (getStartDateTime(entryStartDate, entryStartTime) > getEndDateTime(entryEndDate, entryEndTime)) {
            msg = '応募可能期間の時間範囲が無効です。';
        } else if (validPeriodStartDate === '' || validPeriodStartDate.trim() === '' ||
            validPeriodStartTime === '' || validPeriodStartTime.trim() === '' ||
            validPeriodEndDate === '' || validPeriodEndDate.trim() === '' ||
            validPeriodEndTime === '' || validPeriodEndTime.trim() === '') {
            msg = 'レシート有効期間を入力してください。';
        } else if (getStartDateTime(validPeriodStartDate, validPeriodStartTime) > getEndDateTime(validPeriodEndDate, validPeriodEndTime)) {
            msg = 'レシート有効期間の時間範囲が無効です。';
        } else if (entryMaxNumber !== '' && !numRegExp.test(entryMaxNumber)) {
            msg = '応募可能回数は正の整数で入力してください。';
        } else if (attrList.length > 0) {
           for (var i=0; i < attrList.length; i++) {
             if (attrList[i]['id'] === '' || attrList[i]['id'].trim() === '') {
                msg = '属性'+ (i+1) +'の属性IDを入力してください。';
             } else if (!enRegExp.test(attrList[i]['id'])) {
                msg = '属性'+ (i+1) +'の属性IDは半角英数字記号（_-）で入力してください。';
             } else if (attrList[i]['description'] === '' || attrList[i]['description'].trim() === '') {
                msg = '属性'+ (i+1) +'の属性名を入力してください。';
             } else if (attrList[i]['options'].length === 0) {
                msg = '属性'+ (i+1) +'の属性選択肢を設定してください。';
             }
             if (msg !== '') {
               break;
             }
           }
        }
        
        if (msg !== '') {
            const modal = Modal.error();
            modal.update({
               title:'エラー',
               content:msg,
               onOk(){
                  modal.destroy();
               }
            })
        } else {
            //入力された値
            let saveInfo = {
               campaignId:showId,
               campaignName:campaignName,
               entryStartTime:getStartDate(entryStartDate, entryStartTime),
               entryEndTime:getEndDate(entryEndDate, entryEndTime),
               validPeriodStartTime:getStartDate(validPeriodStartDate, validPeriodStartTime),
               validPeriodEndTime:getEndDate(validPeriodEndDate, validPeriodEndTime)
            };
            if (entryMaxNumber !== '') {
                saveInfo['entryMaxNumber'] = parseInt(entryMaxNumber);
            }
            if (attrList.length > 0) {
              let cusItemList = [];
              attrList.forEach(item => {
                  let cusItem = {
                      id:item.id,
                      description:item.description
                  };
                  let desInfo = {};
                  item.options.forEach((opItem, index) => {
                    desInfo[index] = opItem.value;
                  })
                  cusItem['options'] = desInfo;
                  cusItemList = [...cusItemList, cusItem];
              });
              saveInfo['customerAttribution'] = cusItemList;
            }
            //入力された値をDBに登録する
            const resultData = await API.addCampaignInfo(saveInfo, showId, token);
            if (resultData.message !== '') {
                const modal = Modal.error();
                modal.update({
                   title:'エラー',
                   content:resultData.message,
                   onOk(){
                      modal.destroy();
                   }
                })
             } else {
                //キャンペーン一覧画面に遷移する
                navigate('/campaigns', '')
            }
        }
    }
    //キャンペーンを削除ボタン
    const handleCampaignDel = async () => {
        const modal = Modal.confirm()
        modal.update({
            content: '削除します。よろしいですか？',
            okText: "OK",
            cancelText: "キャンセル",
            async onOk () {
                const delInfo = await API.delCampaignInfo(showId, token)
                if (delInfo.message !== '') {
                    const modal = Modal.error()
                    modal.update({
                        title: 'エラー',
                        content: delInfo.message,
                        onOk () {
                            modal.destroy()
                        }
                    })
                } else {
                    navigate('/campaigns', '')
                }
            },
            onCancel () {
                modal.destroy()
            }
        });
    }
    //開始日時（ミリ秒）
    const getStartDateTime = (dateStr, timeStr) => {
        return new Date(moment(dateStr).format('YYYY-MM-DD') + ' ' + moment(timeStr, 'HH:mm').format('HH:mm:00')).getTime();
    }
    //終了日時（ミリ秒）
    const getEndDateTime = (dateStr, timeStr) => {
        return new Date(moment(dateStr).format('YYYY-MM-DD') + ' ' + moment(timeStr, 'HH:mm').format('HH:mm:59')).getTime();
    }
    //開始日時（YYYY-MM-DD HH:mm:ss）
    const getStartDate = (dateStr, timeStr) => {
        return moment(dateStr).format('YYYY-MM-DD') + ' ' + moment(timeStr, 'HH:mm').format('HH:mm:00');
    }
    //終了日時（YYYY-MM-DD HH:mm:ss）
    const getEndDate = (dateStr, timeStr) => {
        return moment(dateStr).format('YYYY-MM-DD') + ' ' + moment(timeStr, 'HH:mm').format('HH:mm:59');
    }
    //属性タイプコンボボックス
    const getOptionItemList = (dataList) => {
        let resultList = [];
        dataList.forEach(item => {
           let newItem = {};
           newItem['value'] = item;
           newItem['checked'] = false;
           resultList = [...resultList, newItem];
        });
        return resultList;
    }

    return (
        <Wrapper>
            <Header showFlag={'page3'} showName={showName} showSpan={showSpan} showId={showId}/>
            <main>
                <div>
                <Title>キャンペーン情報修正</Title>  
                </div>
                <Content>
                <table>
                    <tbody>
                    <tr>
                        <ContentTh>キャンペーンID:</ContentTh>
                        <td>{showId}</td>
                    </tr>
                    <tr>
                        <ContentTh>キャンペーン名称:</ContentTh>
                        <td><ContentText type="text" maxLength='100' value={campaignName} onChange={(e) => setCampaignName(e.target.value)}/></td>
                    </tr>
                    <tr>
                        <ContentTh>応募可能期間:</ContentTh>
                        <td>
                            <input type="date" value={entryStartDate} onChange={(e) => setEntryStartDate(e.target.value)}/> 
                            <ContentTime type="time" value={entryStartTime} onChange={(e) => setEntryStartTime(e.target.value)}/>
                            ~ 
                            <input type="date" value={entryEndDate} onChange={(e) => setEntryEndDate(e.target.value)}/> 
                            <ContentTime type="time" value={entryEndTime} onChange={(e) => setEntryEndTime(e.target.value)}/>                              
                        </td>
                    </tr>
                    <tr>
                        <ContentTh>レシート有効期間:</ContentTh>
                        <td>
                            <input type="date" value={validPeriodStartDate} onChange={(e) => setValidPeriodStartDate(e.target.value)}/> 
                            <ContentTime type="time" value={validPeriodStartTime} onChange={(e) => setValidPeriodStartTime(e.target.value)}/>
                            ~ 
                            <input type="date" value={validPeriodEndDate} onChange={(e) => setValidPeriodEndDate(e.target.value)}/> 
                            <ContentTime type="time" value={validPeriodEndTime} onChange={(e) => setValidPeriodEndTime(e.target.value)}/>   
                        </td>
                    </tr>
                    <tr>
                        <ContentTh>応募可能回数:</ContentTh>
                        <td><ContentNum type="number" value={entryMaxNumber} onChange={(e) => setEntryMaxNumber(e.target.value)}/></td>
                    </tr>
                    { attrList.map((item, index) =>
                    <tr key={index}>
                        <ContentTop>属性{index + 1}:</ContentTop>
                        <td>
                            <AttrEdit>
                            <div>
                                <AttrLabel htmlFor='attribution-id'>属性ID:</AttrLabel>
                                <AttrContent type="text" maxLength='50' value={item.id} onChange={(e) => handleAttrIdChange(e, index)}/>
                            </div>
                            <div>
                                <AttrLabel htmlFor='attribution-name'>属性名:</AttrLabel>
                                <AttrContent type="text" maxLength='50' value={item.description} onChange={(e) => handleAttrNameChange(e, index)}/>
                            </div>
                            <AttrAddDiv>
                                <AttrAddInput type="text" maxLength='50' value={item.optionText} onChange={(e)=> handleOptionTextChange(e,index)}/>
                                <ButtonSave onClick={() => handleOptionAdd(index)}>選択肢を追加</ButtonSave>
                            </AttrAddDiv>
                            <WrapDiv>
                                <WrapTable>
                                    <tbody>
                                    { item.options.map((opItem, opIndex) =>
                                    <WrapTr key= {opIndex}>
                                        <WrapTdFir><WrapInput type="checkbox" checked = {opItem.checked} onChange={() => handleOptionCheckChange(index, opIndex)}/></WrapTdFir>
                                        <WrapTd contenteditable="true">{opItem.value}</WrapTd>
                                        <WrapArrow><i onClick={() => handleOptionUp(index, opIndex)} className='fa-solid fa-arrow-up'/></WrapArrow>
                                        <WrapArrow><i onClick={() => handleOptionDown(index, opIndex)} className='fa-solid fa-arrow-down'/></WrapArrow>
                                    </WrapTr>
                                    )}
                                    </tbody>
                                </WrapTable>
                            </WrapDiv>
                            <WrapButtonDiv>
                                <AttrAddButton onClick={() => handleAttrDel(index)}>この属性を削除</AttrAddButton>
                                <AttrAddButton onClick={() => handleOptionDel(index)}>選択した選択肢を削除</AttrAddButton>
                            </WrapButtonDiv>
                            </AttrEdit>
                          </td>
                        </tr>
                        )}
                        <tr>
                            <td></td>
                            <td>
                            <AttrAdd>
                                <AttrAddSelect value={attrAddId} onChange={handleAttrAdd}>
                                {Object.keys(attrOptionInfo).map(opKey =>
                                <option key={opKey} value={opKey}>{attrOptionInfo[opKey]}</option>
                                )}
                                </AttrAddSelect>
                                <AttrAddButton onClick={handleAttrClick}>属性を追加</AttrAddButton>
                            </AttrAdd>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <ButtonCell>
                            <ButtonCancel onClick={handleCancel}>キャンセル</ButtonCancel>
                            <ButtonCancel onClick={handleCampaignDel}>キャンペーンを削除</ButtonCancel>
                            <ButtonSave onClick={handleCampaignSubmit}>保存</ButtonSave>
                        </ButtonCell>
                    </tr>
                    </tbody>
                </table> 
                </Content>
            </main>
            <Footer/>
        </Wrapper>
    )   
}

export default CampaignSetting;