import clairvoyance_icon from '../images/clairvoyance_icon.png';
import cookie from 'react-cookies';
import {
  Image, Title, Nav, NavUl, NavLi, Ahref, CampaignTitle,
  CampaignHref, CampaignH1, CampaigDate, NavMainLi, NavMenuLi,
  NavP, NavSpan, SubMenu, SubLi
} from './Header.styles';

import {useNavigateParams} from '../Utility';

const Header = (props) => {
  const showPageFlag = props.showFlag;
  // const navigate = useNavigate();
  const navigate = useNavigateParams();
  //ログアウト
  const handleLogoutClick = () => {
    //cookieのクリア
    cookie.remove('token');
    localStorage.clear();
    navigate('/','');
  }

  if (showPageFlag === 'page3') {
    const showName = props.showName;
    const showSpan = props.showSpan;
    const showId = props.showId;
    //キャンペーン情報修正
    const handleSettingClick = () => {
      // navigate('/campaigns/setting/' + showId, { state: { showId: showId, showName: showName, showSpan: showSpan } });
      navigate("/campaigns/setting", `campaignId=${showId}`);
    }
    //対象商品一覧取得
    const handleProductSettingClick = () => {
      // navigate('/campaigns/productsetting', { state: { campaignId: showId, showName, showSpan } });
      navigate("/campaigns/productsetting", `campaignId=${showId}`, {});
    }
    //地域ごと統計
    const handleRegionClick = () => {
      // navigate('/campaigns/region', { state: { showId: showId, showName: showName, showSpan: showSpan } });
      navigate("/campaigns/region", `campaignId=${showId}`);

    }
    //キャンペーントップ
    const handleTopClick = () => {
      // navigate('/campaigns/top', { state: { showId: showId, showName: showName, showSpan: showSpan } });
      navigate("/campaigns/top", `campaignId=${showId}`);

    }
    //商品ごと統計
    const handleItemClick = () => {
      // navigate('/campaigns/item', { state: { showId: showId, showName: showName, showSpan: showSpan } });
      navigate("/campaigns/item", `campaignId=${showId}`);

    }
    //消費傾向統計
    const handleConsumerClick = () => {
      // navigate('/campaigns/consumer', { state: { showId: showId, showName: showName, showSpan: showSpan } });
      navigate("/campaigns/consumer", `campaignId=${showId}`);

    }
    //レシート検索
    const hsndleReceiptClick = () => {
      // navigate('/campaigns/receiptslist', { state: { campaignId: showId, showName: showName, showSpan: showSpan } });
      navigate("/campaigns/receiptslist", `campaignId=${showId}`);

    }

    return (
      <header>
          <Image src={clairvoyance_icon}/>
          <CampaignTitle>
             <CampaignHref onClick={handleTopClick}>
               <CampaignH1>{showName}</CampaignH1>
             </CampaignHref>
             <CampaigDate>{showSpan}</CampaigDate>
             </CampaignTitle>
             <Nav>
               <NavUl>
                  <NavMainLi>
                     <Ahref onClick={handleTopClick}>キャンペーン<br/>トップ</Ahref>
                  </NavMainLi>
                  <NavMenuLi>
                    <NavP>統計<NavSpan>▼</NavSpan></NavP>
                    <SubMenu>
                          <SubLi><Ahref onClick={handleItemClick}>商品ごと統計</Ahref></SubLi>
                          <SubLi><Ahref onClick={handleRegionClick}>地域ごと統計</Ahref></SubLi>
                          <SubLi><Ahref onClick={handleConsumerClick}>消費傾向統計</Ahref></SubLi>                          
                    </SubMenu>
                  </NavMenuLi>
                  <NavLi>
                    <Ahref onClick={hsndleReceiptClick}>レシート検索</Ahref>
                  </NavLi>
                  <NavMenuLi>
                  <NavP>設定<NavSpan>▼</NavSpan></NavP>
                    <SubMenu>
                          <SubLi><Ahref onClick={handleProductSettingClick}>商品情報設定</Ahref></SubLi>
                          <SubLi><Ahref onClick={handleSettingClick}>キャンペーン情報修正</Ahref></SubLi>                         
                    </SubMenu>
                  </NavMenuLi>
                  <NavMainLi><Ahref href='/campaigns'>キャンペーン<br/>一覧に戻る</Ahref></NavMainLi>
                  <NavLi><Ahref onClick={handleLogoutClick}>ログアウト</Ahref></NavLi>
               </NavUl>
             </Nav>
      </header> 
    )
  } else if (showPageFlag === 'page2') {
    return (
      <header>
        <Image src={clairvoyance_icon} />
        <Title>千里眼</Title>
        <Nav>
          <NavUl>
            <NavLi><Ahref onClick={handleLogoutClick}>ログアウト</Ahref></NavLi>
          </NavUl>
        </Nav>
      </header>
    )
  } else {
    return (
      <header>
        <Image src={clairvoyance_icon} />
        <Title>千里眼</Title>
      </header>
    )
  }
}

export default Header;