import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import API from '../API';
import cookie from 'react-cookies';
import { Modal } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import ReactPaginate from 'react-paginate';
import {
  Wrapper, Title, Content, ContentTh, ContentText, ContentNum, ButtonCell, ButtonSave,
  Pop, TooltipCo, ButtonSearch,
  ListTable, ListTr, ListCodeTd, ListNameTd, ListStringTd, ListkeyTd, ListExckeyTd, ListTopTd, ListNumberTd, Listhref,
  Pagination, SerchTable, Serch, SerchTd, SerchUnitTd, ListKeySurroundTd
} from './ProductSetting.style';
//450行目あたりでBootstrapインストール

import { useNavigateParams } from '../Utility';

let globalCurrentPage = 0;
let isSearched = false;

//ツールチップ
const Tooltip = ({ children, content }) => {
  const [show, setShow] = useState(false);
  return (
    <TooltipCo style={{ display: content !== undefined ? 'block' : 'none' }}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}>
      <div>
        {children}
      </div>
      {show && content !== undefined && <Pop>{content?.join(', ')}</Pop>}
    </TooltipCo>
  );
}


const Items = ({ handleProductEditClick, currentItems, handleChange, handleProductAllCheck, del, pageChecked }) => {

  return (
    <ListTable>
      <tbody>
        <ListTr key={globalCurrentPage}>
          <ListTopTd><input type="checkbox" onChange={handleProductAllCheck} id="allCheck" value={globalCurrentPage} checked={pageChecked.includes(globalCurrentPage)} /></ListTopTd>
          <ListTopTd>JANコード</ListTopTd>
          <ListTopTd>ブランド</ListTopTd>
          <ListTopTd>最低単価</ListTopTd>
          <ListTopTd>最高単価</ListTopTd>
          <ListTopTd>キーワードリスト</ListTopTd>
          <ListTopTd>除外リスト</ListTopTd>
        </ListTr>
        {
          currentItems &&
          currentItems.map((item) => (
            <ListTr key={item.janCode}>
              <ListCodeTd>
                <input
                  name="product"
                  type="checkbox"
                  value={item.janCode}
                  onChange={handleChange}
                  checked={del.includes(item.janCode)}
                />
              </ListCodeTd>
              <ListStringTd><Listhref onClick={() => { handleProductEditClick(item) }} >{item.janCode}</Listhref></ListStringTd>
              <ListNameTd>{item.brandName}</ListNameTd>
              <ListNumberTd>{item.unitPrice?.lowerLimit?.toLocaleString()}</ListNumberTd>
              <ListNumberTd>{item.unitPrice?.upperLimit?.toLocaleString()}</ListNumberTd>
              <ListKeySurroundTd>
                <Tooltip content={item.wordList}>
                  <ListkeyTd>
                    {item.wordList.join(', ')}
                  </ListkeyTd>
                </Tooltip>
              </ListKeySurroundTd>

              <ListKeySurroundTd>

                <Tooltip content={item.excludeList}>
                  <ListExckeyTd>
                    {item.excludeList?.join(', ')}
                  </ListExckeyTd>
                </Tooltip>
              </ListKeySurroundTd>

            </ListTr>
          ))
        }
      </tbody>
    </ListTable >
  );
}

//ページネーション処理
function PaginatedItems({ handleProductEditClick, itemsPerPage, items, handleProductAllCheck, handleChange, del, pageChecked }) {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {

    if (isSearched) {
      isSearched = false;
      const endOffset = 0 + itemsPerPage;
      globalCurrentPage = 0;
      setItemOffset(0);
      setCurrentItems(items.slice(0, endOffset));
    } else {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(items.slice(itemOffset, endOffset));
    }

    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, items, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(event.selected);
    console.log(newOffset);
    globalCurrentPage = event.selected;
    setItemOffset(newOffset);
    // setInitialPage(event.selected);
  };

  return (
    <>
      <Items
        handleProductEditClick={handleProductEditClick}
        currentItems={currentItems}
        handleProductAllCheck={handleProductAllCheck}
        handleChange={handleChange}
        del={del}
        pageChecked={pageChecked}
      />
      <Pagination>
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}

          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          marginPagesDisplayed={1} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
          pageRangeDisplayed={1} // アクティブなページを基準にして、そこからいくつページ数を表示するか

          pageClassName="page-item"
          pageLinkClassName="page-link"
          activeClassName="active"

          previousClassName="page-item"
          previousLinkClassName="page-link"

          nextClassName="page-item"
          nextLinkClassName="page-link"

          // //中間ページの省略表記関連
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          forcePage={globalCurrentPage}
        />
      </Pagination>
    </>
  );
}

const ProductList = () => {
  const token = cookie.load('token');
  //キャンペーンID
  // const { state: { campaignId, showName, showSpan, reSearchInfo } } = useLocation();
  const { state: { reSearchInfo } } = useLocation();
  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get('campaignId');
  const showName = localStorage.getItem('showName');
  const showSpan = localStorage.getItem('showSpan');
  //JANコード
  const [janCode, setJanCode] = useState('');
  //ブランド名称
  const [brandName, setBrandName] = useState('');
  //容量
  const [standardSize, setStandardSize] = useState('');
  //キーワード
  const [wordList, setWordList] = useState('');
  //単位数
  const [unitNumber, setUnitNumber] = useState('');
  //最低単価
  const [lowerLimit, setLowerLimit] = useState('');
  //最高単価
  const [upperLimit, setUpperLimit] = useState('');
  //正の整数(ゼロを含む)
  const numRegExp = new RegExp('^\\d+$');
  const [searchInfo, setSearchInfo] = useState('');
  const [productWords, setProductWords] = useState([]);
  const navigate = useNavigateParams();
  const itemsPageNum = 10;

  //初期画面表示
  useEffect(() => {
    if (reSearchInfo === undefined) {
      const startResultInfo = async () => {
        const resultData = await API.getProductDataList(searchInfo, campaignId, token);
        if (resultData.message !== '') {
          const modal = Modal.error();
          modal.update({
            title: 'エラー',
            content: resultData.message,
            onOk() {
              modal.destroy();
            }
          })
        } else {
          //結果を出した状態でページに戻る
          const dataInfo = resultData.data;
          setProductWords([...dataInfo]);
        }
      }
      startResultInfo();
    } else {


      let searchInfo = {
      };
      if (reSearchInfo.searchInfo.janCode !== undefined) {
        searchInfo['janCode'] = reSearchInfo.searchInfo.janCode;
        setJanCode(reSearchInfo.searchInfo.janCode);
      }
      if (reSearchInfo.searchInfo.brandName !== undefined) {
        searchInfo['brandName'] = reSearchInfo.searchInfo.brandName;
        setBrandName(reSearchInfo.searchInfo.brandName);
      }
      if (reSearchInfo.searchInfo.standardSize !== undefined) {
        searchInfo['standardSize'] = reSearchInfo.searchInfo.standardSize;
        setStandardSize(reSearchInfo.searchInfo.standardSize);
      }
      if (reSearchInfo.searchInfo.unitNumber !== undefined) {
        searchInfo['unitNumber'] = parseInt(reSearchInfo.searchInfo.unitNumber);
        setUnitNumber(reSearchInfo.searchInfo.unitNumber);
      }
      if (reSearchInfo.searchInfo.lowerLimit !== undefined) {
        searchInfo['lowerLimit'] = parseInt(reSearchInfo.searchInfo.lowerLimit);
        setLowerLimit(reSearchInfo.searchInfo.lowerLimit);
      }
      if (reSearchInfo.searchInfo.upperLimit !== undefined) {
        searchInfo['upperLimit'] = parseInt(reSearchInfo.searchInfo.upperLimit);
        setUpperLimit(reSearchInfo.searchInfo.upperLimit);
      }

      if (reSearchInfo.searchInfo.keyword !== undefined) {
        searchInfo['keyword'] = reSearchInfo.searchInfo.keyword;
        setWordList(reSearchInfo.searchInfo.keyword);
      }



      const startResultInfo = async () => {
        const resultData = await API.getProductDataList(searchInfo, campaignId, token);
        if (resultData.message !== '') {
          const modal = Modal.error();
          modal.update({
            title: 'エラー',
            content: resultData.message,
            onOk() {
              modal.destroy();
            }
          })
        } else {
          //結果を出した状態でページに戻る
          const dataInfo = resultData.data;
          setProductWords([...dataInfo]);
        }
      }
      startResultInfo();
    }


  }, [])

  //検索
  const handleProductSearch = async () => {

    //削除判定状態のjanCodeを空にする
    del.length = 0;
    pageChecked.length = 0;
    // allCheck.checked = false;
    var msg = '';
    if (unitNumber !== '' && !numRegExp.test(unitNumber) && unitNumber !== undefined) {
      msg = '単位数は正の整数で入力してください';
    } else if (unitNumber === 0 && unitNumber !== undefined && unitNumber !== '') {
      msg = '単位数は正の整数で入力してください';
    } else if (lowerLimit !== '' && !numRegExp.test(lowerLimit) && lowerLimit !== undefined) {
      msg = '最低単価は0以上の整数で入力してください';
    } else if (upperLimit !== '' && !numRegExp.test(upperLimit) && upperLimit !== undefined) {
      msg = '最高単価は0以上の整数で入力してください';
    } else if (parseInt(upperLimit) < parseInt(lowerLimit)) {
      msg = '単価の値範囲が無効です';
    }

    if (msg !== '') {
      const modal = Modal.error();
      modal.update({
        title: 'エラー',
        content: msg,
        onOk() {
          modal.destroy();
        }
      })
    } else {
      //入力された値
      let searchInfo = {
      };

      if (janCode !== '') {
        searchInfo['janCode'] = janCode;
      }
      if (brandName !== '') {
        searchInfo['brandName'] = brandName;
      }
      if (standardSize !== '') {
        searchInfo['standardSize'] = standardSize;
      }
      if (wordList !== '') {
        searchInfo['keyword'] = wordList;
      };
      if (unitNumber !== '' && unitNumber !== undefined) {
        searchInfo['unitNumber'] = parseInt(unitNumber);
      }
      if (lowerLimit !== '' && lowerLimit !== undefined) {
        searchInfo['lowerLimit'] = parseInt(lowerLimit);
      }
      if (upperLimit !== '' && upperLimit !== undefined) {
        searchInfo['upperLimit'] = parseInt(upperLimit);
      }
      //入力された値でDBを検索する
      const resultData = await API.getProductDataList(searchInfo, campaignId, token);
      if (resultData.message !== '') {
        const modal = Modal.error();
        modal.update({
          title: 'エラー',
          content: resultData.message,
          onOk() {
            modal.destroy();
          }
        })
      } else {
        //結果を出した状態でページに戻る
        const dataInfo = resultData.data;
        console.log(dataInfo);
        setProductWords([...dataInfo]);
        setSearchInfo(searchInfo);
        setDel([]);
        setPageChecked([]);
        isSearched = true;
        // navigate('/campaigns/productsetting', { state: { resultData, campaignId, showName, showSpan, searchInfo } });
        navigate("/campaigns/productsetting", `campaignId=${campaignId}`, { reSearchInfo: searchInfo });

      }
    }
  }
  //JANコードリンク
  const handleProductEditClick = (item) => {
    globalCurrentPage = 0;
    // navigate('/campaigns/productediting', { state: { searchInfo, campaignId, showName, showSpan, item } });
    navigate("/campaigns/productediting", `campaignId=${campaignId}`, { searchInfo: searchInfo, item: item });

  };


  //追加ボタン
  const handleProductAdd = () => {
    globalCurrentPage = 0;
    // navigate('/campaigns/productediting', { state: { searchInfo, campaignId, showName, showSpan } });
    navigate("/campaigns/productediting", `campaignId=${campaignId}`, { searchInfo: searchInfo });


  }

  //単一のみの削除選択
  const [del, setDel] = useState([]);
  const handleChange = e => {
    if (del.includes(e.target.value)) {
      setDel(del.filter(item => item !== e.target.value));
    } else {
      setDel([...del, e.target.value]);
    }
  };

  //全選択削除判定
  // const allCheck = document.getElementById("allCheck");
  const delbutton = document.getElementById("delbutton");
  const [pageChecked, setPageChecked] = useState([]);

  const handleProductAllCheck = () => {
    let startIndex = itemsPageNum * globalCurrentPage;
    let endIndex = startIndex + itemsPageNum;
    let checkList = [];
    checkList = productWords.slice(startIndex, endIndex);

    let tempDel = [...del];
    let unCheckedArr = [];
    checkList.forEach((item) => {
      if (pageChecked.includes(globalCurrentPage)) {
        unCheckedArr = [...unCheckedArr, item.janCode];
      } else {
        tempDel = [...tempDel, item.janCode]
      }
    }
    )
    if (pageChecked.includes(globalCurrentPage)) {
      setPageChecked(pageChecked.filter(item => item !== globalCurrentPage));
    } else {
      setPageChecked([...pageChecked, globalCurrentPage]);
    }
    if (tempDel.length === 0) {
      delbutton.disabled = true;
      setDel([]);
    } else {
      if (unCheckedArr.length > 0) {
        setDel(tempDel.filter(item => !unCheckedArr.includes(item)));
      } else {
        setDel([...tempDel]);
      }
    }
    // if (del.length === 0) {

    //   productWords.forEach((item) =>
    //     setDel(prev => [...prev, item.janCode])
    //   )

    //   allCheck.checked = true;
    // } else {
    //   del.length = 0;
    //   allCheck.checked = false;
    //   delbutton.disabled = true;
    //   setDel([]);

    // }
  }

  //削除ボタン
  const handleProductDelete = async () => {

    const deletedItems = { 'deletedItems': del };
    const delData = await API.delProductData(deletedItems, campaignId, token);
    if (delData.message !== '') {
      const modal = Modal.error();
      modal.update({
        title: 'エラー',
        content: delData.message,
        onOk() {
          modal.destroy();
        }
      })
      //データ削除後もう一度検索してる
    } else {
      //削除判定状態のjanCodeを空にする
      del.length = 0;
      pageChecked.length = 0;
      // allCheck.checked = false;
      //入力された値でDBを検索する
      const resultData = await API.getProductDataList(searchInfo, campaignId, token);
      if (resultData.message !== '') {
        const modal = Modal.error();
        modal.update({
          title: 'エラー',
          content: resultData.message,
          onOk() {
            modal.destroy();
          }
        })
      } else {
        //結果を出した状態でページに戻る
        const dataInfo = resultData.data;
        setProductWords([...dataInfo]);
        setPageChecked([]);
        isSearched = true;
        // navigate('/campaigns/productsetting', { state: { resultData, campaignId } });
        navigate("/campaigns/productsetting", `campaignId=${campaignId}`, { reSearchInfo: resultData });

      }
    }
  }

  return (
    <Wrapper>
      <Header showFlag={'page3'} showName={showName} showSpan={showSpan} showId={campaignId} />
      <main>
        <Serch>
          <Title>商品情報設定</Title>
          <SerchTable>
            <tbody>
              <tr>
                <ContentTh>JANコード:</ContentTh>
                <SerchTd><ContentText type="text" maxLength='13' value={janCode} onChange={(e) => setJanCode(e.target.value)} /></SerchTd>
                <ContentTh>ブランド:</ContentTh>
                <SerchTd><ContentText type="text" maxLength='100' value={brandName} onChange={(e) => setBrandName(e.target.value)} /></SerchTd>
              </tr>
              <tr>
                <ContentTh>単価:</ContentTh>
                <SerchUnitTd>
                  <ContentNum type="number" maxLength='10' value={lowerLimit} onChange={(e) => setLowerLimit(e.target.value)} />
                  ~
                  <ContentNum type="number" maxLength='10' value={upperLimit} onChange={(e) => setUpperLimit(e.target.value)} />
                </SerchUnitTd>
                <ContentTh>キーワード:</ContentTh>
                <SerchTd><ContentText type="text" maxLength='100' value={wordList} onChange={(e) => setWordList(e.target.value)} /></SerchTd>
                <td> <ButtonSearch onClick={handleProductSearch}>検索</ButtonSearch></td>
              </tr>
            </tbody>
          </SerchTable>
        </Serch>
        <hr width="100%"></hr>
        <Content>

          <ButtonCell>
            <ButtonSave onClick={handleProductDelete} disabled={!del.length} id="delbutton">削除</ButtonSave>
            <ButtonSave onClick={handleProductAdd}> 追加</ButtonSave>
          </ButtonCell>
          <PaginatedItems itemsPerPage={itemsPageNum} items={productWords} handleProductAllCheck={handleProductAllCheck} handleChange={handleChange} handleProductEditClick={handleProductEditClick} del={del} pageChecked={pageChecked} />
        </Content>
      </main >
      <Footer />
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous"></link>
    </Wrapper >

  )
}
export default ProductList;