import React, { useState, useEffect} from 'react';
import Footer from './Footer';
import Header from './Header';
import API from '../API';
import cookie from 'react-cookies';
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
// import "default-passive-events";
import moment from 'moment';
import { Modal } from 'antd';
import { Wrapper, FilterDiv, SubTitleDiv, SelectDate, InputDate, InputTime, SelectRegion, UpdateButton,
  RegionDiv, ItemSummary, SubTitleH2, FilterSelect, DetailsArrow, ItemDetail, ItemCard, TitleDiv,
  RegionSummary, SummarySpan, ConsumerDiv} from './Common.styles';
// import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const Item = () => {
    const token = cookie.load('token');
    // const {state:{showId,showName,showSpan}} = useLocation();
    const [searchParams] = useSearchParams();
    const showId = searchParams.get('campaignId');
    const showName = localStorage.getItem('showName');
    const showSpan = localStorage.getItem('showSpan');
    //商品リスト
    const [itemList, setItemList] = useState([]);
    const [productList, setProductList] = useState([]);
    //表示地域
    const [selectRegion, setSelectRegion] = useState('全国');
    //表示期間-開始
    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    //表示期間-終了
    const [endDate, setEndDate] = useState('');
    const [endTime, setEndTime] = useState('');
    //消費者属性
    const [attrParam, setAttrParam] = useState('');
    //都道府県
    const prefectureOpList = ['全国', '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県',
    '埼玉県', '千葉県', '東京都', '神奈川県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県',
    '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県',
    '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'];

    useEffect(() => {
        const getItemList = async () => {
          var attrStr = '';
          var attrIdList = [];
          const campaignInfo = await API.getCampaignInfo(showId, token);
          if (campaignInfo.message === '' && campaignInfo.data !== undefined 
             && campaignInfo.data['customerAttribution'] !== undefined) {
               campaignInfo.data['customerAttribution'].forEach(item => {
                  if (item.id !== undefined) {
                   attrIdList = [...attrIdList, item.id];
                   if (attrStr === '' && item.id === 'age') {
                     attrStr = 'age';
                   }
                  }
               })
          }
          // 開始日時、終了日時
          var startSpan = undefined
          var endSpan = undefined
          const resultData = campaignInfo.data
          if (resultData.validPeriodStartTime !== undefined && resultData.validPeriodStartTime.trim() !== ''
            && resultData.validPeriodEndTime !== undefined && resultData.validPeriodEndTime.trim() !== '') {
            startSpan = new Date(resultData.validPeriodStartTime).getTime()
            endSpan = new Date(resultData.validPeriodEndTime).getTime()
            var tempStartDateTime = resultData.validPeriodStartTime.split(" ")
            var tempEndDateTime = resultData.validPeriodEndTime.split(" ")
            setStartDate(tempStartDateTime[0])
            setStartTime(tempStartDateTime[1].slice(0, 5))
            setEndDate(tempEndDateTime[0])
            setEndTime(tempEndDateTime[1].slice(0, 5))
          }
          //地域
          const mapJsonInfo = await API.getMapJson();
          if (mapJsonInfo.data !== undefined) {
            echarts.registerMap('japanMap7', mapJsonInfo.data);
          }
          //表示商品
          const getProductInfo = API.getproductWordsData(showId, token);
          //売上金額
          const getSalesInfo = API.getStatisticsData(showId, token, 'salesAmount', 'day', 'list', '', '', startSpan, endSpan, {});
          //販売単価別売上数量
          const getUnitInfo = API.getStatisticsData(showId, token, 'unitAmount', '', 'list', '', '', startSpan, endSpan, {});
          //地域別売上金額
          const getRegionInfo = API.getStatisticsData(showId, token, 'salesAmount', 'region', 'list', '', '', startSpan, endSpan, {});
          //消費者属性
          var getAttrInfo;
          if (attrStr !== '') {
             setAttrParam(attrStr);
             getAttrInfo = API.getStatisticsData(showId, token, 'attributionRatio', '', 'list', attrStr, '', startSpan, endSpan, {});
          } else if (attrIdList.length > 0) {
             setAttrParam(attrIdList[0]);
             getAttrInfo = API.getStatisticsData(showId, token, 'attributionRatio', '', 'list', attrIdList[0], '', startSpan, endSpan, {});
          }
          
          Promise.all([getProductInfo, getSalesInfo, getUnitInfo, getRegionInfo, getAttrInfo]).then(function(values) {
            const [productInfo, salesInfo, unitInfo, regionInfo, attrInfo] = values;

            var message = '';
            if (productInfo.message !== '') {
            message = productInfo.message;
            } else if (salesInfo.message !== '') {
            message = salesInfo.message;
            } else if (unitInfo.message !== '') {
            message = unitInfo.message;
            } else if (attrInfo.message !== '') {
            message = attrInfo.message;
            } else if (regionInfo.message !== '') {
            message = regionInfo.message
            }
    
            if (message !== '') {
            const modal = Modal.error();
            modal.update({
            title:'エラー',
            content:message,
            onOk() {
                modal.destroy();
              }
            })
            } else {
            const productData = productInfo.data;
            var proList = [];
            if (productData !== undefined && productData.length > 0) {
              for (var f=0; f <productData.length; f++) {
                if (productData[f]['janCode'] !== undefined && productData[f]['janCode'] !== ''
                && productData[f]['brandName'] !== undefined && productData[f]['brandName'] !== '') {
                  let dto = {};
                  dto['code'] = productData[f]['janCode']
                  dto['name'] = productData[f]['brandName'];
                  proList = [...proList, dto];
                }
              }
            }
            proList = [...proList, {'code':'データなし', 'name':'データなし'}];
            setProductList(proList);
            const salesData = salesInfo.data;
            const unitData = unitInfo.data;
            const attrData = attrInfo.data;
            const reginData = regionInfo.data;
            var data1 = [];
            var data2 = [];
            var data3 = [];
            var data4 = [];
            if (salesData !== undefined && salesData.data !== undefined && salesData.data.length > 0) {
              data1 = salesData.data;
            }
            if (unitData !== undefined && unitData.data !== undefined && unitData.data.length > 0) {
              data2 = unitData.data;
            }
            if (attrData !== undefined && attrData.data !== undefined && attrData.data.length > 0) {
              data3 = attrData.data;
            }
            if (reginData !== undefined && reginData.data !== undefined && reginData.data.length > 0) {
              data4 = reginData.data;
            }
            setItemDataInfo(proList, data1, data2, data3, data4);
            }
          });
        }
        getItemList();
    },[]); //eslint-disable-line
      
    const handleRegionChange = (e) => {
        setSelectRegion(e.target.value);
    };
    //更新ボタン
    const handleUpdateClick = async () => {
        var msg = '';
        if ((startDate === '' || startDate.trim() === '') && startTime !== '' && startTime.trim() !== '') {
          msg = '完全な表示期間開始日時を入力してください。';
        } else if (startDate !== '' && startDate.trim() !== '' && (startTime === '' || startTime.trim() === '')) {
          msg = '完全な表示期間開始日時を入力してください。';
        } else if ((endDate === '' || endDate.trim() === '') && endTime !== '' && endTime.trim() !== '') {
          msg = '完全な表示期間終了日時を入力してください。';
        } else if (endDate !== '' && endDate.trim() !== '' && (endTime === '' || endTime.trim() === '')) {
          msg = '完全な表示期間終了日時を入力してください。';
        } else if (startDate !== '' && startDate.trim() !== '' && startTime !== '' && startTime.trim() !== ''
          && endDate !== '' && endDate.trim() !== '' && endTime !== '' && endTime.trim() !== '') {
          if (getStartDateTime(startDate, startTime) > getEndDateTime(endDate, endTime)) {
            msg = '表示期間の時間範囲が無効です。';
           }
        }
        if (msg !== '') {
          const modal = Modal.error();
          modal.update({
           title:'エラー',
           content:msg,
           onOk(){
            modal.destroy();
           }
          })
        } else {
          var startSpan = undefined;
          var endSpan = undefined;
          var regionParam = '';
          if (startDate !== '' && startDate.trim() !== '' && startTime !== '' && startTime.trim() !== '') {
            startSpan = getStartDateTime(startDate, startTime);
          }
          if (endDate !== '' && endDate.trim() !== '' && endTime !== '' && endTime.trim() !== '') {
            endSpan = getEndDateTime(endDate, endTime);
          }
          if (selectRegion !== '全国') {
           regionParam = selectRegion;
          }
          //売上金額
          const salesInfo = await API.getStatisticsData(showId, token, 'salesAmount', 'day', 'list', '', regionParam, startSpan, endSpan, {});
          //販売単価別売上数量
          const unitInfo = await API.getStatisticsData(showId, token, 'unitAmount', '', 'list', '', regionParam, startSpan, endSpan, {});
          //消費者属性
          var attrInfo = {message:'',data:[]};
          if (attrParam !== '') {
             attrInfo = await API.getStatisticsData(showId, token, 'attributionRatio', '', 'list', attrParam, regionParam, startSpan, endSpan, {});
          }
          //地域別売上金額
          const regionInfo = await API.getStatisticsData(showId, token, 'salesAmount', 'region', 'list', '', regionParam, startSpan, endSpan, {});
          var message = '';
          if (salesInfo.message !== '') {
            message = salesInfo.message;
          } else if (unitInfo.message !== '') {
            message = unitInfo.message;
          } else if (attrInfo.message !== '') {
            message = attrInfo.message;
          } else if (regionInfo.message !== '') {
            message = regionInfo.message
          }
          if (message !== '') {
           const modal = Modal.error();
           modal.update({
            title:'エラー',
            content:message,
            onOk() {
             modal.destroy();
            }
           })
          } else {
           const salesData = salesInfo.data;
           const unitData = unitInfo.data;
           const attrData = attrInfo.data;
           const proList = productList;
           const regionData = regionInfo.data;
           var data1 = [];
           var data2 = [];
           var data3 = [];
           var data4 = [];
           if (salesData !== undefined && salesData.data !== undefined && salesData.data.length > 0) {
             data1 = salesData.data;
           }
           if (unitData !== undefined && unitData.data !== undefined && unitData.data.length > 0) {
             data2 = unitData.data;
           }
           if (attrData !== undefined && attrData.data !== undefined && attrData.data.length > 0) {
             data3 = attrData.data;
           }
           if (regionData !== undefined && regionData.data !== undefined && regionData.data.length > 0) {
             data4 = regionData.data;
           }
           setItemDataInfo(proList, data1, data2, data3, data4);
          }
        }
    }

    const setItemDataInfo = (proList, data1, data2, data3, data4) => {
      var finalData = [];
      if (data1.length > 0 || data2.length > 0 || data3.length > 0 || data4.length > 0) {
        for (var i=0; i < proList.length; i++) {
          var newItem = {'product':'', 'salesNum':0, 'unitNum':0, 'attrNum':0};
          newItem['salesOption'] = getSalesOption([],[]);
          newItem['unitOption'] = getUnitOption([],[]);
          newItem['attrOption'] = getAttrOption([]);
          newItem['mapOption'] = getMapOptionInfo([], 10);
          var proCode =  proList[i]['code'];
          var proName = proList[i]['name'];
          //売上金額
          for (var m=0; m < data1.length; m++) {
            if (data1[m]['id'] !== undefined && data1[m]['id'] !== '' && data1[m]['id'] === proCode) {
              newItem['product'] = proName;
              if (data1[m]['summary'] !== undefined) {
                newItem['salesNum'] = data1[m]['summary'];
              }
              if (data1[m]['data1'] !== undefined && data1[m]['data1'].length > 0 &&
                data1[m]['data2'] !== undefined && data1[m]['data2'].length > 0) {
                newItem['salesOption'] = getSalesOption(data1[m]['data1'], data1[m]['data2']);
              }
              break;
            }
          }
          //販売単価別売上数量
          for (var n=0; n < data2.length; n++) {
            if (data2[n]['id'] !== undefined && data2[n]['id'] !== '' && data2[n]['id'] === proCode) {
              newItem['product'] = proName;
              if (data2[n]['summary'] !== undefined) {
                newItem['unitNum'] = data2[n]['summary'];
              }
              if (data2[n]['data1'] !== undefined && data2[n]['data1'].length > 0 &&
              data2[n]['data2'] !== undefined && data2[n]['data2'].length > 0) {
                newItem['unitOption'] = getUnitOption(data2[n]['data1'], data2[n]['data2']);
              }
              break;
            }
          }
          //消費者属性
          for (var k=0; k < data3.length; k++) {
            if (data3[k]['id'] !== undefined && data3[k]['id'] !== '' && data3[k]['id'] === proCode) {
              newItem['product'] = proName;
              if (data3[k]['summary'] !== undefined) {
                newItem['attrNum'] = data3[k]['summary'];
              }
              if (data3[k]['data1'] !== undefined && data3[k]['data1'].length > 0 &&
              data3[k]['data2'] !== undefined && data3[k]['data2'].length > 0) {
                let data = [];
                for (var h=0; h<data3[k]['data1'].length; h++) {
                  let item = {};
                  item['name'] = data3[k]['data1'][h];
                  item['value'] = data3[k]['data2'][h]
                  data = [...data, item];
                }
                newItem['attrOption'] = getAttrOption(data);
              }
              break;
            }
          }
          //地域別売上金額
          for (var p=0; p< data4.length; p++) {
            if (data4[p]['id'] !== undefined && data4[p]['id'] !== '' && data4[p]['id'] === proCode) {
              newItem['product'] = proName;
              if (data4[p]['data1'] !== undefined && data4[p]['data1'].length > 0 &&
              data4[p]['data2'] !== undefined && data4[p]['data2'].length > 0) {
                let data = [];
                let maxValue = 10;
                for (var b=0; b < data4[p]['data1'].length; b++) {
                  let item = {};
                  item['name'] = data4[p]['data1'][b];
                  item['value'] = data4[p]['data2'][b];
                  if (data4[p]['data2'][b] > maxValue) {
                    maxValue = Math.ceil(data4[p]['data2'][b]);
                  }
                  data = [...data, item];
                }
                newItem['mapOption'] = getMapOptionInfo(data, maxValue);
              }
              break;
            }
          }
          if (newItem['product'] !== undefined && newItem['product'] !== '') {
            finalData = [...finalData, newItem]
          }
        }
      }
      setItemList(finalData);  
    };
    //開始日時（ミリ秒）
    const getStartDateTime = (dateStr, timeStr) => {
        return new Date(moment(dateStr).format('YYYY-MM-DD') + ' ' + moment(timeStr, 'HH:mm').format('HH:mm:00')).getTime();
    };
    //終了日時（ミリ秒）
    const getEndDateTime = (dateStr, timeStr) => {
        return new Date(moment(dateStr).format('YYYY-MM-DD') + ' ' + moment(timeStr, 'HH:mm').format('HH:mm:59')).getTime();
    };

    const getSalesOption = (data1, data2) => {
      let tipStr = '売上金額（円）';
      let option = {
        tooltip:{
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            snap: true,
            label: {
              show: false
            }
          },
          borderColor:'#fff',
          padding:[0,0,0,0],
          borderWidth:0,
          formatter: (params) => {
            var res = "<table class='c3-tooltip'>" +
                    "<tr><th colSpan='2'>" + params[0].name.replaceAll('-', '/') + "</th></tr>" +
                    "<tr><td class='name'><span style='background-color:#1f77b4;'></span>" + tipStr + "</td><td>"+ params[0].value.toLocaleString() +"</td></tr></table>"
            return res
          }
        },
        grid:{
          top: 40,
          bottom: 90,
          left: 50
        },
        xAxis:{
          type:'category',
          axisLine: {
            show: true,
            lineStyle:{
            color:'#ccc'
            }
          },
          splitLine:{
            show:false
          },
          axisTick:{
            show:true
          },
          axisLabel:{
            show:true,
            formatter: function (value) {
              var date = new Date(value)
              var texts = [(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1), 
              date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
              ]
              return texts.join('/')
            }
          },
          data:data1
        },
        yAxis:{
          type:'value',
          axisLine: {
            show:true,
            lineStyle:{
              color:'#ccc'
            }
          },
          splitLine:{
            show:false
          },
          axisTick:{
            show:true,
          },
          axisLabel:{
            show:true,
            rotate: -45
          },
        },
        series:[{
          type:'line',
          showSymbol: false,
          lineStyle:{
            color:'#1f77b4',
            width:1,
          },
          data:data2
        }]
      };
      return option
    };

    const getUnitOption = (data1, data2) => {
      let tipStr = '売上数量（個）';
      let option = {
        tooltip:{
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            snap: true,
            label: {
              show: false
            }
          },
          borderColor:'#fff',
          padding:[0,0,0,0],
          borderWidth:0,
          formatter: (params) => {
            var res = "<table class='c3-tooltip'>" +
                    "<tr><th colSpan='2'>" + Number(params[0].name).toLocaleString() + "</th></tr>" +
                    "<tr><td class='name'><span style='background-color:#1f77b4;'></span>" + tipStr + "</td><td>"+ params[0].value.toLocaleString() +"</td></tr></table>"
            return res
          }
        },
        grid:{
          top: 40,
          bottom: 90,
          left: 40
        },
        xAxis:{
          type:'category',
          name:'販売価格（円）',
          nameLocation:'center',
          nameGap:25,
          nameTextStyle:{
            color:'#000',
            fontSize:10,
            padding:[0,0,0,180],
            verticalAlign:'top'
          },
          axisLine: {
            show: true,
            lineStyle:{
              color:'#ccc'
            }
          },
          splitLine:{
            show:false
          },
          axisTick:{
            show:true
          },
          axisLabel:{
            show:true,
            color:'#ccc',
            formatter: function (value) {
              return Number(value).toLocaleString();
            }
          },
          data:data1
        },
        yAxis:{
          type:'value',
          boundaryGap: [0, '30%'],
          axisLine: {
            show:true,
            lineStyle:{
                color:'#ccc'
            }
          },
          splitLine:{
            show:false
          },
          axisTick:{
            show:true,
          },
          axisLabel:{
            show:true
          },
        },
        series:[{
          type:'line',
          smooth: 0.6,
          showSymbol: false,
          lineStyle:{
            color:'#1f77b4',
            width:1,
          },
          areaStyle: {
            color:'#ADD8E6'
          },
          data:data2
        }]
      };
      return option
    };

    const getAttrOption = (data) => {
      let option = {
        tooltip:{
          trigger: 'item',
          borderColor:'#fff',
          borderWidth:0,
          padding:[0,0,0,0],
          formatter: (param) => {
              var res = "<table class='c3-tooltip'>" +
                    "<tr><td class='name'><span style='background-color:"+ param.color+";'></span>" + param.name + "</td><td class='value'>"+ param.percent +"%</td></tr></table>"
              return res
             }
        },
        legend: {
          type: 'scroll',
          icon:'rect',
          orient: 'vertical',
          height:210,
          right:10,
          top:20,
          itemWidth:10,
          itemHeight:10,
          textStyle:{
            color:'#000',
            fontSize:12,
            width:70,
            overflow:'truncate'
          },
          pageTextStyle:{
            color:'#000',
            fontSize:12,
          },
          pageIconSize:10,
          tooltip: {
            show: true
          }
        },
        color:['#626A70', '#8AC9F2', '#D2E4F0', '#405D70', '#A4B3BD'],
        series:[{
          type:'pie',
          radius: '60%',
          colorBy:'data',
          left:-100,
          top: -10,
          bottom:20,
          emphasis:{
            focus:'self'
          },
          label:{
            show:false
          },
          data:data
        }]
      };
      return option
    };

    const getMapOptionInfo = (data, maxVal) => {
      let option = {
       tooltip:{
         trigger: 'item',
         borderColor:'#fff',
         borderWidth:0,
         padding:[0,0,0,0],
         formatter: (param) => {
             var data = 0;
             if (param.value !== undefined && !isNaN(param.value)) {
               data = param.value;
             }
             var res = "<table class='c3-tooltip'>" +
                   "<tr><td class='name'>" + param.name + "</td><td class='value'>" + Number(data).toLocaleString() +" 円</td></tr></table>"
             return res
            }
         },
         visualMap:{
           min:0,
           max:maxVal,
           calculable: true,
           right: 10,
           bottom: 30,
           orient: 'horizontal',
           itemHeight: 100,
           itemWidth: 15,
           inRange:{
             color:[
               "#d2e4f0",
               "#92bedb",
               "#5398c6",
               "#316b92",
               "#316b92",
               "#1c3d53"
             ]
           },
           formatter: function (value) {
            return value.toLocaleString();
           },
           show:true,
         },
         series:[{
           type:'map',
           map:'japanMap7',
           center:[135.3, 36.5],
           zoom: 9,
           top: -5,
           left: 90,
           data:data
         }]
      };
      return option;
     };

    return (
        <Wrapper>
        <Header showFlag={'page3'} showName={showName} showSpan={showSpan} showId={showId}/>
        <main>
          <FilterDiv>
            <SubTitleDiv><SubTitleH2>商品ごと統計</SubTitleH2></SubTitleDiv>
            <SelectDate>表示期間:</SelectDate>
            <InputDate type='date' value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
            <InputTime type='time' value={startTime} onChange={(e) => setStartTime(e.target.value)}/> ~&nbsp;
            <InputDate type='date' value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
            <InputTime type='time' value={endTime} onChange={(e) => setEndTime(e.target.value)}/>
            <SelectRegion>表示地域:</SelectRegion>
            <FilterSelect value={selectRegion} onChange={handleRegionChange}>
             {prefectureOpList.map((item, index) =>
               <option key ={index} value={item}>{item}</option>
              )}
            </FilterSelect>
            <UpdateButton onClick={handleUpdateClick}>更新</UpdateButton>
          </FilterDiv>
          <RegionDiv>
           {
             itemList.map((item, index) => 
             <details key={index} open>
               <ItemSummary><DetailsArrow className='detail-arrow'>▲</DetailsArrow>{item.product}</ItemSummary>
               <ItemDetail>
                   <ItemCard>
                     <TitleDiv>売上金額</TitleDiv>
                     <RegionSummary><p><SummarySpan>合計: </SummarySpan>{item.salesNum.toLocaleString()}円</p></RegionSummary>
                     <div><ReactEcharts option={item.salesOption}/></div>
                   </ItemCard>
                   <ItemCard>
                     <TitleDiv>販売単価別売上数量</TitleDiv>
                     <RegionSummary><p><SummarySpan>合計: </SummarySpan>{item.unitNum.toLocaleString()}個</p></RegionSummary>
                     <div><ReactEcharts option={item.unitOption}/></div>
                   </ItemCard>
                   <ItemCard>
                     <TitleDiv>消費者属性</TitleDiv>
                     <RegionSummary><p><SummarySpan>平均購入金額: </SummarySpan>{item.attrNum.toLocaleString()}円</p></RegionSummary>
                     <ConsumerDiv><ReactEcharts option={item.attrOption}/></ConsumerDiv>
                   </ItemCard>
                   <ItemCard>
                     <TitleDiv>地域別売上金額</TitleDiv>
                     <div><ReactEcharts option={item.mapOption}/></div>
                   </ItemCard>
               </ItemDetail>
             </details>
               )
             }
          </RegionDiv>
        </main>
        <Footer/>
      </Wrapper>
    )
}

export default Item;