import Footer from './Footer';
import Header from './Header';
import { Wrapper, Main, ErrorMsg, ErrorButton, ErrorHref} from './Common.styles';

const NotFound = () => {
    return (
        <Wrapper>
           <Header showFlag={'page1'}/>
           <Main>
              <div>
                  <ErrorMsg>
                    申し訳ございません
                    <br/>
                    お探しのページは見つかりませんでした
                    <br/>
                    もう一度ログインしてお試しください
                  </ErrorMsg>
                  <ErrorButton>
                      <ErrorHref href='/'>ログインページへ</ErrorHref>
                  </ErrorButton>
              </div>
           </Main>
           <Footer/>
        </Wrapper>
    )
}

export default NotFound;