import React from "react"
import { Navigate, useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useAuth } from "../Context";


export default function PrivateRoute({ children, childPath, childElement }) {
  const location = useLocation();
  const { state } = useAuth();
  const [searchParams] = useSearchParams();
  let campaignId = null;
  if (searchParams.has('campaignId')) {
     campaignId = searchParams.get('campaignId');
  }
  let toPath = '/';
  if(childPath !== undefined && !localStorage.getItem('isLogInd')){
    localStorage.setItem('loginAfterPage', childPath);
    localStorage.setItem('loginAfterParam', campaignId);

  } else {
    localStorage.removeItem('loginAfterPage');
    localStorage.removeItem('loginAfterParam');

  }

  if (localStorage.getItem('isLogInd') === undefined) return null; // or loading spinner, etc...
  return localStorage.getItem('isLogInd') ? childElement : <Navigate state={{from:location}} to={toPath}/> ;

}