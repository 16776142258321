import React from 'react';

import axios from "axios";
import cookie from 'react-cookies'
import { Modal } from 'antd';
import './Loading.css';
import { createRoot } from 'react-dom/client';
import Loader from "./Loading";




const instance = axios.create({
    timeout: 20000,
    // baseURL: baseUrl 
})
let requestCount = 0

//instance.defaults.headers.post['Content-Type'] = 'application/json;chartset=utf-8'
/** request  **/
instance.interceptors.request.use(config => {
    // document.body.classList.add('loading-indicator');
    showLoading();
    var token = cookie.load('token');
    if (token) {
        config.headers['Authorization'] = token;
        config.headers['Content-Type'] = 'application/json;chartset=utf-8';
    }
    return config
}, error => {
    hideLoading();
    return Promise.reject(error)
})

/** response  **/
instance.interceptors.response.use(response => {
    // document.body.classList.remove('loading-indicator');
    hideLoading();
    if (response.status === 200) {
        cookie.save('token', response.headers.token);
    }
    
    return response
}, error => {
    // document.body.classList.remove('loading-indicator');
    hideLoading();

    if (error.response) {
        if (error.response.status === 403) {
            const modal = Modal.error();
            modal.update({
                title: 'エラー',
                content: 'セッションの有効期限が切れました。もう一度ログインしてください。',
                onOk() {
                    modal.destroy();
                    cookie.remove('token');
                    localStorage.clear();
                    window.location.href = '/'
                }
            })
            return new Promise(() => { })
        } else if (error.response.status >= 400 && error.response.status < 500 && error.response.status !==409 ) {

            if (error.response.data.message !== '') {
                const modal = Modal.error();
                modal.update({
                  title: 'エラー',
                  content: error.response.data.message,
                  onOk() {
                    modal.destroy();
                    window.location.href = '/*';
                  }
                })
              } else {
                 window.location.href = '/*';
              }
           
            return new Promise(() => { })

        } else if (error.response.status >= 500 && error.response.status < 600) {

            if (error.response.data.message !== '') {
                const modal = Modal.error();
                modal.update({
                  title: 'エラー',
                  content: error.response.data.message,
                  onOk() {
                    modal.destroy();
                    window.location.href = '/errorPage';
                }
                })
              } else {
                window.location.href = '/errorPage';
            }
            return new Promise(() => { })
        } else {
            return Promise.reject(error)

        }
    } else {

        return Promise.reject(error)
    }
})

/* get */
export const get = (url, params, config = {}) => {
    return new Promise((resolve, reject) => {
        instance({
            method: 'GET',
            url,
            params,
            ...config
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

/* post  */
export const post = (url, data, config = {}) => {
    return new Promise((resolve, reject) => {
        instance({
            method: 'POST',
            url,
            data,
            ...config
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

/* put  */
export const put = (url, data, config = {}) => {
    return new Promise((resolve, reject) => {
        instance({
            method: 'PUT',
            url,
            data,
            ...config
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

/* DELETE  */
export const deleteM = (url, data, config = {}) => {
    return new Promise((resolve, reject) => {
        instance({
            method: 'DELETE',
            url,
            data,
            ...config
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}


//  show loading
const showLoading = () => {
    if (requestCount === 0) {
        var dom = document.createElement('div')
        dom.setAttribute('id', 'loading')
        document.body.appendChild(dom);
        const root = createRoot(dom);
        root.render(<Loader />)

    }
    requestCount++
}

// hide loading
const hideLoading = () => {
    requestCount--
    if (requestCount === 0) {
        document.body.removeChild(document.getElementById('loading'))
    }
}